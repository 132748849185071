import { WeekDay } from "types/IOperationServices";

export function getTodayWeekDay(): WeekDay {
  const today = new Date();
  const daysArray = [
    WeekDay.SUNDAY,
    WeekDay.MONDAY,
    WeekDay.TUESDAY,
    WeekDay.WEDNESDAY,
    WeekDay.THURSDAY,
    WeekDay.FRIDAY,
    WeekDay.SATURDAY,
  ];

  return daysArray[today.getDay()];
}
