import { makeAutoObservable } from "mobx";
import { StoreState } from "../../../enum/StoreState";
import { RootEnv } from "../../setup/create-store";
import { getEnv, getRoot } from "mobx-easy";
import {
  IOccurrence,
  IOccurrenceDescription,
  IOccurrenceWeatherData,
  IOccurrenceWaveData,
  IOccurrenceWaterFlowData,
} from "../../../types/IOccurrence";
import { EnvironmentalType } from "../../../enum/EnvironmentalType";
import { IStation } from "../oceanWeatherIntelligences/oceanWeatherIntelligenceModel";
import { IStationRegion } from "../../../types/IStationRegion";
import { OccurrenceFormValues } from "../../../pages/Occurrence/Components/OcurrenceFormModal";
import { OccurrenceEnvironmentalType } from "../../../enum/OccurrenceEnvironmentalType";
import { EnvironmentalVariables } from "../../../enum/EnvironmentalVariables";
import { ProductAnalyticsEvents } from "../../../enum/ProductAnalyticsEvents";
import RootStore from "../../root-store";
import {
  chartMeteogramXAxisMock,
  chartMeteogramYAxisMock,
} from "../forecastChart/forecastChartMeteogram/forecastChartMeteogram.mock";
import { IGetOccurrenceAnalysis } from "../../../services/OccurrenceService";

const occurrenceEnvironmentalTypeMapper: Record<
  OccurrenceEnvironmentalType,
  EnvironmentalVariables[]
> = {
  [OccurrenceEnvironmentalType.WIND]: [
    EnvironmentalVariables.WIND_VEL,
    EnvironmentalVariables.WIND_DIR,
    EnvironmentalVariables.GUST,
    EnvironmentalVariables.WIND_VEL_80M,
    EnvironmentalVariables.WIND_DIR_80M,
    EnvironmentalVariables.GUST_80M,
  ],
  [OccurrenceEnvironmentalType.RAIN]: [EnvironmentalVariables.PRECIP],
  [OccurrenceEnvironmentalType.FOG]: [EnvironmentalVariables.VISIBILITY],
  [OccurrenceEnvironmentalType.TEMPERATURE]: [
    EnvironmentalVariables.TEMP_AIR,
    EnvironmentalVariables.DEW_POINT,
  ],
  [OccurrenceEnvironmentalType.WAVE]: [
    EnvironmentalVariables.WAVE_HEIGHT,
    EnvironmentalVariables.WAVE_DIR,
    EnvironmentalVariables.PEAK_PERIOD,
    EnvironmentalVariables.WAVE_HEIGHT_MAX,
    EnvironmentalVariables.WAVE_HEIGHT_SEA,
    EnvironmentalVariables.WAVE_DIR_SEA,
    EnvironmentalVariables.PEAK_PERIOD_SEA,
    EnvironmentalVariables.WAVE_HEIGHT_SWELL,
    EnvironmentalVariables.WAVE_DIR_SWELL,
    EnvironmentalVariables.PEAK_PERIOD_SWELL,
  ],
  [OccurrenceEnvironmentalType.CURRENT]: [
    EnvironmentalVariables.CURRENT_VEL,
    EnvironmentalVariables.CURRENT_DIR,
  ],
  [OccurrenceEnvironmentalType.WATER_LEVEL]: [
    EnvironmentalVariables.WATER_LEVEL,
  ],
};

export default class OccurrenceStore {
  state: string = StoreState.DONE;

  occurrences: IOccurrence[] = [];

  selectedOccurrence: IOccurrence | null = null;

  isOccurrenceModalOpen: boolean = false;

  stations: IStationRegion[] = [];

  isLoading = this.state === StoreState.PEDDING;

  filteredOccurrences: IOccurrence[] = [];

  searchValue: string = "";

  isOccurrenceBeingProcessed: boolean = false;

  importState: string = StoreState.DONE;

  openSuccessImportModal: boolean = false;

  isImportOccurrenceModalOpen: boolean = false;

  occurrencesDescription: IOccurrenceDescription[] = [];

  weatherChart: any | null = null;

  waveChart: any | null = null;

  waterFlowChart: any | null = null;

  i4castChartXAxis: any[] = chartMeteogramXAxisMock;

  i4castChartYAxis: any[] = chartMeteogramYAxisMock;

  weatherData: IOccurrenceWeatherData | null = null;

  waveData: IOccurrenceWaveData | null = null;

  waterFlowData: IOccurrenceWaterFlowData | null = null;

  occurrence: IOccurrence | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  setState(value: StoreState) {
    this.state = value;
  }

  setOccurrences(occurrences: IOccurrence[]) {
    this.occurrences = occurrences;
  }

  setSelectedOccurrence(occurrence: IOccurrence | null) {
    this.selectedOccurrence = occurrence;
  }

  setIsOccurrenceModalOpen(value: boolean) {
    this.isOccurrenceModalOpen = value;
  }

  setStations(stations: IStationRegion[]) {
    this.stations = stations;
  }

  setFilteredOccurrences(occurrences: IOccurrence[]) {
    this.filteredOccurrences = occurrences;
  }

  setSearchValue(value: string) {
    this.searchValue = value;
  }

  setIsOccurrenceBeingProcessed(value: boolean) {
    this.isOccurrenceBeingProcessed = value;
  }

  setImportState(value: StoreState) {
    this.importState = value;
  }

  setOpenSuccessImportModal(value: boolean) {
    this.openSuccessImportModal = value;
  }

  setIsImportOccurrenceModalOpen(value: boolean) {
    this.isImportOccurrenceModalOpen = value;
  }

  setOccurrencesDescription(value: IOccurrenceDescription[]) {
    this.occurrencesDescription = value;
  }

  setWeatherChart(value: any) {
    this.weatherChart = value;
  }

  setWaveChart(value: any) {
    this.waveChart = value;
  }

  setWaterFlowChart(value: any) {
    this.waterFlowChart = value;
  }

  seti4castChartYAxis(value: any[]) {
    this.i4castChartYAxis = value;
  }

  setWeatherData(value: IOccurrenceWeatherData | null) {
    this.weatherData = value;
  }

  setWaveData(value: IOccurrenceWaveData | null) {
    this.waveData = value;
  }

  setWaterFlowData(value: IOccurrenceWaterFlowData | null) {
    this.waterFlowData = value;
  }

  setOccurrence(value: IOccurrence | null) {
    this.occurrence = value;
  }

  resetEventAnalysis() {
    this.setOccurrencesDescription([]);
    this.resetClimatologicData();
    this.setOccurrence(null);
  }

  resetClimatologicData() {
    this.setWeatherData(null);
    this.setWaveData(null);
    this.setWaterFlowData(null);
  }

  reset() {
    this.setState(StoreState.DONE);
    this.setOccurrences([]);
    this.setSelectedOccurrence(null);
    this.setIsOccurrenceModalOpen(false);
    this.setStations([]);
    this.setFilteredOccurrences([]);
    this.setIsOccurrenceBeingProcessed(false);
    this.setImportState(StoreState.DONE);
    this.setOpenSuccessImportModal(false);
    this.setIsImportOccurrenceModalOpen(false);
    this.resetEventAnalysis();
  }

  async getOccurrences() {
    this.setState(StoreState.PEDDING);
    const { occurrenceService } = getEnv<RootEnv>();

    this.setOccurrences([]);
    this.setFilteredOccurrences([]);
    this.setSearchValue("");

    try {
      const occurrences: IOccurrence[] =
        await occurrenceService.getOccurrencies();

      this.setOccurrences(occurrences);
      this.setFilteredOccurrences(occurrences);

      this.setState(StoreState.DONE);
    } catch (error) {
      this.setState(StoreState.ERROR);
    }
  }

  async loadStations() {
    if (this.stations.length) {
      this.setState(StoreState.DONE);
      return;
    }
    const { atmOceanService } = getEnv<RootEnv>();
    this.setState(StoreState.PEDDING);
    this.setStations([]);
    try {
      const stations: IStation[] =
        await atmOceanService.getStationsForecastChart(
          EnvironmentalType.WEATHER // ignored by backend
        );
      if (stations.length) {
        const stationRegion = stations
          .map((station) => {
            return {
              id: `${station.station_id}-${station.region_id}`,
              name: `${station.station_name} - ${station.macro_region_name}`,
            };
          })
          .sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          });
        this.setStations(stationRegion);
      }
      this.setState(StoreState.DONE);
    } catch (error) {
      this.setState(StoreState.ERROR);
    }
  }

  async addOccurrence(occurrence: Omit<IOccurrence, "id">) {
    const { occurrenceService } = getEnv<RootEnv>();
    this.setState(StoreState.PEDDING);

    try {
      const response = await occurrenceService.createOccurrence(occurrence);
      if (response.id) {
        let newOccurrence: IOccurrence = { ...occurrence, id: response.id };
        this.setOccurrences([newOccurrence].concat(this.occurrences));
        this.setFilteredOccurrences(this.getFilteredOccurrences());
        this.setState(StoreState.DONE);

        return newOccurrence.id;
      } else {
        this.setState(StoreState.ERROR);
      }
    } catch (error) {
      this.setState(StoreState.ERROR);
    }
  }

  async deleteOccurrence(id: string) {
    const { occurrenceService } = getEnv<RootEnv>();
    this.setState(StoreState.PEDDING);

    try {
      const response = await occurrenceService.deleteOccurrence(id);
      if (response.success) {
        this.setOccurrences(
          this.occurrences.filter((occurrence) => occurrence.id !== id)
        );
        this.setFilteredOccurrences(this.getFilteredOccurrences());
        this.setState(StoreState.DONE);
      } else {
        this.setState(StoreState.ERROR);
      }
    } catch (error) {
      this.setState(StoreState.ERROR);
    }
  }

  async updateOccurrence(occurrence: IOccurrence) {
    const { occurrenceService } = getEnv<RootEnv>();
    this.setState(StoreState.PEDDING);
    try {
      const response = await occurrenceService.updateOccurrence(occurrence);
      if (response.success) {
        const editOccurence = this.occurrences;
        const index = editOccurence.findIndex(
          (item) => item.id === occurrence.id
        );
        editOccurence[index] = occurrence;

        this.setOccurrences(editOccurence);
        this.setFilteredOccurrences(this.getFilteredOccurrences());

        this.setState(StoreState.DONE);
        return response.success;
      } else {
        this.setState(StoreState.ERROR);
      }
    } catch (error) {
      this.setState(StoreState.ERROR);
    }
  }

  async getOccurrencesDescription() {
    this.setState(StoreState.PEDDING);
    const { occurrenceService } = getEnv<RootEnv>();

    try {
      const occurrences: IOccurrenceDescription[] =
        await occurrenceService.getOccurrencesDescription();

      this.setOccurrencesDescription(occurrences);
      this.setState(StoreState.DONE);
    } catch (error) {
      this.setState(StoreState.ERROR);
    }
  }

  async getOccurrenceAnalysis({
    regionId,
    stationId,
    environmentalType,
    initialDate,
    finalDate,
  }: IGetOccurrenceAnalysis) {
    this.setState(StoreState.PEDDING);
    const { occurrenceService } = getEnv<RootEnv>();
    const {
      dataStores: { forecastChartMeteogramStore },
    } = getRoot<RootStore>();

    this.setWeatherChart({});
    this.setWaveChart({});
    this.setWaterFlowChart({});

    this.resetClimatologicData();

    try {
      const { statistics, i4castData } =
        await occurrenceService.getOccurrenceAnalysis({
          stationId,
          regionId,
          environmentalType,
          initialDate,
          finalDate,
        });

      const { serieProcessed, yAxisProcessed } =
        forecastChartMeteogramStore.getProcessedSerieByEnvironmentalType(
          environmentalType,
          i4castData.series
        );

      this.seti4castChartYAxis(yAxisProcessed);

      if (environmentalType === EnvironmentalType.WEATHER) {
        this.setWeatherData(statistics);
        this.setWeatherChart(serieProcessed);
      } else if (environmentalType === EnvironmentalType.WAVE) {
        this.setWaveData(statistics);
        this.setWaveChart(serieProcessed);
      } else if (environmentalType === EnvironmentalType.WATER_FLOW) {
        this.setWaterFlowData(statistics);
        this.setWaterFlowChart(serieProcessed);
      }

      this.setState(StoreState.DONE);
    } catch (error) {
      this.setState(StoreState.ERROR);
    }
  }

  async getOccurrence(id: string) {
    this.setState(StoreState.PEDDING);
    const { occurrenceService } = getEnv<RootEnv>();

    try {
      const occurence = await occurrenceService.getOccurrence(id);

      this.setOccurrence(occurence);
      this.setState(StoreState.DONE);
    } catch (error) {
      this.setState(StoreState.ERROR);
    }
  }

  async importOccurrences(occurrenceFile: FormData) {
    const { occurrenceService } = getEnv<RootEnv>();
    this.setImportState(StoreState.PEDDING);
    try {
      const response = await occurrenceService.importOccurrences(
        occurrenceFile
      );

      if (response.success) {
        this.setImportState(StoreState.DONE);
        return response.success;
      }
      return this.setImportState(StoreState.ERROR);
    } catch (error) {
      this.setImportState(StoreState.ERROR);
    }
  }

  processOccurenceFormValues(values: OccurrenceFormValues) {
    const [stationId, regionId] = values.stationRegion.split("-");
    if (!stationId || !regionId) {
      throw new Error("Station and Region with inconsistent values!");
    }
    let latLong: string | undefined = undefined;
    if (values.latitude && values.longitude) {
      latLong = `${values.latitude};${values.longitude}`;
    }
    let variables: EnvironmentalVariables[] = [];

    if (values.startHour && values.startDate) {
      const [hours, minutes] = values.startHour.split(":");
      values.startDate.setHours(parseInt(hours), parseInt(minutes));
    }
    if (values.endHour && values.endDate) {
      const [hours, minutes] = values.endHour.split(":");
      values.endDate.setHours(parseInt(hours), parseInt(minutes));
    }

    if (values.wind) {
      variables = variables.concat(
        occurrenceEnvironmentalTypeMapper[OccurrenceEnvironmentalType.WIND]
      );
    }
    if (values.wave) {
      variables = variables.concat(
        occurrenceEnvironmentalTypeMapper[OccurrenceEnvironmentalType.WAVE]
      );
    }
    if (values.rain) {
      variables = variables.concat(
        occurrenceEnvironmentalTypeMapper[OccurrenceEnvironmentalType.RAIN]
      );
    }
    if (values.fog) {
      variables = variables.concat(
        occurrenceEnvironmentalTypeMapper[OccurrenceEnvironmentalType.FOG]
      );
    }
    if (values.waterLevel) {
      variables = variables.concat(
        occurrenceEnvironmentalTypeMapper[
          OccurrenceEnvironmentalType.WATER_LEVEL
        ]
      );
    }
    if (values.current) {
      variables = variables.concat(
        occurrenceEnvironmentalTypeMapper[OccurrenceEnvironmentalType.CURRENT]
      );
    }
    if (values.temperature) {
      variables = variables.concat(
        occurrenceEnvironmentalTypeMapper[
          OccurrenceEnvironmentalType.TEMPERATURE
        ]
      );
    }

    if (!variables.length) {
      throw new Error("Environmental variables with inconsistent values");
    }

    return {
      description: values.description,
      allowNotification: values.allowNotification,
      forecastCorrect: values.forecastCorrect === "yes",
      initialDate: values.startDate?.getTime(),
      finalDate: values.endDate?.getTime(),
      regionId: parseInt(regionId),
      stationId: parseInt(stationId),
      localLatLong: latLong,
      environmentalVariables: variables,
    };
  }

  getOccurrenceTypeFromEnvironmentalVariables(
    environmentalVariables: string[]
  ) {
    const occurrenceTypes: string[] = [];
    for (const environmentalVariable of environmentalVariables) {
      for (const occurenceType of Object.keys(
        occurrenceEnvironmentalTypeMapper
      )) {
        const variables =
          occurrenceEnvironmentalTypeMapper[
            occurenceType as OccurrenceEnvironmentalType
          ];
        if (
          variables.includes(environmentalVariable as EnvironmentalVariables) &&
          !occurrenceTypes.includes(occurenceType)
        ) {
          occurrenceTypes.push(occurenceType);
        }
      }
    }
    return occurrenceTypes;
  }

  getFilteredOccurrences() {
    if (!this.searchValue) {
      return this.occurrences;
    }

    return this.occurrences.filter((occurrence) => {
      const date = new Date(occurrence.initialDate).toLocaleString();

      return (
        occurrence.description
          .toLowerCase()
          .includes(this.searchValue.toLowerCase()) ||
        date.includes(this.searchValue)
      );
    });
  }

  async addLogUserExperienceAPI(events: ProductAnalyticsEvents) {
    const { logServices } = getEnv<RootEnv>();
    logServices.addLogUserExperience(events);
  }
}
