import {
  IOperationService,
  IServiceEventsParams,
  IServiceList,
} from "types/IOperationServices";
import api from "./api";

export default class OperationServicesService {
  async getServices(limit?: number): Promise<IServiceList[]> {
    const response = await api.get(
      `/v1/operation-service/services?limit=${limit}`
    );
    return response.data;
  }

  async getServiceById(id: number): Promise<IOperationService> {
    const response = await api.get(`/v1/operation-service/services/${id}`);
    return response.data;
  }

  async createServices(service: IOperationService): Promise<IOperationService> {
    const response = await api.post(`/v1/operation-service/services/`, {
      ...service,
    });
    return response.data;
  }

  async updateService(service: IOperationService) {
    const response = await api.put(
      `/v1/operation-service/services/${service.id}`,
      {
        ...service,
      }
    );
    return response.data;
  }

  async deleteService(id: number) {
    const response = await api.delete(`/v1/operation-service/services/${id}`);
    return response.data;
  }

  async getOperationServiceEvents({
    id,
    startDate,
    endDate,
  }: IServiceEventsParams) {
    const response = await api.get(
      `/v1/operation-service/services/${id}/events`,
      { params: { startDate, endDate } }
    );
    return response.data;
  }
}
