import { EnvironmentalType } from "enum/EnvironmentalType";
import { EnvironmentalVariables } from "enum/EnvironmentalVariables";
import { MacroRegion } from "enum/MacroRegion";
import { VisualizationType } from "enum/VisualizationType";
import i18n from "i18n";
import { makeAutoObservable } from "mobx";
import { getEnv, getRoot } from "mobx-easy";
import { ProbabilisticForecastParams } from "services/ProbabilisticForecastService";
import RootStore from "stores/root-store";
import { IMacroRegion } from "types/IMacroRegion";
import { getMacroRegionOriginFromLatLon } from "util/macroRegion/getMacroRegionOriginFromLatLon";
import { getRiverWaterLevelConfigMap } from "util/meteogramCharts/processSerie/common/getRiverWaterLevelConfigMap";
import { StoreState } from "../../../enum/StoreState";
import { ProductAnalyticsEvents } from "../../../enum/ProductAnalyticsEvents";

import { RootEnv } from "../../setup/create-store";
import { IStation } from "../oceanWeatherIntelligences/oceanWeatherIntelligenceModel";
import RequestCancellationStore from "../requestCancellationStore/RequestCancellationStore";
import { Units } from "../unitMeasurement/UnitMeasurementStore";

interface YAxisConfig {
  min: number;
  max: number;
  unitUser: string;
}

interface RiverForecastData {
  seriesData: any[];
  yAxisData: YAxisConfig;
  timezoneOffset: number;
}

export default class RiverForecastChartStore {
  state: string = StoreState.DONE;
  mapState: string = StoreState.DONE;
  riverForecastData: RiverForecastData | null = null;
  isChartDataEmpty: boolean = false;
  openMeteogram: boolean = true;
  selectedStation: number = 0;
  regionId: number = 0;
  clickMapLatLng: any | undefined = undefined;
  stations: IStation[] = [];
  macroRegionOrigin: MacroRegion | undefined;
  mapCenterLatitude: number = 0;
  mapCenterLongitude: number = 0;
  actionCallChart: boolean = false;
  actionCallMapClick: boolean = false;
  messageError: string = "";
  visualizationType: VisualizationType = VisualizationType.FORECAST;
  tabStateActive: EnvironmentalType = EnvironmentalType.WATER_FLOW;
  requestCancellationStore: RequestCancellationStore;

  constructor(requestCancellationStore: RequestCancellationStore) {
    makeAutoObservable(this);
    this.requestCancellationStore = requestCancellationStore;
  }

  setState(value: StoreState) {
    this.state = value;
  }

  setMapState(value: StoreState) {
    this.mapState = value;
  }

  setRiverForecastData(value: RiverForecastData | null) {
    this.riverForecastData = value;
  }

  setIsChartDataEmpty(value: boolean) {
    this.isChartDataEmpty = value;
  }

  setOpenMeteogram(value: boolean) {
    this.openMeteogram = value;
  }

  setSelectedStation(values: number) {
    this.selectedStation = values;
  }

  setRegionId(regionId: number) {
    this.regionId = regionId;
  }

  setClickMapLatLng(values: any | undefined) {
    this.clickMapLatLng = values;
  }

  setStations(values: IStation[]) {
    this.stations = values;
  }

  setMacroRegionOrigin(value: MacroRegion | undefined) {
    this.macroRegionOrigin = value;
  }

  setMapCenterLatitude(value: number) {
    this.mapCenterLatitude = value;
  }

  setMapCenterLongitude(value: number) {
    this.mapCenterLongitude = value;
  }

  setMapCenter(): void {
    const foundStation = this.stations.find(
      (value) => value.station_id === this.selectedStation
    );
    if (foundStation) {
      this.setMapCenterLatitude(foundStation.station_lat);
      this.setMapCenterLongitude(foundStation.station_lon);
    }
  }

  setActionCallChart() {
    this.actionCallChart = !this.actionCallChart;
  }

  setActionCallMapClick() {
    this.actionCallMapClick = !this.actionCallMapClick;
  }

  setMessageError(value: string) {
    this.messageError = value;
  }

  setVisualizationType(value: VisualizationType) {
    this.visualizationType = value;
  }

  setTabStateActive(value: EnvironmentalType) {
    this.tabStateActive = value;
  }

  updateMacroRegionOrigin(values: any, companyMacroRegions: IMacroRegion[]) {
    const {
      dataStores: { forecastChartPageStore },
    } = getRoot<RootStore>();

    if (values) {
      const macroRegionOrigin = getMacroRegionOriginFromLatLon(
        values.lat,
        values.lng,
        companyMacroRegions
      );
      const isAllowed = forecastChartPageStore.validateMacroRegion(
        companyMacroRegions,
        macroRegionOrigin
      );
      if (isAllowed) {
        this.setMacroRegionOrigin(macroRegionOrigin);
      }
    }
  }

  reset() {
    this.setState(StoreState.DONE);
    this.setMapState(StoreState.DONE);
    this.setRiverForecastData(null);
    this.setIsChartDataEmpty(false);
    this.setOpenMeteogram(true);
    this.setSelectedStation(0);
    this.setRegionId(0);
    this.setClickMapLatLng(undefined);
    this.setStations([]);
    this.setMacroRegionOrigin(undefined);
    this.setMapCenterLatitude(0);
    this.setMapCenterLongitude(0);
    this.setMessageError("");
    this.setVisualizationType(VisualizationType.FORECAST);
    this.setTabStateActive(EnvironmentalType.WATER_FLOW);
  }

  async getRiverForecastChart(
    {
      latitude,
      longitude,
      environmentalVariable,
      macroregionOrigin,
      dataInterval,
      stationId,
    }: ProbabilisticForecastParams,
    controller?: AbortController
  ) {
    const {
      dataStores: { unitMeasurementStore },
    } = getRoot<RootStore>();
    const { probabilisticForecastService } = getEnv<RootEnv>();

    this.setState(StoreState.PEDDING);

    if (controller) {
      this.requestCancellationStore.addController(controller);
    }

    try {
      let chartData = await probabilisticForecastService.getRiverForecastChart(
        {
          latitude,
          longitude,
          environmentalVariable,
          macroregionOrigin,
          dataInterval,
          stationId,
        },
        undefined,
        controller?.signal
      );

      const unitsUser = unitMeasurementStore.getUnitMeasurementConfig(
        environmentalVariable
      );

      const configMap = getRiverWaterLevelConfigMap();

      const createSerie = async (key: EnvironmentalVariables, data: any) => ({
        ...configMap[key].serieConfig,
        tooltip: {
          valueSuffix: unitsUser,
        },
        data: (await this.convertUnitMeasurementRiverProbabilisticSerie(
          data,
          unitsUser,
          chartData.units_symbol
        )) as number[][],
      });

      const max = unitMeasurementStore.convertUnit(
        chartData.max,
        chartData.units_symbol.toLowerCase() as Units,
        unitsUser as Units
      );

      const min = unitMeasurementStore.convertUnit(
        chartData.min,
        chartData.units_symbol.toLowerCase() as Units,
        unitsUser as Units
      );

      const series = [
        await createSerie(
          EnvironmentalVariables.RIVER_EXTREME_HIGH,
          chartData.extremeHigh
        ),
        await createSerie(
          EnvironmentalVariables.RIVER_VERY_HIGH,
          chartData.veryHigh
        ),
        await createSerie(EnvironmentalVariables.RIVER_HIGH, chartData.high),
        await createSerie(
          EnvironmentalVariables.RIVER_MOST_LIKELY,
          chartData.mostLikely
        ),
        await createSerie(EnvironmentalVariables.RIVER_LOW, chartData.low),
        await createSerie(
          EnvironmentalVariables.RIVER_VERY_LOW,
          chartData.veryLow
        ),
        await createSerie(
          EnvironmentalVariables.RIVER_EXTREME_LOW,
          chartData.extremeLow
        ),
        await createSerie(
          EnvironmentalVariables.RIVER_SENSOR,
          chartData.sensor
        ),
      ];

      this.setRiverForecastData({
        seriesData: series,
        timezoneOffset: chartData.timezoneOffset,
        yAxisData: {
          max: max * 1.1,
          min: min - min * 0.1,
          unitUser: unitsUser,
        },
      });
      this.setState(StoreState.DONE);
    } catch (error: any) {
      if (error.hasOwnProperty("response")) {
        if (error.response.status === 404) {
          this.setState(StoreState.DONE);
          this.setIsChartDataEmpty(true);
        } else {
          this.setMapState(StoreState.ERROR);
          this.setMessageError(
            i18n.t(
              "forecastChart.forecastChartPage.errorToGetDataStationMeteogramChartType"
            )
          );
        }
      }
    } finally {
      if (controller)
        this.requestCancellationStore.removeController(controller);
      setTimeout(() => {
        this.setState(StoreState.DONE);
      }, 2000);
    }
  }

  async getRiverStations(macroRegionOrigin?: string) {
    const { atmOceanService } = getEnv<RootEnv>();

    this.setStations([]);
    this.setMapState(StoreState.PEDDING);
    try {
      const stationLoaders: Partial<
        Record<
          VisualizationType,
          (macroRegionOrigin?: string) => Promise<IStation[]>
        >
      > = {
        [VisualizationType.FORECAST]: atmOceanService.loadRiverStations,
        [VisualizationType.SENSOR]: atmOceanService.loadSensorRiverStations,
      } as const;

      const loadStations = stationLoaders[this.visualizationType];

      if (!loadStations) {
        throw new Error(
          `Invalid visualization type: ${this.visualizationType}`
        );
      }

      const stationsResult: IStation[] = await loadStations(macroRegionOrigin);

      if (stationsResult.length > 0) {
        this.setStations(stationsResult);

        const found = stationsResult.find(
          (value) => value.station_id === this.selectedStation
        );
        if (stationsResult[0].region_id !== undefined) {
          this.setRegionId(stationsResult[0].region_id);
        }
        if (!found) {
          this.setSelectedStation(stationsResult[0].station_id);

          this.setClickMapLatLng({
            lat: parseFloat(stationsResult[0].station_lat.toString()),
            lng: parseFloat(stationsResult[0].station_lon.toString()),
          });
          this.setActionCallChart();
        } else {
          this.setSelectedStation(found.station_id);
          this.setActionCallChart();
        }
      } else {
        this.setSelectedStation(0);
      }

      this.setMapCenter();
      this.setMapState(StoreState.DONE);
    } catch (error: any) {
      this.setMapState(StoreState.ERROR);
      this.setMessageError(
        i18n.t(
          "forecastChart.forecastChartPage.errorToGetDataStationMeteogramChartType"
        )
      );
    }
  }

  async convertUnitMeasurementRiverProbabilisticSerie(
    data: number[][] | (number | number[])[][],
    unitsUser: string,
    unitsSymbol: string
  ) {
    const {
      dataStores: { unitMeasurementStore },
    } = getRoot<RootStore>();

    let convertedData: (number | number[])[][] = [];

    if (Array.isArray(data[0])) {
      convertedData = (data as (number | number[])[][]).map((serie) => {
        return serie.map((value, index) => {
          if (index === 0) {
            return value;
          }
          if (Array.isArray(value)) {
            return (value as number[]).map((val: number) =>
              unitMeasurementStore.convertUnit(
                val,
                unitsSymbol.toLowerCase() as Units,
                unitsUser as Units
              )
            );
          } else {
            return unitMeasurementStore.convertUnit(
              value as number,
              unitsSymbol.toLowerCase() as Units,
              unitsUser as Units
            );
          }
        });
      });
    }

    return convertedData;
  }

  async addLogUserExperienceAPI(events: ProductAnalyticsEvents) {
    const { logServices } = getEnv<RootEnv>();
    logServices.addLogUserExperience(events);
  }
}
