import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "react-datepicker/dist/react-datepicker.css";
import "./font.css";
import { StoreProvider } from "./stores/setup/store-context";
import { createStore } from "./stores/setup/create-store";
import { ChakraProvider } from "@chakra-ui/react";
import myTheme from "./styles/theme";

import "./i18n";

import * as Sentry from "@sentry/react";
import { getConfig } from "config/app.config";
import { ConfigKey } from "enum/ConfigKey";

import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";

Sentry.init({
  dsn: getConfig(ConfigKey.REACT_APP_SENTRY_DSN),
  ignoreErrors: [/^(?!.*500).*$/],
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    /^https:\/\/i4cast\.i4sea\.com\//, // Frontend URL
    /^https:\/\/i4cast-backend\.i4sea\.com\.br\/v1\//, // Backend URL
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

// Posthog - Product Analytics
posthog.init(getConfig(ConfigKey.REACT_APP_PUBLIC_POSTHOG_KEY), {
  api_host: getConfig(ConfigKey.REACT_APP_PUBLIC_POSTHOG_HOST),
  persistence: "localStorage",
  capture_pageview: false,
  autocapture: false,
  loaded: (posthog) => {
    console.log("PostHog loaded successfully");
  },
  debug: false,
});

const rootStore = createStore();

ReactDOM.render(
  <ChakraProvider theme={myTheme}>
    <StoreProvider value={rootStore}>
      <PostHogProvider client={posthog}>
        <App />
      </PostHogProvider>
    </StoreProvider>
  </ChakraProvider>,
  document.getElementById("root")
);
