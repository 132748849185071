import { Text, Box, Flex, Switch, useToast, Tooltip } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import React, {
  useCallback,
  useState,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { useStores } from "../../../stores/setup/use-store";
import ForecastChartFormStation from "../ForecastChartFormStation/ForecastChartFormStation";
import { ConfigProvider, Select } from "antd";
import {
  extractCoordinates,
  getCoordinatesWithinRadius,
} from "../../../util/getCoordinatesWithinRadius/getCoordinatesWithinRadius";
import { useTranslation } from "react-i18next";
import { StoreState } from "../../../enum/StoreState";
import { ReactComponent as IconPin } from "../../../assets/small-pin.svg";
import { SCREEN_HORIZONTAL_SPACING } from "../../../constants";
import useLoadFavoriteInsight from "./hooks/useLoadFavoriteInsight";
import { VisualizationType } from "enum/VisualizationType";

const ForecastChartPanelHeader: React.FC = () => {
  const {
    dataStores: {
      forecastChartPageStore,
      forecastChartInsightStore,
      forecastChartMeteogramStore,
    },
  } = useStores();

  const toast = useToast();
  const toastId = "toastWarningId";
  const selectStationToastId = "selectStationToastId";

  const { t } = useTranslation("translation", {
    keyPrefix: "forecastChart.forecastChartPanelHeader",
  });

  const [selectedInsightValue, setSelectedInsightValue] =
    useState<string | null>(null);
  const [clearSelect, setClearSelect] = useState<boolean>(false);
  const [hasSearch, setHasSearch] = useState<boolean>(false);

  const handleGetInsightList = useCallback(
    (filter?: string) => {
      setHasSearch(true);
      forecastChartInsightStore.getAllOperationRuleLocationInsight({
        limit: 20,
        filter,
        macroRegionOrigin: forecastChartPageStore.macroRegionOrigin,
      });
    },
    [forecastChartInsightStore]
  );

  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const debounceHandleGetInsightList = useCallback((filter: string) => {
    forecastChartInsightStore.setStateInsightList(StoreState.PEDDING);

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      handleGetInsightList(filter);
    }, 750);
  }, []);

  useEffect(() => {
    if (forecastChartPageStore.macroRegionOrigin) {
      forecastChartInsightStore.getAllOperationRuleLocationInsight({
        limit: 20,
        macroRegionOrigin: forecastChartPageStore.macroRegionOrigin,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forecastChartPageStore.macroRegionOrigin]);

  useEffect(() => {
    const hasDataEmpty = forecastChartInsightStore.hasSomeInsightWithoutData;

    if (
      !toast.isActive(toastId) &&
      hasDataEmpty &&
      forecastChartInsightStore.showInsightWithoutDataToast
    ) {
      toast({
        id: toastId,
        title: t("insightWithoutData"),
        description: t("theGrayIconIndicates"),
        status: "info",
        position: "top",
        isClosable: true,
      });

      forecastChartInsightStore.setShowInsightWithoutDataToast(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    forecastChartInsightStore.hasSomeInsightWithoutData,
    forecastChartMeteogramStore.selectedInsight?.id,
  ]);

  const getChartMeteogramDataByPeriod = async () => {
    if (!forecastChartMeteogramStore.selectedInsight) return;

    await forecastChartMeteogramStore.getChartMeteogramDataByPeriod({
      environmental_type: forecastChartPageStore.tabStateActivty,
      periodInHours: forecastChartMeteogramStore.periodSelected,
      create_at: forecastChartMeteogramStore.selectForecastDateAvailable,
    });
  };

  useEffect(() => {
    if (!forecastChartMeteogramStore.selectedInsight) return;
    if (!forecastChartPageStore.insightMode) return;

    if (!forecastChartMeteogramStore.showInsightOnMeteogram) return;

    getChartMeteogramDataByPeriod().then(() => {
      if (
        forecastChartMeteogramStore.insightMeteogram?.start === 0 ||
        forecastChartMeteogramStore.insightMeteogram?.end === 0 ||
        (forecastChartMeteogramStore.allPositionsAreNull(
          forecastChartMeteogramStore.insightMeteogram?.data
        ) &&
          !toast.isActive(toastId))
      ) {
        toast({
          id: toastId,
          title: t("noEventsForThisStation"),
          status: "info",
          position: "top",
          isClosable: true,
        });
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forecastChartMeteogramStore.selectedInsight]);

  const handleChangeInsight = useCallback(
    (value: string) => {
      if (!value) {
        return;
      }
      forecastChartInsightStore.resetMultiStationInsight();
      setClearSelect(false);

      setSelectedInsightValue(value);

      const id = value.split("-")[0];

      const insights = forecastChartInsightStore.filteredInsightList.length
        ? forecastChartInsightStore.filteredInsightList
        : forecastChartInsightStore.insightList;

      const insight = insights?.find((i) => {
        return i.id.toString() === id;
      });

      if (!insight) {
        return;
      }

      forecastChartPageStore.setInsightMode(!!insight);
      forecastChartMeteogramStore.resetInsightMeteogram();
      forecastChartMeteogramStore.setSelectedInsight(insight);

      const { station_lat, station_lon } =
        forecastChartPageStore.getStationByIdAndRegionId(
          forecastChartPageStore.selectedStation,
          forecastChartPageStore.regionId
        ) || {};

      if (!station_lat || !station_lon) {
        if (!toast.isActive(selectStationToastId))
          toast({
            id: selectStationToastId,
            title: t("selectStation"),
            status: "info",
            position: "top",
            isClosable: true,
          });
        return;
      }

      if (!forecastChartPageStore.clickMapLatLng) {
        forecastChartPageStore.setClickMapLatLng({
          lat: station_lat,
          lng: station_lon,
        });
      }

      const stationCoordinates = {
        latitude: station_lat,
        longitude: station_lon,
      };

      const coordinatesList = getCoordinatesWithinRadius(
        stationCoordinates,
        extractCoordinates(forecastChartPageStore.stations),
        forecastChartInsightStore.radius
      );

      forecastChartInsightStore.setSelectedCoordinatesWithinRadius(
        coordinatesList
      );
    },
    [
      forecastChartInsightStore,
      forecastChartMeteogramStore,
      forecastChartPageStore,
      t,
      toast,
    ]
  );

  const handleChangeShowInsightOnChart = useCallback(
    async (showInsight: boolean) => {
      if (showInsight) {
        forecastChartMeteogramStore.setOpenMeteogram(true);
      }

      await forecastChartMeteogramStore.handleShowInsightOnMeteogram(
        showInsight
      );
      if (
        forecastChartMeteogramStore.insightMeteogram?.start === 0 ||
        forecastChartMeteogramStore.insightMeteogram?.end === 0 ||
        (forecastChartMeteogramStore.allPositionsAreNull(
          forecastChartMeteogramStore.insightMeteogram?.data
        ) &&
          !toast.isActive(toastId))
      ) {
        toast({
          id: toastId,
          title: t("noEventsForThisStation"),
          status: "info",
          position: "top",
          isClosable: true,
        });
      }
    },
    [forecastChartInsightStore, forecastChartMeteogramStore, t, toast]
  );

  useLoadFavoriteInsight(hasSearch, handleChangeInsight);

  const renderEmpty = () => {
    if (forecastChartInsightStore.stateInsightList === StoreState.PEDDING) {
      return <h1> {t("loading")}...</h1>;
    }

    if (forecastChartInsightStore.isInsightSearchListEmpty) {
      return <h1>{t("noData")}</h1>;
    }
  };

  const isDisabledSwitch = useCallback(() => {
    if (!forecastChartMeteogramStore.selectedInsight) {
      return true;
    }

    if (!forecastChartMeteogramStore.showMeteogram) {
      return true;
    }

    const insightStation =
      forecastChartInsightStore.insightMultiStation?.stationsData.find(
        (i) =>
          i.stationId === forecastChartPageStore.selectedStation &&
          i.regionId === forecastChartPageStore.regionId
      );

    if (!insightStation?.innerCircle) {
      return true;
    }

    if (forecastChartMeteogramStore.selectForecastDateAvailable) {
      return true;
    }

    if (
      forecastChartMeteogramStore.isSensorMarker &&
      forecastChartMeteogramStore.visualizationType === VisualizationType.SENSOR
    ) {
      return true;
    }

    return false;
  }, [
    forecastChartInsightStore.insightMultiStation,
    forecastChartMeteogramStore.selectForecastDateAvailable,
    forecastChartMeteogramStore.selectedInsight,
    forecastChartMeteogramStore.showMeteogram,
    forecastChartPageStore.regionId,
    forecastChartPageStore.selectedStation,
    forecastChartMeteogramStore.isSensorMarker,
  ]);

  const renderTooltipText = useCallback(() => {
    if (
      !forecastChartMeteogramStore.selectedInsight &&
      !forecastChartPageStore.insightMode
    ) {
      return t("selectInsightToEnable");
    }

    const insightStation =
      forecastChartInsightStore.insightMultiStation?.stationsData.find(
        (i) =>
          i.stationId === forecastChartPageStore.selectedStation &&
          i.regionId === forecastChartPageStore.regionId
      );

    if (!insightStation?.innerCircle) {
      return t("thereIsNoInsightDataAvailable");
    }
  }, [
    forecastChartInsightStore.insightMultiStation,
    forecastChartMeteogramStore.selectedInsight,
    forecastChartPageStore.insightMode,
    forecastChartPageStore.regionId,
    forecastChartPageStore.selectedStation,
    t,
  ]);

  const onClearInsightList = useCallback(() => {
    forecastChartInsightStore.resetMultiStationInsight();

    forecastChartPageStore.setInsightMode(false);
    forecastChartMeteogramStore.setSelectedInsight(null);
    forecastChartMeteogramStore.resetInsightMeteogram();
    handleChangeShowInsightOnChart(false);
    forecastChartInsightStore.setFilteredInsightList([]);
    setClearSelect(true);
    setSelectedInsightValue(null);
  }, [
    forecastChartInsightStore,
    forecastChartMeteogramStore,
    forecastChartPageStore,
    handleChangeShowInsightOnChart,
  ]);

  const isFavoriteInsight = useMemo(() => {
    if (!forecastChartInsightStore.favoriteInsight) return false;
    if (!forecastChartInsightStore.favoriteInsightId) return false;
    if (!forecastChartMeteogramStore.selectedInsight) return false;

    const selectedInsightId = forecastChartMeteogramStore.selectedInsight.id;

    if (forecastChartInsightStore.favoriteInsightId === selectedInsightId) {
      return true;
    }

    return false;
  }, [
    forecastChartInsightStore.favoriteInsight,
    forecastChartInsightStore.favoriteInsightId,
    forecastChartMeteogramStore.selectedInsight,
  ]);

  const handleFavoriteInsight = useCallback(() => {
    if (!forecastChartMeteogramStore.selectedInsight) return;
    if (forecastChartInsightStore.stateFavorite === StoreState.PEDDING) return;

    if (isFavoriteInsight && forecastChartInsightStore.favoriteInsightId) {
      forecastChartInsightStore.deleteOperationRuleLocationItemAsFavorite(
        forecastChartInsightStore.favoriteInsightId
      );
      forecastChartInsightStore.setFavoriteInsight(null);
      forecastChartInsightStore.setFavoriteInsightId(null);
    } else {
      forecastChartInsightStore.addOperationRuleLocationItemAsFavorite(
        forecastChartMeteogramStore.selectedInsight
      );
    }
  }, [
    forecastChartInsightStore,
    forecastChartMeteogramStore.selectedInsight,
    isFavoriteInsight,
  ]);

  const value = useMemo(() => {
    if (clearSelect) {
      return null;
    } else if (selectedInsightValue) {
      return selectedInsightValue;
    } else if (
      forecastChartInsightStore.favoriteInsight &&
      forecastChartPageStore.insightMode
    ) {
      return forecastChartInsightStore.favoriteInsight;
    }
  }, [
    clearSelect,
    forecastChartInsightStore.favoriteInsight,
    forecastChartPageStore.insightMode,
    selectedInsightValue,
  ]);

  return (
    <Box
      className="joyride-geospatial"
      bg="white"
      color="newBlue.500"
      position={"absolute"}
      w="300px"
      borderRadius={8}
      mt="7px"
      ml={SCREEN_HORIZONTAL_SPACING}
      p={2}
      zIndex={9999999}
    >
      {forecastChartPageStore.stationInfo && (
        <Flex alignItems={"center"} justifyContent={"space-between"} mb={2}>
          <Flex alignItems={"center"}>
            <i
              style={{ color: "#286795", marginRight: "5px" }}
              className="fas fa-map-marker-alt fa-1x"
            />
            <Text fontSize="sm" px={1}>
              {forecastChartPageStore.stationInfo}
            </Text>
          </Flex>
          <ForecastChartFormStation
            showFormAddStation={forecastChartPageStore.selectedStation === 0}
            showButtonRemoveStation={forecastChartPageStore.stationSavedByUser(
              forecastChartPageStore.selectedStation
            )}
          />
        </Flex>
      )}
      <Flex alignItems={"center"} gap={"8px"} mt="5px">
        <ConfigProvider renderEmpty={renderEmpty}>
          <Select
            showSearch
            allowClear
            onClear={onClearInsightList}
            placeholder={t("enterYourInsight")}
            style={{ width: "257px", height: "auto", marginBottom: "5px" }}
            value={value}
            onChange={(value) => handleChangeInsight(value)}
            onSearch={debounceHandleGetInsightList}
            options={forecastChartInsightStore.getInsightListForSelectOptions()}
            loading={
              forecastChartInsightStore.stateInsightList === StoreState.PEDDING
            }
            optionRender={(option) => (
              <>
                <h3>{option.data.label}</h3>
                <p style={{ color: "grey" }}>{option.data.desc}</p>
              </>
            )}
          />
        </ConfigProvider>
        {forecastChartMeteogramStore.selectedInsight && (
          <Box mr="-4px">
            <Tooltip
              label={
                isFavoriteInsight ? t("removeFavorite") : t("markAsFavorite")
              }
              fontSize="sm"
              hasArrow
              placement="top"
            >
              <Box cursor={"pointer"} onClick={handleFavoriteInsight}>
                <IconPin
                  style={{
                    fill: isFavoriteInsight ? "#6a7578" : "",
                    marginBottom: 5,
                  }}
                />
              </Box>
            </Tooltip>
          </Box>
        )}
      </Flex>

      <Tooltip label={renderTooltipText()} fontSize="sm" placement="bottom">
        <Flex alignItems={"center"} gap={2}>
          <Switch
            p={0}
            colorScheme="whatsapp"
            size="sm"
            id="show-insight-chart"
            isChecked={forecastChartMeteogramStore.showInsightOnMeteogram}
            onChange={(e) => handleChangeShowInsightOnChart(e.target.checked)}
            isDisabled={isDisabledSwitch()}
          />
          <Text fontSize="14px" size="sm">
            {t("showInsightInForecastCharts")}
          </Text>
        </Flex>
      </Tooltip>
    </Box>
  );
};

export default observer(ForecastChartPanelHeader);
