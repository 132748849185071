import axios from "axios";
import { CancelToken } from "axios";
import { DateTime } from "luxon";
import { makeAutoObservable } from "mobx";
import { getEnv, getRoot } from "mobx-easy";
import {
  EnvironmentalType,
  MeteogramVariables,
} from "../../../../enum/EnvironmentalType";
import { StoreState } from "../../../../enum/StoreState";
import RootStore from "../../../root-store";
import { RootEnv } from "../../../setup/create-store";
import {
  IAtmOceanLineChart,
  IAtmOceanMeteogram,
  IAtmOceanMeteogramItem,
  IFilterChartMeteogramDataSeriesParams,
  IGetChartMeteogramDataByPeriodParams,
} from "../../atmOcean/atmOceanModel";
import UnitMeasurementStore, {
  Units,
} from "../../unitMeasurement/UnitMeasurementStore";
import {
  chartMeteogramDataMock,
  chartMeteogramYAxisMock,
  chartMeteogramXAxisMock,
} from "./forecastChartMeteogram.mock";
import i18n from "../../../../i18n";
import { IInsightMeteogram } from "../../../../types/IInsight";
import { IOperationInsightDescription } from "../../operationRuleLocationInsights/operationRuleLocationInsightModel";
import { ProductAnalyticsEvents } from "../../../../enum/ProductAnalyticsEvents";
import { EnvironmentalVariables } from "../../../../enum/EnvironmentalVariables";
import { ISensorMeasuredData } from "../../../../types/ISensorMeasuredData";
import { VisualizationType } from "../../../../enum/VisualizationType";
import { processSerie } from "../../../../util/meteogramCharts/processSerie/processSerie";
import { getWeatherConfigMap } from "../../../../util/meteogramCharts/processSerie/common/getWeatherConfigMap";
import { getWaterFlowConfigMap } from "../../../../util/meteogramCharts/processSerie/common/getWaterFlowConfigMap";
import { getTemperatureConfigMap } from "../../../../util/meteogramCharts/processSerie/common/getTemperatureConfigMap";
import { getCurrentConfigMap } from "../../../../util/meteogramCharts/processSerie/common/getCurrentConfigMap";
import { getWaveConfigMap } from "../../../../util/meteogramCharts/processSerie/common/getWaveConfigMap";
import { getPrecipConfigMap } from "../../../../util/meteogramCharts/processSerie/common/getPrecipConfigMap";
import { getEnvironmentalVariablesDefault } from "../../../../util/meteogramCharts/processSerie/common/getEnvironmentalVariablesDefault";
import RequestCancellationStore from "../../../data/requestCancellationStore/RequestCancellationStore";
import { Axis, Chart, Options, Series } from "highcharts";
import { calculateAdjustedValue } from "util/meteogramCharts/processSerie/calculateAdjustedValue";
import { YAxisConfig } from "util/meteogramCharts/processSerie/common/types/ISerieConfigMap";
import { getVisibilityConfigMap } from "util/meteogramCharts/processSerie/common/getVisibilityConfigMap";
import { visibilitySeriesMock } from "stores/data/analytics/visibilityMock";

export interface IEnvironmentalDataMeteogramNetCDFApiParams {
  latitude: number;
  longitude: number;
  environmental_type: EnvironmentalType;
  macroRegionOrigin: string;
  periodInHours?: number;
  controller?: AbortController;
  stationId?: number;
}

type CustomUserOptions = Series["userOptions"] & {
  environmentalVariable: EnvironmentalVariables;
  visualizationType: VisualizationType;
  unitSymbol: string;
  defaultMaxConverted: number;
};

type CustomSeries = (Omit<Series, "userOptions"> & {
  userOptions: CustomUserOptions;
  options: Options & {
    yAxisConfig?: YAxisConfig;
    visible: boolean;
    index: number;
  };
})[];

type CustomAxis = {
  yAxisConfig?: YAxisConfig;
  visible: boolean;
  max: number;
  index: number;
  defaultMax?: number;
};

type CustomOtionAxis = Omit<Axis, "options"> & {
  options: Options & CustomAxis;
};

type CustomChart = Omit<Chart, "series" | "axes" | "yAxis"> & {
  series: CustomSeries;
  yAxis: CustomOtionAxis[];
  axes: CustomOtionAxis[];
};

type CustomHighChartSeries = Omit<Highcharts.Series, "chart"> & {
  chart: CustomChart;
};
export default class ForecastChartMeteogramStore {
  chartEnvionmentaldata: IAtmOceanLineChart = {
    limits: { min: 0, max: 5 },
    serie: { name: "", units: "", data: [] },
  };
  chartMeteogramData: IAtmOceanMeteogram = chartMeteogramDataMock;
  charMeteogramDataFiltered: IAtmOceanMeteogram = chartMeteogramDataMock;

  chartMeteogramDataSeries: any[] = [];
  chartMeteogramYAxis: any[] = chartMeteogramYAxisMock;
  chartMeteogramXAxis: any[] = chartMeteogramXAxisMock;

  state: string = StoreState.DONE;

  messageError: string = "";

  messageSuccess: string = "";

  visibleMeteogramChart: boolean = false;

  selectForecastDateAvailable: DateTime | undefined;

  lastUpdateTime?: string | null;

  nextUpdateTime?: string | null;

  isChartDataEmpty: boolean = false;

  showUpdateTime: boolean = true;

  periodSelected?: number = 24; //24h

  realInterval?: number | undefined; //15min

  show48hButton: boolean = false;

  show72hButton: boolean = false;

  isOpenShareModal: boolean = false;

  insights: IOperationInsightDescription[] = [];

  insightMeteogram: IInsightMeteogram | null;

  selectedInsight: IOperationInsightDescription | null;

  showInsightOnMeteogram: boolean = false;

  currentInsightMeteogramData: (number | null)[][] = [];

  insightState: string = StoreState.DONE;

  showMeteogram: boolean | undefined = undefined;

  openMeteogram: boolean = true;

  visualizationType: VisualizationType = VisualizationType.FORECAST;

  selectedEnvironmentalVariables: EnvironmentalVariables[] =
    getEnvironmentalVariablesDefault(EnvironmentalType.WEATHER);

  isSensorMarker: boolean = false;

  selectedSensorMarker: ISensorMeasuredData | undefined = undefined;

  requestCancellationStore: RequestCancellationStore;

  constructor(requestCancellationStore: RequestCancellationStore) {
    makeAutoObservable(this);
    this.requestCancellationStore = requestCancellationStore;
  }

  clearSelectForecastDateAvailable() {
    this.selectForecastDateAvailable = undefined;
  }

  setSelectForecastDateAvailable(values: DateTime) {
    this.selectForecastDateAvailable = values;
  }

  setState(value: StoreState) {
    this.state = value;
  }

  setMessageError(value: string) {
    this.messageError = value;
  }

  setMessageSuccess(value: string) {
    this.messageSuccess = value;
  }

  setChartMeteogramData(value: IAtmOceanMeteogram) {
    this.chartMeteogramData = value;
  }

  setCharMeteogramDataFiltered(value: IAtmOceanMeteogram) {
    this.charMeteogramDataFiltered = value;
  }

  setChartMeteogramDataSeries(value: any[]) {
    this.chartMeteogramDataSeries = value;
  }

  setChartMeteogramYAxis(value: any[]) {
    this.chartMeteogramYAxis = value;
  }

  setChartMeteogramXAxis(value: any[]) {
    this.chartMeteogramXAxis = value;
  }

  setVisibleMeteogramChart(value: boolean) {
    this.visibleMeteogramChart = value;
  }

  setLastUpdateTime(value: string | undefined) {
    this.lastUpdateTime = value;
  }

  setNextUpdateTime(value: string | undefined) {
    this.nextUpdateTime = value;
  }

  setIsChartDataEmpty(value: boolean) {
    this.isChartDataEmpty = value;
  }

  setShowUpdateTime(value: boolean) {
    this.showUpdateTime = value;
  }

  setPeriodSelected(value?: number) {
    this.periodSelected = value;
  }

  setRealInterval(value?: number) {
    this.realInterval = value;
  }

  setShow48hButton(value: boolean) {
    this.show48hButton = value;
  }

  setShow72hButton(value: boolean) {
    this.show72hButton = value;
  }

  setOpenShareModal(value: boolean) {
    this.isOpenShareModal = value;
  }

  setInsights(value: IOperationInsightDescription[]) {
    this.insights = value;
  }

  setInsightMeteogram(value: IInsightMeteogram | null) {
    this.insightMeteogram = value;
  }

  setSelectedInsight(value: IOperationInsightDescription | null) {
    this.selectedInsight = value;
  }

  setShowInsightOnMeteogram(value: boolean) {
    this.showInsightOnMeteogram = value;
  }

  setCurrentInsightMeteogramData(value: (number | null)[][]) {
    this.currentInsightMeteogramData = value;
  }

  setInsightState(value: StoreState) {
    this.insightState = value;
  }

  setShowMeteogram(value: boolean | undefined) {
    this.showMeteogram = value;
  }

  setOpenMeteogram(value: boolean) {
    this.openMeteogram = value;
  }

  setSelectedEnvironmentalVariables(value: EnvironmentalVariables[]) {
    this.selectedEnvironmentalVariables = value;
  }

  setVisualizationType(value: VisualizationType) {
    this.visualizationType = value;
  }

  setIsSensorMarker(value: boolean) {
    this.isSensorMarker = value;
  }

  setSelectedSensorMarker(value: ISensorMeasuredData | undefined) {
    this.selectedSensorMarker = value;
  }

  resetChartMeteogramData() {
    this.setChartMeteogramYAxis(chartMeteogramYAxisMock);
    this.setChartMeteogramXAxis(chartMeteogramXAxisMock);
    this.setChartMeteogramDataSeries([]);
    this.setChartMeteogramData(chartMeteogramDataMock);
  }

  resetChartMeteogramDataFiltered() {
    this.setChartMeteogramYAxis(chartMeteogramYAxisMock);
    this.setChartMeteogramXAxis(chartMeteogramXAxisMock);
    this.setChartMeteogramDataSeries([]);
    this.setCharMeteogramDataFiltered(chartMeteogramDataMock);
  }

  resetInsightMeteogram() {
    this.setShowInsightOnMeteogram(false);
    this.setInsightMeteogram(null);
    this.setCurrentInsightMeteogramData([]);
  }

  reset() {
    this.resetChartMeteogramData();
    this.resetChartMeteogramDataFiltered();
    this.resetInsightMeteogram();
    this.clearSelectForecastDateAvailable();
    this.setState(StoreState.DONE);
    this.setMessageError("");
    this.setMessageSuccess("");
    this.resetUpdateTime();
    this.setIsChartDataEmpty(false);
    this.setShowUpdateTime(true);
    this.setPeriodSelected(24);
    this.setShow48hButton(false);
    this.setShow72hButton(false);
    this.setOpenShareModal(false);
    this.setInsights([]);
    this.setInsightState(StoreState.DONE);
    this.setShowMeteogram(true);
    this.setSelectedEnvironmentalVariables(
      getEnvironmentalVariablesDefault(EnvironmentalType.WEATHER)
    );
    this.setVisualizationType(VisualizationType.FORECAST);
    this.setSelectedSensorMarker(undefined);
    this.setSelectedInsight(null);
  }

  resetUpdateTime() {
    this.setLastUpdateTime("");
    this.setNextUpdateTime("");
  }

  async getEnvironmentalDataMeteogramNetCDFApi({
    latitude,
    longitude,
    environmental_type,
    macroRegionOrigin,
    controller,
    stationId,
  }: IEnvironmentalDataMeteogramNetCDFApiParams) {
    const { atmOceanService } = getEnv<RootEnv>();
    this.setState(StoreState.PEDDING);
    this.resetChartMeteogramData();
    this.setShowUpdateTime(true);
    try {
      if (controller) {
        this.requestCancellationStore.addController(controller);
      }

      let chartData: IAtmOceanMeteogram =
        await atmOceanService.getEnvironmentalDataMeteogramNetCDF(
          latitude,
          longitude,
          environmental_type,
          macroRegionOrigin,
          1 * 60, //1min in seconds
          controller,
          stationId
        );
      this.setChartMeteogramData(chartData);

      await this.getChartMeteogramDataByPeriod({
        environmental_type,
        periodInHours: this.periodSelected,
      });

      this.setState(StoreState.DONE);
    } catch (error: any) {
      console.log(error);
      if (axios.isCancel(error)) {
        console.log("axios request cancelled");
      } else if (
        error.hasOwnProperty("response") &&
        error.response.status === 404
      ) {
        this.setIsChartDataEmpty(true);
      } else {
        this.setState(StoreState.ERROR);
        this.setIsChartDataEmpty(true);
        this.setMessageError(
          i18n.t(
            "forecastChart.forecastChartMeteogramStore.errorTryGetDataChartMeteogram"
          )
        );
      }
    } finally {
      if (controller) {
        this.requestCancellationStore.removeController(controller);
      }
      setTimeout(() => {
        this.setMessageError("");
        this.setState(StoreState.DONE);
      }, 2000);
    }
  }

  async getChartMeteogramDataApi(
    station_id: number,
    environmental_type: EnvironmentalType,
    cancelToken: CancelToken,
    macroRegionOrigin: string,
    create_at?: string
  ) {
    const { atmOceanService } = getEnv<RootEnv>();
    let createAtDate;

    this.setState(StoreState.PEDDING);

    if (create_at) {
      createAtDate = DateTime.fromISO(create_at);
      this.setShowUpdateTime(false);
    } else {
      this.setShowUpdateTime(true);
    }

    try {
      if (station_id > 0) {
        let chartData: IAtmOceanMeteogram =
          await atmOceanService.getEnvironmentalDataMeteogram({
            station_id: station_id,
            environmental_type: environmental_type,
            cancelToken: cancelToken,
            macro_region_origin: macroRegionOrigin,
            dataInterval: 1 * 60, //1min
            create_at: create_at,
          });

        if (!chartData.series?.length) {
          this.setIsChartDataEmpty(true);
        }

        this.setChartMeteogramData(chartData);
      }
      await this.getChartMeteogramDataByPeriod({
        environmental_type,
        periodInHours: this.periodSelected,
        create_at: createAtDate,
      });

      this.setState(StoreState.DONE);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("axios request cancelled");
      } else {
        this.setState(StoreState.ERROR);
        this.setIsChartDataEmpty(true);
        this.setMessageError(
          i18n.t(
            "forecastChart.forecastChartMeteogramStore.errorTryGetDataChartMeteogram"
          )
        );
        this.setMessageError("");
      }
    }
  }

  async convertUnitMeasurementMeteogramSerie(chartData: IAtmOceanMeteogram) {
    const {
      dataStores: { unitMeasurementStore },
    } = getRoot<RootStore>();

    chartData.series.forEach((serie) => {
      const unitsUser = unitMeasurementStore.getUnitMeasurementConfig(
        serie.environmental_variable as MeteogramVariables
      );
      if (unitsUser && unitsUser !== serie.units_symbol.toLowerCase()) {
        serie.data = serie.data.map((value: any) => {
          if (typeof value === "number") {
            return unitMeasurementStore.convertUnit(
              value,
              serie.units_symbol.toLowerCase() as Units,
              unitsUser as Units
            );
          } else if (typeof value === "object") {
            if (value.length === 2) {
              return [
                value[0],
                unitMeasurementStore.convertUnit(
                  value[1],
                  serie.units_symbol.toLowerCase() as Units,
                  unitsUser as Units
                ),
              ];
            } else if (value.length === 3) {
              if (
                serie.environmental_variable ===
                  MeteogramVariables.PRECIP_MIN_20KM ||
                serie.environmental_variable ===
                  MeteogramVariables.VISIBILITY_MINIMUM_20KM
              ) {
                return [
                  value[0],
                  unitMeasurementStore.convertUnit(
                    value[1],
                    serie.units_symbol.toLowerCase() as Units,
                    unitsUser as Units
                  ),
                  unitMeasurementStore.convertUnit(
                    value[2],
                    serie.units_symbol.toLowerCase() as Units,
                    unitsUser as Units
                  ),
                ];
              }
              return [
                value[0],
                unitMeasurementStore.convertUnit(
                  value[1],
                  serie.units_symbol.toLowerCase() as Units,
                  unitsUser as Units
                ),
                value[2],
              ];
            }
          }
          return value;
        });
      }
    });
    return chartData;
  }

  allPositionsAreNull(meteogramInsightData: (number | null)[][] | undefined) {
    if (meteogramInsightData) {
      const areAllPositionsEmpty = meteogramInsightData.every(
        (positionData) => {
          return (
            positionData.length === 3 &&
            positionData[1] === null &&
            positionData[2] === null
          );
        }
      );
      return areAllPositionsEmpty;
    }
    return false;
  }

  renderInsightTitleOnMeteogram(maxLength: number) {
    let title: string | undefined = "";

    if (this.allPositionsAreNull(this.insightMeteogram?.data)) {
      title = `[${i18n.t(
        "forecastChart.forecastChartMeteogramStore.noUpcomingEvents"
      )}] - ${this.selectedInsight?.name}`;
    } else {
      title = this.selectedInsight?.name;
    }

    if (title) {
      if (title.length <= maxLength) {
        return title;
      }

      const truncatedTitle = title.substring(0, maxLength) + "...";

      return truncatedTitle;
    }
  }

  async processMeteogramSerie(
    environmentalType: EnvironmentalType,
    series: any[]
  ) {
    const { serieProcessed, yAxisProcessed, xAxisProcessed } =
      this.getProcessedSerieByEnvironmentalType(environmentalType, series);

    if (!yAxisProcessed?.length) {
      this.setIsChartDataEmpty(true);
      return;
    }

    this.setChartMeteogramDataSeries(serieProcessed);
    this.setChartMeteogramYAxis(yAxisProcessed);
    if (xAxisProcessed?.length) {
      this.setChartMeteogramXAxis(xAxisProcessed);
    }
  }

  getProcessedSerieByEnvironmentalType(
    environmentalType: EnvironmentalType,
    series: any[]
  ) {
    let serieProcessed: any[] = [];
    let yAxisProcessed: any[] = [];
    let xAxisProcessed: any[] = [];

    const {
      dataStores: { unitMeasurementStore, forecastChartPageStore },
    } = getRoot<RootStore>();

    const isVattenfall =
      forecastChartPageStore.macroRegionOrigin &&
      forecastChartPageStore.vattenfallRegions.includes(
        forecastChartPageStore.macroRegionOrigin
      );

    let _environmentalVariablesDefault = getEnvironmentalVariablesDefault(
      environmentalType,
      isVattenfall
    );

    const isSameTypeEnv = this.checkHasSameTypeSelectedEnv(
      this.selectedEnvironmentalVariables,
      _environmentalVariablesDefault
    );
    if (!isSameTypeEnv) {
      this.setSelectedEnvironmentalVariables(_environmentalVariablesDefault);
    }

    const forecastSeries = series?.filter(
      (item) => item.visualizationType !== VisualizationType.SENSOR
    );
    const sensorSeries = series?.filter(
      (item) => item.visualizationType === VisualizationType.SENSOR
    );

    const processResult = this.processEnvironmentalType(
      environmentalType,
      forecastSeries,
      VisualizationType.FORECAST,
      unitMeasurementStore,
      this.addEnvironmentalVariable
    );
    serieProcessed = serieProcessed?.concat(processResult.serie);
    yAxisProcessed = yAxisProcessed?.concat(processResult.yAxis);

    let sensorProcessResult = this.processEnvironmentalType(
      environmentalType,
      sensorSeries,
      VisualizationType.SENSOR,
      unitMeasurementStore,
      this.addEnvironmentalVariable
    );

    if (yAxisProcessed?.length) {
      let yAxisMax = yAxisProcessed[0]?.max;
      let yAxisMin = yAxisProcessed[0]?.min;

      if (sensorProcessResult?.serie.length) {
        const sensorYAxisMax = sensorProcessResult.yAxis?.[0]?.max;
        const sensorYAxisMin = sensorProcessResult.yAxis?.[0]?.min;

        if (sensorYAxisMax > yAxisMax) {
          yAxisProcessed[0].max = calculateAdjustedValue(
            yAxisMax,
            sensorYAxisMax
          );
        }

        if (sensorYAxisMin < yAxisMin) {
          yAxisProcessed[0].min = calculateAdjustedValue(
            yAxisMin,
            sensorYAxisMin,
            false
          );
        }
      }

      if (!yAxisProcessed[1]) {
        yAxisProcessed[1] = {
          labels: yAxisProcessed[0]?.labels,
          min: 0,
          max: yAxisProcessed[0]?.max,
          title: yAxisProcessed[0]?.title,
          gridLineWidth: 1,
          opposite: true,
        };
      }
    }

    if (
      this.selectedInsight &&
      this.showInsightOnMeteogram &&
      this.selectForecastDateAvailable === undefined
    ) {
      serieProcessed.push({
        name: this.renderInsightTitleOnMeteogram(25),
        type: "arearange",
        yAxis: yAxisProcessed.length,
        xAxis: 0,
        marker: {
          enabled: false,
        },
        tooltip: {
          snap: 0,
        },
        data: this.currentInsightMeteogramData,
        color: `${this.insightMeteogram?.color}7d`,
      });
      yAxisProcessed[yAxisProcessed.length] = {
        min: 0,
        max: 50,
        labels: {
          enabled: false,
        },
        title: {
          text: null,
        },
      };
      xAxisProcessed = [
        {
          type: "datetime",
          gridLineWidth: 1,
          offset: 40,
        },
        {
          currentDateIndicator: {
            color: "#54f542",
            width: 2,
            zIndex: 4,
            dashStyle: "dash",
          },
          type: "datetime",
          gridLineWidth: 0,
          offset: 40,
          labels: {
            enabled: false,
          },
          title: {
            text: null,
          },
        },
      ];
    }

    sensorProcessResult.serie = sensorProcessResult.serie.map((serie) => {
      return {
        ...serie,
        name: "Sensor - " + serie.name,
      };
    });

    serieProcessed.unshift(...sensorProcessResult.serie);

    return { serieProcessed, yAxisProcessed, xAxisProcessed };
  }

  processEnvironmentalType(
    environmentalType: EnvironmentalType,
    series: any[] = visibilitySeriesMock,
    visualizationType: VisualizationType,
    unitMeasurementStore: UnitMeasurementStore,
    addEnvironmentalVariable: any
  ) {
    let configMapFunction;
    switch (environmentalType) {
      case EnvironmentalType.WATER_FLOW:
        configMapFunction = getWaterFlowConfigMap;
        break;
      case EnvironmentalType.CURRENT:
        configMapFunction = getCurrentConfigMap;
        break;
      case EnvironmentalType.WEATHER:
        configMapFunction = getWeatherConfigMap;
        break;
      case EnvironmentalType.VISIBILITY:
        configMapFunction = getVisibilityConfigMap;
        break;
      case EnvironmentalType.WEATHER_TEMPERATURE:
        configMapFunction = getTemperatureConfigMap;
        break;
      case EnvironmentalType.WAVE:
      case EnvironmentalType.WAVE_SEA:
      case EnvironmentalType.WAVE_SWELL:
        configMapFunction = getWaveConfigMap;
        break;
      case EnvironmentalType.PRECIPITATION:
        configMapFunction = getPrecipConfigMap;
        break;
      default:
        throw new Error("Invalid environmental type");
    }
    try {
      const processSerieResult = processSerie({
        series,
        selectedEnvironmentalVariables: this.selectedEnvironmentalVariables,
        configMap: configMapFunction(visualizationType),
        getUnitMeasurementConfig: unitMeasurementStore.getUnitMeasurementConfig,
        convertUnit: unitMeasurementStore.convertUnit,
        addEnvironmentalVariable,
        visualizationType,
      });

      return processSerieResult;
    } catch (error) {
      console.error("Error processing serie", error);
    }
    return { serie: [], yAxis: [] };
  }

  checkHasSameTypeSelectedEnv(
    selectedEnvironmentalVariables: EnvironmentalVariables[],
    defaultEnvironmentalVariables: EnvironmentalVariables[]
  ) {
    return selectedEnvironmentalVariables
      .filter((x) => x !== EnvironmentalVariables.PRECIP)
      .some((env) => defaultEnvironmentalVariables.includes(env));
  }

  showButtonControl(hoursData: number) {
    if (hoursData > 48) {
      this.setShow48hButton(true);
      this.setShow72hButton(true);
    } else if (hoursData > 24) {
      this.setShow48hButton(true);
    }
  }

  filterChartMeteogramDataSeries({
    series,
    intervalInMilliseconds,
    periodInHours,
    create_at,
  }: IFilterChartMeteogramDataSeriesParams): IAtmOceanMeteogramItem[] {
    const dataReference = create_at ? create_at.toUTC() : DateTime.utc();
    let realInterval: number | undefined;

    series.forEach((serie) => {
      const data = serie.data as number[][];
      const lastDate = data[data.length - 1];
      const isDirection =
        serie.environmental_variable?.endsWith("_dir") ||
        serie.environmental_variable?.includes("_dir_");
      if (lastDate?.length && lastDate[0]) {
        const lastDateFormat = DateTime.fromMillis(lastDate[0]);
        const hoursData = lastDateFormat.diff(DateTime.utc(), "hours").hours;
        this.showButtonControl(hoursData);
      }
      let dataFiltered: number[][] = [];
      data.map((item: any, index: number) => {
        if (periodInHours) {
          if (
            item[0] <=
              dataReference.plus({ hours: periodInHours }).toMillis() &&
            item[0] % intervalInMilliseconds === 0
          ) {
            if (isDirection) {
              if (index % 3 === 0) {
                dataFiltered.push([item[0], item[1], item[2]]);
              } else {
                dataFiltered.push([item[0], null, null]);
              }
            } else {
              dataFiltered.push([item[0], item[1], item[2]]);
            }
          }
        } else if (item[0] % intervalInMilliseconds === 0) {
          if (isDirection) {
            if (index % 6 === 0) {
              dataFiltered.push([item[0], item[1], item[2]]);
            } else {
              dataFiltered.push([item[0], null, null]);
            }
          } else {
            dataFiltered.push([item[0], item[1], item[2]]);
          }
        }
      });

      serie.data = dataFiltered;

      if (dataFiltered?.length > 2) {
        const dateProx = DateTime.fromMillis(dataFiltered[1][0]);
        const date = DateTime.fromMillis(dataFiltered[0][0]);
        realInterval = dateProx.diff(date, "minutes").minutes;
      }
    });
    this.setRealInterval(realInterval);

    return series;
  }

  async getChartMeteogramDataByPeriod({
    environmental_type,
    periodInHours,
    create_at,
  }: IGetChartMeteogramDataByPeriodParams) {
    this.setState(StoreState.PEDDING);
    this.resetChartMeteogramDataFiltered();
    this.setPeriodSelected(periodInHours);

    const interval = 1 * 60 * 1000; // 1min in milliseconds
    // For copy value without reference obs mobx
    const series: IAtmOceanMeteogramItem[] = JSON.parse(
      JSON.stringify(this.chartMeteogramData.series)
    );

    if (
      this.insightMeteogram &&
      (this.insightMeteogram.start !== 0 || this.insightMeteogram.end !== 0) &&
      this.showInsightOnMeteogram &&
      this.selectForecastDateAvailable === undefined
    ) {
      const { start, end } = this.insightMeteogram;
      this.filterMeteogramByInsightDate(series, start, end);

      const insightMeteogram = JSON.parse(
        JSON.stringify(this.insightMeteogram)
      );

      this.filterInsightMeteogramByPeriod(
        insightMeteogram,
        series[0],
        periodInHours
      );
    }

    if (interval) {
      this.filterChartMeteogramDataSeries({
        series,
        intervalInMilliseconds: interval,
        periodInHours: periodInHours,
        create_at: create_at,
      });
    }
    let chartDataFiltered: IAtmOceanMeteogram = {
      plotOptions: this.chartMeteogramData.plotOptions,
      series,
    };

    chartDataFiltered = await this.convertUnitMeasurementMeteogramSerie(
      chartDataFiltered
    );

    const sortItems = chartDataFiltered.series.sort(
      this.sortByEnvironmentalVariable
    );

    this.processMeteogramSerie(environmental_type, sortItems);
    this.setState(StoreState.DONE);
  }

  sortByEnvironmentalVariable(
    a: IAtmOceanMeteogramItem,
    b: IAtmOceanMeteogramItem
  ): number {
    const indexA = Object.values(EnvironmentalVariables).indexOf(
      a.environmental_variable as EnvironmentalVariables
    );
    const indexB = Object.values(EnvironmentalVariables).indexOf(
      b.environmental_variable as EnvironmentalVariables
    );

    return indexA - indexB;
  }

  async getInsightsByStation(stationId: number, regionId: number) {
    const { operationRuleLocationInsightService } = getEnv<RootEnv>();
    this.setInsightState(StoreState.PEDDING);
    try {
      const response =
        await operationRuleLocationInsightService.getOperationRuleLocationInsightsByStation(
          stationId,
          regionId
        );

      this.setInsights(response);
      this.setInsightState(StoreState.DONE);
    } catch (error) {
      this.setInsightState(StoreState.ERROR);
    }
  }

  async getInsightMeteogram(
    operationRuleLocationItemId: number,
    stationId: number,
    regionId: number
  ) {
    const { oceanWeatherIntelligenceService } = getEnv<RootEnv>();
    this.setState(StoreState.PEDDING);
    try {
      const response =
        await oceanWeatherIntelligenceService.getInsightMeteogram(
          operationRuleLocationItemId,
          stationId,
          regionId
        );

      this.setInsightMeteogram(response);
      this.setCurrentInsightMeteogramData(response.data);
      this.setState(StoreState.DONE);
    } catch (error) {
      this.setState(StoreState.ERROR);
    }
  }

  filterMeteogramByInsightDate(
    series: IAtmOceanMeteogramItem[],
    startDate: number,
    endDate: number
  ) {
    series.map((serie) => {
      const data = serie.data as number[][];
      let dataFiltered = data.filter(
        (item) => item[0] >= startDate && item[0] <= endDate
      );

      serie.data = dataFiltered;
    });
    return series;
  }

  filterInsightMeteogramByPeriod(
    insightMeteogram: IInsightMeteogram,
    chartSerie: IAtmOceanMeteogramItem,
    periodInHours?: number
  ) {
    const data = chartSerie?.data as number[][];
    if (!data?.length || !insightMeteogram?.data?.length) {
      this.setCurrentInsightMeteogramData([]);
      return;
    }
    const startDate = data[0][0];
    const endDate = data[data.length - 1][0];

    let meteogramData = insightMeteogram.data;

    meteogramData = meteogramData.filter((item) => {
      if (item[0]) {
        return item[0] >= startDate && item[0] <= endDate;
      }
      return false;
    });

    if (!periodInHours) {
      this.setCurrentInsightMeteogramData(meteogramData || []);
      return;
    }
    const dateNow = DateTime.utc();

    const dataFiltered = meteogramData.filter((item) => {
      if (item[0]) {
        return item[0] <= dateNow.plus({ hours: periodInHours }).toMillis();
      }
      return false;
    });

    this.setCurrentInsightMeteogramData(dataFiltered);
  }

  async handleShowInsightOnMeteogram(showInsight: boolean) {
    const {
      dataStores: { forecastChartPageStore },
    } = getRoot<RootStore>();
    if (!showInsight) {
      this.setShowInsightOnMeteogram(false);
      this.getChartMeteogramDataByPeriod({
        environmental_type: forecastChartPageStore.tabStateActivty,
        periodInHours: this.periodSelected,
        create_at: this.selectForecastDateAvailable,
      });
      return;
    }

    this.setShowInsightOnMeteogram(true);

    const insight = this.selectedInsight;

    if (!insight) {
      return;
    }

    if (
      !forecastChartPageStore.selectedStation ||
      !forecastChartPageStore.regionId
    ) {
      return;
    }

    await this.getInsightMeteogram(
      insight.id,
      forecastChartPageStore.selectedStation,
      forecastChartPageStore.regionId
    );

    this.getChartMeteogramDataByPeriod({
      environmental_type: forecastChartPageStore.tabStateActivty,
      periodInHours: this.periodSelected,
      create_at: this.selectForecastDateAvailable,
    });
  }

  async getForecastUpdate(
    macroRegionOrigin: string,
    environmental_type: string
  ) {
    const { atmOceanService } = getEnv<RootEnv>();

    const updateTime = await atmOceanService.getForecastUpdate(
      macroRegionOrigin,
      environmental_type
    );

    this.setLastUpdateTime(updateTime?.lastUpdatedAt || "");
    this.setNextUpdateTime(updateTime?.nextUpdatedAt || "");
  }

  addEnvironmentalVariable(
    environmentalVariable: EnvironmentalVariables,
    visualizationType: VisualizationType = VisualizationType.FORECAST
  ) {
    const {
      dataStores: { forecastChartMeteogramStore, analyticsChartStore },
    } = getRoot<RootStore>();

    const handleChartMaxHeight = (chart: CustomChart) => {
      const allSeries = chart?.series ?? [];

      const currentYAxis = chart.axes.filter((axis) => axis.coll === "yAxis");

      const selectedVariables =
        VisualizationType.FORECAST === visualizationType
          ? forecastChartMeteogramStore.selectedEnvironmentalVariables
          : analyticsChartStore.selectedEnvironmentalVariables;

      const activeLegends = allSeries.filter((legend) =>
        selectedVariables.includes(legend.userOptions.environmentalVariable)
      );

      const activeLegendsHeightMapper = new Map();

      activeLegends.forEach((legend) => {
        if (legend.userOptions.yAxis !== undefined) {
          let defaultMax = legend.userOptions.defaultMaxConverted;

          let legendDataMax = legend?.dataMax;

          let dataMax = legendDataMax;

          if (defaultMax && legendDataMax) {
            dataMax = Math.max(legendDataMax, defaultMax / 1.2);
          } else if (!defaultMax) {
            dataMax = legendDataMax;
          } else if (!legendDataMax) {
            dataMax = defaultMax;
          }

          const currentMax =
            activeLegendsHeightMapper.get(legend.userOptions.yAxis) ??
            -Infinity;

          if (dataMax) {
            let newMax = Math.max(currentMax, dataMax);

            activeLegendsHeightMapper.set(legend.userOptions.yAxis, newMax);
          }
        }
      });

      currentYAxis.forEach((curr) => {
        const yAxisIndex = curr.options.index;

        const defaultValue = curr.options?.defaultMax ?? -Infinity;

        const max = activeLegendsHeightMapper.has(yAxisIndex)
          ? activeLegendsHeightMapper.get(yAxisIndex) * 1.2
          : defaultValue;

        chart.yAxis[yAxisIndex].update({
          max,
        });
      });
    };

    return {
      legendItemClick: function (this: CustomHighChartSeries) {
        const chart = this.chart;

        const serie = chart.series.find(
          (x) =>
            x.userOptions.environmentalVariable === environmentalVariable &&
            x.userOptions.visualizationType === visualizationType
        );

        if (!serie) {
          return false;
        }

        const _selectedEnvironmentalVariables =
          VisualizationType.FORECAST === visualizationType
            ? forecastChartMeteogramStore.selectedEnvironmentalVariables
            : analyticsChartStore.selectedEnvironmentalVariables;
        const isVisible = serie.visible;
        const variableIndex = _selectedEnvironmentalVariables.findIndex(
          (env) => env === environmentalVariable
        );

        if (!isVisible) {
          _selectedEnvironmentalVariables.push(environmentalVariable);
          serie.show();
        } else {
          _selectedEnvironmentalVariables.splice(variableIndex, 1);
          serie.hide();
        }
        handleChartMaxHeight(chart);

        return false;
      },
    };
  }

  async addLogUserExperienceAPI(
    events: ProductAnalyticsEvents,
    details?: string
  ) {
    const { logServices } = getEnv<RootEnv>();
    logServices.addLogUserExperience(events, details);
  }

  handleChangeToForecastTab() {
    const {
      dataStores: {
        forecastChartPageStore,
        forecastChartProbabilisticStore,
        analyticsChartStore,
      },
    } = getRoot<RootStore>();
    this.setVisualizationType(VisualizationType.FORECAST);

    forecastChartPageStore.handleOnChangeTabMeteogram(
      forecastChartPageStore.tabStateActivty
    );
    forecastChartPageStore.setProbabilisticActivityVariable(null);
    forecastChartPageStore.setSensorMarkerData([]);
    forecastChartProbabilisticStore.setIsChartDataEmpty(false);
    analyticsChartStore.setIsChartDataEmpty(false);
  }
}
