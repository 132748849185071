import { ProductAnalyticsEvents } from "../enum/ProductAnalyticsEvents";
import posthog from "posthog-js";

export default class LogService {
  async addLogUserExperience(events: ProductAnalyticsEvents, details?: string) {
    try {
      let properties;
      if (details) {
        properties = JSON.parse(details);
      }

      posthog.capture(events, properties);
    } catch (error) {
      console.log(error);
    }
  }

  async identifyUser(userId: string, userData?: any) {
    try {
      posthog.identify(userId, userData);
    } catch (error) {
      console.log(error);
    }
  }
}
