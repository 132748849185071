import React, { ReactElement } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Text,
} from "@chakra-ui/react";

import ganntServiceImgEn from "../../../assets/gantt-service-en.png";
import ganntServiceImgPtbr from "../../../assets/gantt-service-ptbr.png";

import { useTranslation } from "react-i18next";

interface ServiceGuideModalProps {
  isOpen: boolean;
  onClose: () => void;
  handleOpenAddModal: () => void;
}

export const ServiceGuideModal: React.FC<ServiceGuideModalProps> = ({
  isOpen,
  onClose,
  handleOpenAddModal,
}): ReactElement => {
  const { t, i18n } = useTranslation("translation", {
    keyPrefix: "operationServices",
  });

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      size="3xl"
    >
      <ModalOverlay />
      <ModalContent bg={"#f0f1f2"}>
        <ModalHeader color="newBlue.500">
          {t("whatHappensWhenRegistering")}
        </ModalHeader>
        <ModalCloseButton color={"black"} />

        <ModalBody
          pb={6}
          px={5}
          maxH="75vh"
          overflowY="auto"
          sx={{
            "&::-webkit-scrollbar": {
              width: "12px",
              borderRadius: "8px",
              backgroundColor: `rgba(40, 103, 149, 0.3)`,
            },
            "&::-webkit-scrollbar-thumb": {
              borderRadius: "8px",
              backgroundColor: `rgba(40, 103, 149, 0.6)`,
            },
          }}
        >
          <Text color="newBlue.500" fontSize={"sm"} mb={4}>
            {t("yourServiceAndShiftsWill")}
          </Text>
          <img
            src={
              i18n.language === "en" ? ganntServiceImgEn : ganntServiceImgPtbr
            }
            alt=""
          />
        </ModalBody>
        <ModalFooter>
          <Button
            layerStyle="ocean"
            _hover={{ layerStyle: "ocean" }}
            onClick={() => handleOpenAddModal()}
            type="submit"
          >
            {t("createService")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
