import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  useMemo,
} from "react";
import { message } from "antd";
import { DateTime } from "luxon";
import GanttChart from "../../components/GanttChart";
import { useHistory } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStores } from "../../stores/setup/use-store";
import { StoreState } from "../../enum/StoreState";
import DatePicker from "react-datepicker";
import {
  AlertIcon,
  Box,
  Button,
  Center,
  Grid,
  GridItem,
  Select,
  Spinner,
  Stack,
  Text,
  Alert,
  Switch,
  Flex,
  Tooltip,
  Icon,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import pt from "date-fns/locale/pt-BR";
import en from "date-fns/locale/en-GB";
import { PageTitle } from "../../components/PageTitle/PageTitle";
import TodayIcon from "@material-ui/icons/Today";
import SettingsIcon from "@material-ui/icons/Settings";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import axios from "axios";
import DashboardIcon from "@material-ui/icons/Dashboard";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import noDashboards from "../../assets/noDashboards.svg";
import dashboardHasNoLocation from "../../assets/dashboardHasNoLocation.svg";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import EmptyState from "../../components/EmptyState/EmptyState";
import AddLocationIcon from "@material-ui/icons/AddLocation";
import useWindowObserver from "../../hooks/useWindowObserver";
import { SCREEN_HORIZONTAL_SPACING } from "../../constants";
import Highcharts from "highcharts";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { OperationServicesModal } from "pages/OperationServices/Components/OperationServicesModal";
import { ServiceGuideModal } from "pages/OperationServices/Components/ServiceGuideModal";
import AddIcon from "@material-ui/icons/Add";
import useFormOperationService from "pages/OperationServices/hooks/useFormOperationService";
import { Select as SelectAntd, Button as ButtonAntd, Space } from "antd";
import { EditOutlined } from "@ant-design/icons";

const OceanWeatherIntelligence: React.FC = () => {
  const {
    dataStores: {
      operationRuleStore,
      oceanWeatherIntelligenceStore,
      operationRuleLocationInsightStore,
      operationRuleLocationStore,
      authStore,
      operationServicesStore,
      currencyStore,
    },
  } = useStores();
  const {
    isOpen,
    onClose,
    isTutorialOpen,
    onTutorialClose,
    initialValues,
    handleOpenAddModal,
    handleOpenEditModal,
    onSubmitForm,
    isEditing,
    recurrenceOptions,
  } = useFormOperationService();

  let history = useHistory();

  const [clickedDate, setClickedDate] = useState(false);
  const [startDate, setStartDate] = useState(
    oceanWeatherIntelligenceStore.processDateCurrent(DateTime.local())
  );
  const [open, setOpen] = useState(false);

  const windowSize = useWindowObserver(".main-area");

  const { t, i18n } = useTranslation("translation", {
    keyPrefix: "oceanWeatherIntelligence.oceanWeatherIntelligence",
  });

  let today = DateTime.local()
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      .setLocale("pt-BR")
      .setZone("America/Bahia")
      .toMillis(),
    day = 1000 * 60 * 60 * 24;

  useEffect(() => {
    if (operationRuleStore.operationRules.length === 0) {
      operationRuleStore.getOperationRulesApi();
      operationServicesStore.getServices();
      currencyStore.getCurrency();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const serviceId = operationServicesStore.selectedServiceId;
    if (serviceId) {
      const initialDate = startDate ? startDate.toISOString() : undefined;

      operationServicesStore.getOperationServiceEvents({
        id: serviceId,
        startDate: initialDate,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    operationServicesStore.selectedServiceId,
    startDate,
    operationServicesStore.serviceList,
  ]);

  useEffect(() => {
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();

    const operationRuleId = operationRuleStore.operationRuleMainId;

    if (operationRuleId <= 0) {
      return;
    }

    if (!oceanWeatherIntelligenceStore.needUpdateGanttChart()) {
      return;
    }

    if (startDate && clickedDate) {
      oceanWeatherIntelligenceStore.setSelectForecastDateAvailable(
        DateTime.fromISO(startDate.toISOString())
      );
    }

    oceanWeatherIntelligenceStore.getGanttChartDataApi({
      operationRuleId: operationRuleId,
      cancelToken: source.token,
      create_at: startDate ? startDate.toISOString() : undefined,
    });

    oceanWeatherIntelligenceStore.setChangeForUpdate({
      update: false,
      expiration: DateTime.local().plus({ minutes: 10 }).toMillis(),
    });

    return () => {
      source.cancel("Request cancelled");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    operationRuleStore.operationRuleMainId,
    oceanWeatherIntelligenceStore.actionCallGanttChart,
  ]);

  useEffect(() => {
    const style = document.createElement("style");
    style.innerHTML = `
      .custom-select .ant-select-selector {
        border: 1px solid!important;
        border-color: inherit !important;
        box-shadow: none !important; 
      }

      .custom-select .ant-select-selection-item {
        color: #187047 !important;
        font-size: 1rem;
        border-radius: 8px;
      }
    `;
    document.head.appendChild(style);

    return () => {
      document.head.removeChild(style);
    };
  }, []);

  const chartRef = useRef<any>(null);

  const handleSelectDashboardChange = useCallback(
    (dashboardId: number) => {
      oceanWeatherIntelligenceStore.setChangeForUpdate({
        update: true,
        expiration: 0,
      });
      operationRuleLocationInsightStore.setOperationRuleLocationItens([]);
      operationRuleLocationStore.setOperationRuleLocations([]);
      operationRuleStore.setOperationRuleMain(dashboardId);
      operationRuleLocationStore.setHasLocations(true);
      operationRuleLocationInsightStore.setHasInsights(true);
    },
    [
      oceanWeatherIntelligenceStore,
      operationRuleLocationInsightStore,
      operationRuleLocationStore,
      operationRuleStore,
    ]
  );

  const handleGoBackCurrentInsight = useCallback(() => {
    oceanWeatherIntelligenceStore.clearSelectForecastDateAvailable();
    oceanWeatherIntelligenceStore.setActionCallGanttChart();
    setClickedDate(false);
    setStartDate(
      oceanWeatherIntelligenceStore.processDateCurrent(DateTime.local())
    );
    oceanWeatherIntelligenceStore.setChangeForUpdate({
      update: true,
      expiration: 0,
    });
  }, [oceanWeatherIntelligenceStore]);

  //@ts-ignore
  const CustomDatePickerButton = React.forwardRef(({ value, onClick }, ref) => (
    <Button
      //@ts-ignore
      ref={ref}
      className="CustomDatePickerButton"
      onClick={onClick}
      size="small"
      p={1}
      pr={2}
      layerStyle="lightOcean"
      _hover={{ layerStyle: "lightOcean" }}
      leftIcon={<TodayIcon style={{ marginLeft: "6px" }} />}
    >
      {clickedDate ? value : t("clickGoBackPastInsights")}
    </Button>
  ));

  const handleSelectForecastDateAvailable = useCallback(
    (date: Date) => {
      setStartDate(date);
      setClickedDate(true);
      const selectedDashboard = operationRuleStore.operationRuleMainId;

      if (!selectedDashboard || selectedDashboard <= 0) {
        message.info(t("needSelectDashboardPastInsight"));
      } else {
        oceanWeatherIntelligenceStore.setActionCallGanttChart();
        oceanWeatherIntelligenceStore.setChangeForUpdate({
          update: true,
          expiration: 0,
        });
      }
    },
    [oceanWeatherIntelligenceStore, operationRuleStore.operationRuleMainId, t]
  );

  const handleChangeTab = useCallback(
    (id: number) => {
      oceanWeatherIntelligenceStore.setActiveTab(id);
      history.push("/insight-manager");
    },
    [history, oceanWeatherIntelligenceStore]
  );

  const ganttOptions = useMemo(
    () => ({
      chart: {
        backgroundColor: "rgb(240, 241, 242)",
        events: {
          load() {
            const chart: any = this;
            chartRef.current = chart;

            chart.plotBandsTooltip = [];

            setTimeout(() => {
              if (!chart.xAxis || !chart.xAxis[0]) return;

              chart.xAxis[0].plotLinesAndBands?.forEach(
                (plotBand: any, i: number) => {
                  if (plotBand.options.tooltipText && plotBand.svgElem) {
                    Highcharts.addEvent(
                      plotBand.svgElem.element,
                      "mousemove",
                      (e: any) => {
                        const tooltipText = plotBand.options.tooltipText;
                        const x =
                          e.pageX -
                          chart.container.getBoundingClientRect().left +
                          window.scrollX +
                          10;
                        const y =
                          e.pageY -
                          chart.container.getBoundingClientRect().top +
                          window.scrollY +
                          10;

                        if (!chart.plotBandsTooltip[i]) {
                          chart.plotBandsTooltip[i] = chart.renderer
                            .label(tooltipText, x, y, "callout")
                            .attr({
                              fill: "rgba(0, 0, 0, 0.75)",
                              zIndex: 10,
                              padding: 5,
                              r: 5,
                            })
                            .css({
                              color: "#ffffff",
                              transition: "opacity 0.5s",
                            })
                            .add();
                        } else {
                          chart.plotBandsTooltip[i]
                            .attr({ x, y })
                            .css({ opacity: 1 });
                        }
                      }
                    );

                    Highcharts.addEvent(
                      plotBand.svgElem.element,
                      "mouseout",
                      () => {
                        if (chart.plotBandsTooltip[i]) {
                          chart.plotBandsTooltip[i].css({ opacity: 0 });
                        }
                      }
                    );
                  }
                }
              );
            }, 500);
          },
        },
      },
      time: {
        timezoneOffset: oceanWeatherIntelligenceStore.timezoneOffset,
      },
      title: { text: "" },
      xAxis: {
        min: oceanWeatherIntelligenceStore.ganttChartData[0]?.start || today,
        max:
          oceanWeatherIntelligenceStore.ganttChartData[0]?.end ||
          today + 5 * day,
        currentDateIndicator: true,
        tickInterval: 3600 * 1000 * 6,
        plotBands:
          operationServicesStore.showShiftOnGantt &&
          operationServicesStore.selectedServiceId &&
          operationServicesStore.events?.map((data) => ({
            from: DateTime.fromISO(data.start).toMillis(),
            to: DateTime.fromISO(data.end).toMillis(),
            color: "rgba(24, 112, 71, 0.2)",
            borderWidth: 1,
            borderColor: "#fff",
            tooltipText: `📅 ${t("serviceShift")}:<br><b>${DateTime.fromISO(
              data.start
            )
              .toUTC()
              .minus({ minutes: oceanWeatherIntelligenceStore.timezoneOffset })
              .toFormat("dd/MM/yyyy HH:mm")}</b> ${t(
              "until"
            )} <b>${DateTime.fromISO(data.end)
              .toUTC()
              .minus({ minutes: oceanWeatherIntelligenceStore.timezoneOffset })
              .toFormat("dd/MM/yyyy HH:mm")}</b>`,
          })),
      },
      tooltip: {
        useHTML: true,
        backgroundColor: "#ffff",
        borderColor: "#1890ff",
        borderRadius: 8,
        borderWidth: 1,
        formatter: function () {
          //@ts-ignore
          if (this.point?.isNull) {
            return "Null";
          }
          return oceanWeatherIntelligenceStore.ganttChartFormatTooltip(this);
        },
      },
      yAxis: { uniqueNames: true },
      series: oceanWeatherIntelligenceStore.ganttChartData,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      oceanWeatherIntelligenceStore.ganttChartData,
      oceanWeatherIntelligenceStore.state,
      operationRuleStore.operationRules,
      operationServicesStore.showShiftOnGantt,
      operationServicesStore.selectedServiceId,
      operationServicesStore.events,
    ]
  );

  const isInsightEmpty =
    oceanWeatherIntelligenceStore.ganttChartData?.length > 0 &&
    oceanWeatherIntelligenceStore.ganttChartData[0].data?.length > 0 &&
    oceanWeatherIntelligenceStore.ganttChartData[0].end === 0 &&
    oceanWeatherIntelligenceStore.state === StoreState.DONE;

  return (
    <Box mt={8} mb={8} mx={SCREEN_HORIZONTAL_SPACING}>
      <ServiceGuideModal
        handleOpenAddModal={handleOpenAddModal}
        isOpen={isTutorialOpen}
        onClose={onTutorialClose}
      />
      <OperationServicesModal
        initialValues={initialValues}
        isOpen={isOpen}
        onClose={onClose}
        recurrenceOptions={recurrenceOptions}
        onSubmitForm={onSubmitForm}
        validateForm={operationServicesStore.validateForm}
        isLoading={operationServicesStore.state === StoreState.PEDDING}
        isEditing={isEditing}
        formattedTimezone={operationServicesStore.getFormattedTimezoneOffset()}
        currencies={currencyStore.currencies}
      />

      <Stack direction="row" spacing={4}>
        <PageTitle text={t("operationInsights")} />
        <Button
          leftIcon={<DashboardIcon />}
          layerStyle="lightOcean"
          _hover={{ layerStyle: "lightOcean" }}
          size="md"
          mt={"3px"}
          onClick={() => handleChangeTab(0)}
        >
          {t("dashboards")}
        </Button>
        {operationRuleStore.operationRules?.length > 0 && (
          <>
            <Button
              leftIcon={<LocationOnIcon />}
              layerStyle="lightOcean"
              _hover={{ layerStyle: "lightOcean" }}
              size="md"
              mt={"3px"}
              onClick={() => handleChangeTab(1)}
            >
              {t("locations")}
            </Button>
            <Button
              leftIcon={<SettingsIcon />}
              layerStyle="lightOcean"
              _hover={{ layerStyle: "lightOcean" }}
              size="md"
              mt={"3px"}
              onClick={() => handleChangeTab(2)}
            >
              {t("insights")}
            </Button>
          </>
        )}
      </Stack>

      {operationRuleStore.state === StoreState.ERROR && (
        <Alert status="error" mb={4} color="black">
          <AlertIcon />
          {oceanWeatherIntelligenceStore.messageError}
        </Alert>
      )}

      {!operationRuleStore.operationRules?.length &&
        !operationRuleStore.hasDashboards && (
          <EmptyState
            description={t("weStillDontHaveAnyDashboard")}
            image={noDashboards}
            imageSize="25vw"
            buttonAction={() => handleChangeTab(0)}
            buttonIcon={<PlayArrowIcon />}
            buttonText={
              !authStore.readOnlyUser ? (t("letsGo") as string) : undefined
            }
            marginTop="10vh"
          />
        )}

      {operationRuleStore.operationRules?.length > 0 && (
        <>
          <Grid templateColumns="repeat(4, 1fr)" gap={2}>
            <GridItem colStart={1}>
              <Text color="blue.200" p="1" whiteSpace="nowrap">
                {t("selectDashboardInsight")}
              </Text>
              <Select
                borderRadius={8}
                color={"blue.200"}
                backgroundColor="#ffffff"
                size="md"
                key={`selectDashboard-${operationRuleStore.operationRuleMainId}`}
                onChange={(e) =>
                  handleSelectDashboardChange(parseInt(e.target.value))
                }
                value={
                  operationRuleStore.operationRuleMainId > 0
                    ? operationRuleStore.operationRuleMainId
                    : undefined
                }
                _hover={{ borderColor: "blue.100" }}
                _focus={{ borderColor: "none" }}
              >
                {operationRuleStore.operationRules?.map((value) => (
                  <option
                    style={{ color: "black" }}
                    value={value.id}
                    key={value.id}
                  >
                    {value.name}
                  </option>
                ))}
              </Select>
            </GridItem>
            {operationServicesStore.serviceList?.length ? (
              <>
                <GridItem>
                  <Flex>
                    <Text color="#187047" p="1">
                      {t("selectService")}
                    </Text>
                    <Tooltip
                      label={t("selectAserviceToView")}
                      fontSize="sm"
                      hasArrow
                    >
                      <Icon color={"gray.500"} as={InfoOutlinedIcon} pt={1} />
                    </Tooltip>
                  </Flex>
                  <SelectAntd
                    className="custom-select"
                    style={{
                      width: "100%",
                      height: "40px",
                      borderRadius: "8px",
                      fontFamily: "Akko Pro",
                    }}
                    value={operationServicesStore.selectedServiceId}
                    onChange={(value) => {
                      operationServicesStore.setSelectedServiceId(value);
                      setOpen(false);
                    }}
                    open={open}
                    onDropdownVisibleChange={(visible) => setOpen(visible)}
                    allowClear
                    dropdownRender={() => (
                      <div>
                        {operationServicesStore.serviceList?.map(
                          (service, index, array) => (
                            <div
                              key={service.id}
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                padding: "8px 12px",
                                cursor: "pointer",
                                borderBottom:
                                  index === array.length - 1
                                    ? "none"
                                    : "1px solid #f0f0f0",
                              }}
                              onMouseEnter={(e) =>
                                (e.currentTarget.style.backgroundColor =
                                  "#f5f5f5")
                              }
                              onMouseLeave={(e) =>
                                (e.currentTarget.style.backgroundColor =
                                  "white")
                              }
                              onClick={() => {
                                if (service.id) {
                                  operationServicesStore.setSelectedServiceId(
                                    service.id
                                  );
                                  setOpen(false);
                                }
                              }}
                            >
                              <span>{service.name}</span>

                              <ButtonAntd
                                type="text"
                                icon={<EditOutlined />}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  if (service.id) {
                                    handleOpenEditModal(service.id);
                                  }
                                }}
                              />
                            </div>
                          )
                        )}
                      </div>
                    )}
                  >
                    {operationServicesStore.serviceList?.map((service) => (
                      <SelectAntd.Option key={service.id} value={service.id}>
                        <Space
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          {service.name}
                        </Space>
                      </SelectAntd.Option>
                    ))}
                  </SelectAntd>
                </GridItem>
                <GridItem colStart={3} display="flex" alignItems="center">
                  <Tooltip
                    label="Adicionar serviço"
                    fontSize="sm"
                    hasArrow
                    placement="top"
                  >
                    <Center
                      alignItems="center"
                      bg="rgba(24, 112, 71, 0.08)"
                      color="#187047"
                      w="40px"
                      h="37px"
                      borderRadius={4}
                      mt="30px"
                      mr="5px"
                      whiteSpace="nowrap"
                      _hover={{
                        backgroundColor: "rgba(24, 112, 71, 0.2)",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        handleOpenAddModal();
                      }}
                    >
                      <AddIcon />
                    </Center>
                  </Tooltip>

                  <Tooltip
                    label={t("showServiceChart")}
                    fontSize="sm"
                    hasArrow
                    placement="top-end"
                  >
                    <Flex alignItems="center" marginTop="29px" gap={"5px"}>
                      <Text color="#187047" p="1" whiteSpace="nowrap">
                        {t("showShifts")}
                      </Text>

                      <Switch
                        p={0}
                        colorScheme="whatsapp"
                        size="sm"
                        id="show-service"
                        isDisabled={
                          operationServicesStore.selectedServiceId === null ||
                          operationServicesStore.selectedServiceId === undefined
                        }
                        isChecked={operationServicesStore.showShiftOnGantt}
                        onChange={() => {
                          operationServicesStore.setShowShiftOnGantt(
                            !operationServicesStore.showShiftOnGantt
                          );
                        }}
                      />
                    </Flex>
                  </Tooltip>
                </GridItem>
              </>
            ) : (
              <GridItem>
                <Tooltip
                  label={t("createServiceAndVisualize")}
                  fontSize="sm"
                  hasArrow
                  placement="top"
                >
                  <Flex
                    alignItems="center"
                    bg="rgba(24, 112, 71, 0.08)"
                    color="#187047"
                    w="190px"
                    h="35px"
                    borderRadius={4}
                    mt="31px"
                    whiteSpace="nowrap"
                    p={4}
                    gap={2}
                    _hover={{
                      backgroundColor: "rgba(24, 112, 71, 0.2)",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      handleOpenAddModal();
                    }}
                  >
                    <span>{t("createService")}</span>
                    <Center
                      bg="white"
                      fontSize="0.8em"
                      fontWeight={"light"}
                      w={"40px"}
                      h={"18px"}
                      borderRadius={4}
                      isTruncated
                    >
                      <span style={{ marginTop: "3px" }}>
                        {t("newService")}
                      </span>
                    </Center>
                  </Flex>
                </Tooltip>
              </GridItem>
            )}

            {clickedDate && (
              <GridItem
                colStart={5}
                colEnd={6}
                p={2}
                display="flex"
                alignItems="end"
              >
                <Button
                  size="small"
                  p={1}
                  pr={2}
                  mt={2}
                  layerStyle="lightOcean"
                  _hover={{ layerStyle: "lightOcean" }}
                  leftIcon={<ArrowBackIcon />}
                  onClick={handleGoBackCurrentInsight}
                >
                  {t("currentInsight")}
                </Button>
              </GridItem>
            )}

            <GridItem colStart={6} p={2} display="flex" alignItems="end">
              <DatePicker
                selected={startDate}
                onChange={handleSelectForecastDateAvailable}
                maxDate={DateTime.local().toJSDate()}
                timeIntervals={15}
                popperPlacement={"left-start"}
                customInput={<CustomDatePickerButton />}
                dateFormat="MMMM d, yyyy H:mm"
                timeInputLabel={t("time") as string}
                showTimeInput
                locale={i18n.language === "en" ? en : pt}
              />
            </GridItem>
          </Grid>

          {isInsightEmpty && !authStore.readOnlyUser && (
            <Alert color="gray.600" status="warning" mt={2}>
              <AlertIcon />
              <Text mr={1}>{t("almostThereCreateInsightVisualizeChart")}</Text>
              <Text
                cursor="pointer"
                color="#dd6b20"
                _hover={{ color: "rgba(221,107,32,0.7)" }}
                onClick={() => handleChangeTab(2)}
              >
                {t("clickHere")}
              </Text>
            </Alert>
          )}

          {oceanWeatherIntelligenceStore.state === StoreState.PEDDING && (
            <Center h={"50vh"}>
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="blue.500"
                size="xl"
              />
            </Center>
          )}

          {oceanWeatherIntelligenceStore.ganttChartData?.length > 0 &&
            !oceanWeatherIntelligenceStore.ganttChartData[0].data.length &&
            oceanWeatherIntelligenceStore.state === StoreState.DONE && (
              <EmptyState
                key={`dash-${operationRuleStore.operationRuleMainId}`}
                description={t("addLocationInsightToYourDashboard")}
                image={dashboardHasNoLocation}
                imageSize="20vw"
                buttonAction={() => handleChangeTab(1)}
                buttonIcon={<AddLocationIcon />}
                buttonText={
                  !authStore.readOnlyUser
                    ? (t("addLocation") as string)
                    : undefined
                }
                marginTop="10vh"
              />
            )}

          {oceanWeatherIntelligenceStore.ganttChartData?.length > 0 &&
            oceanWeatherIntelligenceStore.ganttChartData[0].data.length &&
            oceanWeatherIntelligenceStore.state === StoreState.DONE &&
            operationRuleStore.operationRules?.length > 0 && (
              <GanttChart
                key={`${operationServicesStore.events}`}
                style={{ maxWidth: windowSize.width }}
                timezoneOffset={oceanWeatherIntelligenceStore.timezoneOffset}
                options={ganttOptions}
              />
            )}
        </>
      )}
    </Box>
  );
};

export default observer(OceanWeatherIntelligence);
