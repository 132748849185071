import React, { ReactElement } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  FormControl,
  FormLabel,
  Flex,
  NumberInput,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  NumberInputField,
  Text,
  Grid,
  GridItem,
  Select,
  FormErrorMessage,
  Divider,
  Textarea,
  IconButton,
  Center,
  Badge,
} from "@chakra-ui/react";
import { FieldArray, Formik, FormikErrors } from "formik";
import { Input } from "../../../components/Input/Input";
import DatePicker from "react-datepicker";
import TodayIcon from "@material-ui/icons/Today";
import { useTranslation } from "react-i18next";
import pt from "date-fns/locale/pt-BR";
import en from "date-fns/locale/en-GB";
import AddIcon from "@material-ui/icons/Add";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import {
  IScheduleRules,
  RecurrenceType,
  ServiceFormValues,
  WeekDay,
} from "types/IOperationServices";
import Loading from "components/Loading/Loading";
import { ICurrency } from "types/ICurrency";
import { formatMoney } from "util/formatMoney/formatMoney";

interface OperationServicesModalProps {
  isOpen: boolean;
  onClose: () => void;
  initialRef?: React.RefObject<HTMLInputElement>;
  initialValues: ServiceFormValues;
  recurrenceOptions: {
    value: RecurrenceType;
    label: string;
  }[];
  onSubmitForm: (values: ServiceFormValues) => void;
  validateForm: (values: ServiceFormValues) => FormikErrors<ServiceFormValues>;
  isLoading: boolean;
  isEditing: boolean;
  formattedTimezone: string;
  currencies: ICurrency[];
}

export const OperationServicesModal: React.FC<OperationServicesModalProps> = ({
  isOpen,
  onClose,
  initialRef,
  initialValues,
  recurrenceOptions,
  onSubmitForm,
  isLoading,
  validateForm,
  isEditing,
  formattedTimezone,
  currencies,
}): ReactElement => {
  const { t, i18n } = useTranslation("translation", {
    keyPrefix: "operationServices",
  });

  //@ts-ignore
  const CustomDatePickerButton = React.forwardRef(({ value, onClick }, ref) => (
    <Button
      //@ts-ignore
      ref={ref}
      className="CustomDatePickerButton"
      onClick={onClick}
      size="small"
      p={2}
      w={"175px"}
      backgroundColor="white"
      color={value ? "rgba(30, 82, 151, 0.8)" : "#BCBCBC"}
      rightIcon={<TodayIcon />}
      fontWeight="light"
    >
      {value ? value : t("selectDate")}
    </Button>
  ));

  return (
    <Modal
      closeOnOverlayClick={false}
      initialFocusRef={initialRef}
      isOpen={isOpen}
      onClose={onClose}
      size="3xl"
    >
      <Formik<ServiceFormValues>
        initialValues={initialValues}
        onSubmit={onSubmitForm}
        validate={validateForm}
        enableReinitialize={true}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({ getFieldProps, values, setFieldValue, submitForm, errors }) => (
          <>
            <ModalOverlay />
            <ModalContent bg={"#f0f1f2"}>
              <ModalHeader color="newBlue.500">
                {isEditing ? t("serviceEdit") : t("serviceCreate")}
              </ModalHeader>
              <ModalCloseButton color={"black"} />

              <ModalBody
                pb={6}
                px={5}
                maxH="75vh"
                overflowY="auto"
                sx={{
                  "&::-webkit-scrollbar": {
                    width: "12px",
                    borderRadius: "8px",
                    backgroundColor: `rgba(40, 103, 149, 0.3)`,
                  },
                  "&::-webkit-scrollbar-thumb": {
                    borderRadius: "8px",
                    backgroundColor: `rgba(40, 103, 149, 0.6)`,
                  },
                }}
              >
                {isLoading ? (
                  <Center minH="68vh">
                    <Loading styleSpinner={{ marginLeft: 0 }} />
                  </Center>
                ) : (
                  <>
                    <Grid templateColumns="repeat(5, 1fr)" gap={2}>
                      <GridItem colSpan={3}>
                        <FormControl isRequired isInvalid={!!errors.name}>
                          <FormLabel color="newBlue.500">
                            {t("serviceName")}
                          </FormLabel>
                          <Input
                            {...getFieldProps("name")}
                            autoFocus
                            placeholder={t("shipMaintance") as string}
                            maxLength={50}
                          />
                          <FormErrorMessage>{errors.name}</FormErrorMessage>
                        </FormControl>
                      </GridItem>

                      <GridItem colSpan={1}>
                        <FormControl color="newBlue.500">
                          <FormLabel color="newBlue.500">
                            {t("currency")}
                          </FormLabel>
                          <Select
                            {...getFieldProps("currency")}
                            id="currency"
                            bg="white"
                            mr="5px"
                          >
                            {currencies.map(({ initials, symbol }) => (
                              <option value={initials} key={initials}>
                                {initials} - {symbol}
                              </option>
                            ))}
                          </Select>
                        </FormControl>
                      </GridItem>

                      <GridItem colSpan={1}>
                        <FormControl>
                          <FormLabel color="newBlue.500">
                            {t("serviceCost")}
                          </FormLabel>
                          <Input
                            {...getFieldProps("cost")}
                            placeholder={t("moneyValue") as string}
                            maxLength={10}
                            value={values.cost}
                            onChange={(e) => {
                              const cost = formatMoney(
                                e.target.value,
                                i18n.language
                              );
                              setFieldValue("cost", cost);
                            }}
                          />
                        </FormControl>
                      </GridItem>

                      <GridItem colSpan={5}>
                        <FormControl>
                          <FormLabel color="newBlue.500">
                            {t("description")}
                          </FormLabel>
                          <Textarea
                            {...getFieldProps("description")}
                            placeholder={t("routineMaintance") as string}
                            maxLength={300}
                            style={{
                              backgroundColor: "white",
                              borderColor: "white",
                              color: "rgba(30, 82, 151, 0.8)",
                              fontSize: 14,
                              fontWeight: "normal",
                            }}
                            borderRadius={8}
                            size="sm"
                            _focus={{
                              border: "2px",
                              borderColor: "newBlue.500",
                              boxShadow: "0px 4px 10px rgba(40, 103, 149, 0.1)",
                            }}
                            borderColor="gray.300"
                          />
                        </FormControl>
                      </GridItem>

                      <GridItem colSpan={2}>
                        <FormControl color="newBlue.500">
                          <FormLabel color="newBlue.500">
                            {t("recurrence")}
                          </FormLabel>
                          <Select
                            {...getFieldProps("recurrenceType")}
                            id="recurrence"
                            bg="white"
                            mr="5px"
                          >
                            {recurrenceOptions.map(({ value, label }) => (
                              <option value={value} key={value}>
                                {label}
                              </option>
                            ))}
                          </Select>
                        </FormControl>
                      </GridItem>
                      {values.recurrenceType !== RecurrenceType.CUSTOM && (
                        <GridItem colSpan={1}>
                          <Badge
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            colorScheme="telegram"
                            mt="34px"
                            w="62px"
                            h="37px"
                            fontSize="14px"
                          >
                            ({formattedTimezone})
                          </Badge>
                        </GridItem>
                      )}

                      <GridItem colSpan={3} alignSelf={"center"}>
                        {values.recurrenceType === RecurrenceType.CUSTOM && (
                          <FormControl>
                            <Flex alignItems="center" mt="30px">
                              <Text color="newBlue.500" fontSize={"md"} mr={2}>
                                {t("repeatEvery")}
                              </Text>
                              <NumberInput
                                value={values.interval}
                                onChange={(e) => {
                                  const intValue = Math.floor(Number(e)) || 1;
                                  setFieldValue("interval", intValue);
                                }}
                                defaultValue={6}
                                min={1}
                                max={240}
                                color="black"
                                size="sm"
                                maxW={20}
                              >
                                <NumberInputField width="80px" bg="#fff" />
                                <NumberInputStepper>
                                  <NumberIncrementStepper />
                                  <NumberDecrementStepper />
                                </NumberInputStepper>
                              </NumberInput>
                              <Text
                                color="newBlue.500"
                                fontSize={"large"}
                                ml={2}
                              >
                                {t("weeks")}
                              </Text>
                              <GridItem colSpan={1}>
                                <Badge
                                  display="flex"
                                  justifyContent="center"
                                  alignItems="center"
                                  colorScheme="telegram"
                                  w="62px"
                                  h="37px"
                                  fontSize="14px"
                                  ml={2}
                                >
                                  ({formattedTimezone})
                                </Badge>
                              </GridItem>
                            </Flex>
                          </FormControl>
                        )}
                      </GridItem>

                      <GridItem colSpan={5} px={2} pt={2}>
                        <Divider borderColor={"gray.300"} />
                      </GridItem>
                    </Grid>
                    <Grid templateColumns="repeat(4, 1fr)" gap={2}>
                      <FieldArray name="scheduleRules">
                        {({ remove }) => (
                          <>
                            {values?.scheduleRules?.map((rule, ruleIndex) => (
                              <>
                                <GridItem colSpan={5} mt={2}>
                                  {(values.recurrenceType ===
                                    RecurrenceType.WEEKLY ||
                                    values.recurrenceType ===
                                      RecurrenceType.CUSTOM) && (
                                    <>
                                      <FormLabel color="newBlue.500">
                                        {t("repeat")}:
                                      </FormLabel>
                                      <FormControl
                                        display="flex"
                                        gap={2}
                                        isInvalid={
                                          !!(
                                            errors.scheduleRules?.[
                                              ruleIndex
                                            ] as FormikErrors<IScheduleRules>
                                          )?.byWeekDay
                                        }
                                      >
                                        {Object.values(WeekDay).map(
                                          (day, index) => {
                                            const isSelected =
                                              values.scheduleRules[
                                                ruleIndex
                                              ].byWeekDay?.includes(day);

                                            return (
                                              <Button
                                                key={index}
                                                size="xs"
                                                onClick={() => {
                                                  const updatedDays = isSelected
                                                    ? values?.scheduleRules[
                                                        ruleIndex
                                                      ]?.byWeekDay?.filter(
                                                        (d) => d !== day
                                                      )
                                                    : [
                                                        ...(values
                                                          .scheduleRules[
                                                          ruleIndex
                                                        ].byWeekDay || []),
                                                        day,
                                                      ];

                                                  setFieldValue(
                                                    `scheduleRules[${ruleIndex}].byWeekDay`,
                                                    updatedDays
                                                  );
                                                }}
                                                layerStyle="grayOcean"
                                                _hover={{
                                                  layerStyle: "grayOcean",
                                                }}
                                                _active={{
                                                  layerStyle: "grayOcean",
                                                }}
                                                isActive={isSelected}
                                              >
                                                {t(day)}
                                              </Button>
                                            );
                                          }
                                        )}
                                        <FormErrorMessage>
                                          {
                                            (
                                              errors.scheduleRules?.[
                                                ruleIndex
                                              ] as FormikErrors<IScheduleRules>
                                            )?.byWeekDay
                                          }
                                        </FormErrorMessage>
                                      </FormControl>
                                    </>
                                  )}
                                </GridItem>

                                {values.recurrenceType ===
                                  RecurrenceType.ONE_TIME && (
                                  <GridItem colSpan={1} alignSelf="baseline">
                                    <FormControl
                                      w={"auto"}
                                      isInvalid={
                                        !!(
                                          errors.scheduleRules?.[
                                            ruleIndex
                                          ] as FormikErrors<IScheduleRules>
                                        )?.startDate
                                      }
                                    >
                                      <DatePicker
                                        selected={rule.startDate}
                                        onChange={(date) => {
                                          setFieldValue(
                                            `scheduleRules[${ruleIndex}].startDate`,
                                            date
                                          );
                                        }}
                                        popperPlacement={"top-start"}
                                        timeIntervals={15}
                                        customInput={<CustomDatePickerButton />}
                                        dateFormat="MMMM d, yyyy"
                                        locale={
                                          i18n.language === "en" ? en : pt
                                        }
                                        showYearDropdown
                                        scrollableYearDropdown
                                      />

                                      <FormErrorMessage>
                                        {
                                          (
                                            errors.scheduleRules?.[
                                              ruleIndex
                                            ] as FormikErrors<IScheduleRules>
                                          )?.startDate
                                        }
                                      </FormErrorMessage>
                                    </FormControl>
                                  </GridItem>
                                )}

                                <GridItem
                                  colSpan={1}
                                  display="flex"
                                  alignItems="center"
                                  alignSelf="baseline"
                                >
                                  <FormControl
                                    isInvalid={
                                      !!(
                                        errors.scheduleRules?.[
                                          ruleIndex
                                        ] as FormikErrors<IScheduleRules>
                                      )?.startTime
                                    }
                                  >
                                    {values.recurrenceType !==
                                      RecurrenceType.ONE_TIME && (
                                      <FormLabel color="newBlue.500">
                                        {t("start")}
                                      </FormLabel>
                                    )}
                                    <Input
                                      {...getFieldProps(
                                        `scheduleRules[${ruleIndex}].startTime`
                                      )}
                                      placeholder={t("selectTime") as string}
                                      type="time"
                                      style={{ width: "100%" }}
                                    />
                                    <FormErrorMessage>
                                      {
                                        (
                                          errors.scheduleRules?.[
                                            ruleIndex
                                          ] as FormikErrors<IScheduleRules>
                                        )?.startTime
                                      }
                                    </FormErrorMessage>
                                  </FormControl>
                                </GridItem>
                                {values.recurrenceType ===
                                  RecurrenceType.ONE_TIME && (
                                  <GridItem colSpan={1}>
                                    <FormControl
                                      w={"auto"}
                                      isInvalid={
                                        !!(
                                          errors.scheduleRules?.[
                                            ruleIndex
                                          ] as FormikErrors<IScheduleRules>
                                        )?.endDate
                                      }
                                    >
                                      <DatePicker
                                        selected={rule.endDate}
                                        onChange={(date) => {
                                          setFieldValue(
                                            `scheduleRules[${ruleIndex}].endDate`,
                                            date
                                          );
                                        }}
                                        popperPlacement={"top-start"}
                                        minDate={
                                          values.scheduleRules[0].startDate
                                        }
                                        timeIntervals={15}
                                        customInput={<CustomDatePickerButton />}
                                        dateFormat="MMMM d, yyyy"
                                        locale={
                                          i18n.language === "en" ? en : pt
                                        }
                                        showYearDropdown
                                        scrollableYearDropdown
                                      />

                                      {
                                        <FormErrorMessage>
                                          {
                                            (
                                              errors.scheduleRules?.[
                                                ruleIndex
                                              ] as FormikErrors<IScheduleRules>
                                            )?.endDate
                                          }
                                        </FormErrorMessage>
                                      }
                                    </FormControl>
                                  </GridItem>
                                )}
                                <GridItem
                                  colSpan={1}
                                  display="flex"
                                  alignItems="center"
                                  alignSelf="baseline"
                                >
                                  <FormControl
                                    isInvalid={
                                      !!(
                                        errors.scheduleRules?.[
                                          ruleIndex
                                        ] as FormikErrors<IScheduleRules>
                                      )?.endTime ||
                                      !!(
                                        errors.scheduleRules?.[ruleIndex] as any
                                      )?.duplicate
                                    }
                                  >
                                    {values.recurrenceType !==
                                      RecurrenceType.ONE_TIME && (
                                      <FormLabel color="newBlue.500">
                                        {t("end")}
                                      </FormLabel>
                                    )}

                                    <Input
                                      {...getFieldProps(
                                        `scheduleRules[${ruleIndex}].endTime`
                                      )}
                                      placeholder={t("selectTime") as string}
                                      type="time"
                                      style={{ width: "100%" }}
                                    />
                                    <FormErrorMessage>
                                      {(
                                        errors.scheduleRules?.[
                                          ruleIndex
                                        ] as FormikErrors<IScheduleRules>
                                      )?.endTime ||
                                        (
                                          errors.scheduleRules?.[
                                            ruleIndex
                                          ] as any
                                        )?.duplicate}
                                    </FormErrorMessage>
                                  </FormControl>
                                </GridItem>
                                {ruleIndex !== 0 && (
                                  <GridItem
                                    colSpan={1}
                                    mt={
                                      values.recurrenceType !==
                                        RecurrenceType.ONE_TIME &&
                                      !(
                                        errors.scheduleRules?.[
                                          ruleIndex
                                        ] as FormikErrors<IScheduleRules>
                                      )?.startDate
                                        ? "30px"
                                        : 0
                                    }
                                  >
                                    <IconButton
                                      layerStyle="lightRedOcean"
                                      _hover={{
                                        layerStyle: "lightRedOcean",
                                      }}
                                      icon={<DeleteOutline />}
                                      aria-label={""}
                                      onClick={() => remove(ruleIndex)}
                                    />
                                  </GridItem>
                                )}
                              </>
                            ))}
                          </>
                        )}
                      </FieldArray>

                      <GridItem colSpan={5} justifySelf="center" mt={"10px"}>
                        <Button
                          layerStyle="lightOcean"
                          _hover={{
                            layerStyle: "lightOcean",
                          }}
                          leftIcon={<AddIcon />}
                          onClick={() => {
                            const newRule = {
                              startDate: null,
                              endDate: null,
                              startTime: "",
                              endTime: "",
                            };

                            setFieldValue("scheduleRules", [
                              ...values.scheduleRules,
                              newRule,
                            ]);
                          }}
                        >
                          {t("newShift")}
                        </Button>
                      </GridItem>

                      <GridItem colSpan={5} p={2}>
                        <Divider borderColor={"gray.300"} />
                      </GridItem>

                      <GridItem colSpan={2}>
                        {values.recurrenceType !== RecurrenceType.ONE_TIME && (
                          <FormControl w={"auto"}>
                            <FormLabel color="newBlue.500">
                              {t("startIn")}
                            </FormLabel>
                            <DatePicker
                              selected={values.serviceStartDate}
                              onChange={(date) => {
                                setFieldValue("serviceStartDate", date);
                              }}
                              popperPlacement={"top-start"}
                              timeIntervals={15}
                              customInput={<CustomDatePickerButton />}
                              dateFormat="MMMM d, yyyy"
                              locale={i18n.language === "en" ? en : pt}
                              showYearDropdown
                              scrollableYearDropdown
                            />
                            {
                              <FormErrorMessage>
                                {t("dateRequired")}
                              </FormErrorMessage>
                            }
                          </FormControl>
                        )}
                      </GridItem>

                      <GridItem colSpan={4}>
                        <FormControl>
                          <Flex alignItems="center" mt={2}>
                            <Text
                              color="newBlue.500"
                              fontSize={"md"}
                              fontWeight="bold"
                              mr={2}
                            >
                              {t("advanceHiring")}
                            </Text>
                            <NumberInput
                              value={values.hoursInAdvance}
                              onChange={(e) => {
                                const intValue = Math.floor(Number(e)) || 1;
                                setFieldValue("hoursInAdvance", intValue);
                              }}
                              defaultValue={6}
                              min={1}
                              max={240}
                              color="black"
                              size="sm"
                              maxW={20}
                            >
                              <NumberInputField width="80px" bg="#fff" />
                              <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                              </NumberInputStepper>
                            </NumberInput>
                            <Text color="newBlue.500" fontSize={"large"} ml={2}>
                              {t("hours")}
                            </Text>
                          </Flex>
                        </FormControl>
                      </GridItem>

                      <GridItem colSpan={2}></GridItem>
                    </Grid>
                  </>
                )}
              </ModalBody>
              <ModalFooter>
                <Button
                  variant="ghost"
                  color="newBlue.500"
                  onClick={onClose}
                  mr={3}
                >
                  {t("cancel")}
                </Button>
                <Button
                  layerStyle="ocean"
                  _hover={{ layerStyle: "ocean" }}
                  onClick={submitForm}
                  type="submit"
                >
                  {isEditing ? t("editService") : t("createService")}
                </Button>
              </ModalFooter>
            </ModalContent>
          </>
        )}
      </Formik>
    </Modal>
  );
};
