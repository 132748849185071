import { makeAutoObservable, runInAction } from "mobx";
import { getEnv } from "mobx-easy";
import { ProductAnalyticsEvents } from "../../../enum/ProductAnalyticsEvents";
import { RootEnv } from "../../setup/create-store";
import { IMenuItem } from "../../../types/IMenuItem";
import { message } from "antd";
import { getMenuListMock } from "./menuItem.mock";
import i18n from "../../../i18n";

export interface MenuItemProps extends IMenuItem {
  onClickMenu?: () => void;
}

export type NavSize = "small" | "large";

export default class MenuViewStore {
  selectedKeys: string[] = localStorage.getItem("selectedKeys")?.split(",") || [
    "1",
  ];
  navSize: NavSize = "large";

  menuList: MenuItemProps[] = getMenuListMock();

  constructor() {
    makeAutoObservable(this);
  }

  setNavSize(value: NavSize) {
    this.navSize = value;
  }

  setSelectedKeys(value: string[]) {
    this.selectedKeys = value;
    localStorage.setItem("selectedKeys", value.toString());
    this.setNavSize("large");
  }

  selectedMenuItem = (menuList: IMenuItem[], pathname: string) => {
    const item = menuList.find((x) => x.url === pathname);

    if (item) {
      return item.id;
    } else {
      let subItemId;

      menuList.map((item) => {
        const id = item.subMenuItem?.find((x) => x.url === pathname)?.id;
        if (id) {
          subItemId = id;
        }
      });

      if (subItemId) {
        return subItemId;
      }
    }
  };

  setMenuList(value: IMenuItem[]) {
    this.menuList = value;
    this.setMooringLinesInMenuList();
    this.setRedirectDynamicUKC();
  }

  setMooringLinesInMenuList() {
    const index = this.menuList.findIndex(
      (x) => x.title === i18n.t("navBar.mooringLines")
    );

    if (this.menuList[index]) {
      runInAction(() => {
        this.menuList[index].onClickMenu = this.getTechnomarAuthApiMoorginLines;
      });
    }
  }

  setRedirectDynamicUKC() {
    const index = this.menuList.findIndex(
      (x) => x.title === i18n.t("navBar.dynamicUkcCharts")
    );

    if (this.menuList[index]) {
      runInAction(() => {
        this.menuList[index].onClickMenu = this.getTechnomarAuthApiUKC;
      });
    }
  }

  getTechnomarAuthApiMoorginLines = async () => {
    const { technomarAuthService } = getEnv<RootEnv>();
    technomarAuthService
      .getTechnomarAuthMooringLines()
      .then((data) => {
        window.open(data.url, "_blank");
      })
      .catch(() => {
        message.error(i18n.t("navBar.errorWhileGetMooringAuthentication"));
      });
  };

  getTechnomarAuthApiUKC = async () => {
    const { technomarAuthService } = getEnv<RootEnv>();
    technomarAuthService
      .getTechnomarAuthUKC()
      .then((data) => {
        window.open(data.url, "_blank");
      })
      .catch(() => {
        message.error(i18n.t("navBar.errorWhileGetUKCAuthentication"));
      });
  };

  reset() {
    this.selectedKeys = ["1"];
    localStorage.setItem("selectedKeys", "");
    this.setMenuList(getMenuListMock());
  }

  async addLogUserExperienceAPI(events: ProductAnalyticsEvents) {
    const { logServices } = getEnv<RootEnv>();
    logServices.addLogUserExperience(events);
  }
}
