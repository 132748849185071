export const formatMoney = (value: string, language: string) => {
  const numericValue = value.replace(/\D/g, "");

  const number = parseFloat(numericValue) / 100;

  return number.toLocaleString(language, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};
