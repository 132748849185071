export interface IOperationServiceTableColumns {
  id: number;
  name: string;
  description?: string;
  recurrenceType: RecurrenceType;
}
export enum WeekDay {
  SUNDAY = "sunday",
  MONDAY = "monday",
  TUESDAY = "tuesday",
  WEDNESDAY = "wednesday",
  THURSDAY = "thursday",
  FRIDAY = "friday",
  SATURDAY = "saturday",
}
export interface IScheduleRules {
  id?: number;
  startDate?: Date;
  startTime: string;
  endDate?: Date;
  endTime: string;
  byWeekDay?: WeekDay[];
  frequency?: CalendarFrequency;
}

export interface ServiceFormValues {
  id?: number;
  name: string;
  description?: string;
  recurrenceType: RecurrenceType;
  cost?: number | string;
  scheduleRules: IScheduleRules[];
  serviceStartDate?: Date;
  hoursInAdvance?: string;
  interval?: string;
  currency?: string;
}

export interface IServiceList {
  id?: number;
  name: string;
  description?: string;
  recurrenceType: RecurrenceType;
}

export interface IOperationService {
  id?: number;
  name: string;
  description?: string;
  cost?: number;
  hoursInAdvance?: number;
  recurrenceType: RecurrenceType;
  startDateISO?: string;
  endDateISO?: string;
  scheduleRules: IOperationServiceSchedule[];
  currency?: string;
}

export interface IOperationServiceSchedule {
  id?: number;
  operationServiceId?: number;
  frequency?: CalendarFrequency;
  byWeekDay?: WeekDay[];
  byMonthDay?: number[];
  byMonth?: CalendarMonth[];
  interval: number;
  startDateISO?: string;
  endDateISO?: string;
  startTime: string;
  endTime: string;
}

export interface IServiceEventsParams {
  id: number;
  startDate?: string;
  endDate?: string;
}

export enum RecurrenceType {
  ONE_TIME = "one_time",
  MONDAY_TO_FRIDAY = "monday_to_friday",
  WEEKLY = "weekly",
  CUSTOM = "custom",
}

export enum CalendarFrequency {
  DAILY = "daily",
  WEEKLY = "weekly",
  MONTHLY = "monthly",
}

export enum CalendarMonth {
  JANUARY = "january",
  FEBRUARY = "february",
  MARCH = "march",
  APRIL = "april",
  MAY = "may",
  JUNE = "june",
  JULY = "july",
  AUGUST = "august",
  SEPTEMBER = "september",
  OCTOBER = "october",
  NOVEMBER = "november",
  DECEMBER = "december",
}
