import { makeAutoObservable } from "mobx";
import { StoreState } from "../../../enum/StoreState";
import { RootEnv } from "../../setup/create-store";
import { getEnv } from "mobx-easy";
import { ICurrency } from "types/ICurrency";

export default class CurrencyStore {
  state: string = StoreState.DONE;
  currencies: ICurrency[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  setState(value: StoreState) {
    this.state = value;
  }

  setCurrencies(value: ICurrency[]) {
    this.currencies = value;
  }

  reset() {
    this.setState(StoreState.DONE);
    this.setCurrencies([]);
  }

  async getCurrency() {
    const { currencyService } = getEnv<RootEnv>();
    this.setState(StoreState.PEDDING);

    try {
      const response = await currencyService.getCurrency();
      this.setCurrencies(response);
      this.setState(StoreState.DONE);
    } catch (error) {
      this.setState(StoreState.ERROR);
    }
  }
}
