import AtmOceanStore from "./atmOcean/atmOceanStore";
import AuthStore from "./Auth/AuthStore";
import ForecastChartPageStore from "./forecastChart/forecastChartPage/forecastChartPageStore";
import InsightAlertStore from "./insightAlerts/insightAlertStore";
import OceanWeatherIntelligenceStore from "./oceanWeatherIntelligences/oceanWeatherIntelligenceStore";
import OperationRuleLocationInsightStore from "./operationRuleLocationInsights/operationRuleLocationInsightStore";
import OperationRuleLocationStore from "./operationRuleLocations/OperationRuleLocationStore";
import OperationRuleStore from "./operationRules/OperationRuleStore";
import UserConfigStore from "./userConfig/userConfigStore";
import ForecastChartMeteogramStore from "./forecastChart/forecastChartMeteogram/forecastChartMeteogramStore";
import PortOfCallStore from "./portOfCall/PortOfCallStore";
import AnalyticsStore from "./analytics/analyticsStore";
import AnalyticsChartStore from "./analytics/analyticsChartStore";
import RegionStore from "./region/RegionStore";
import NotificationsStore from "./notifications/notificationsStore";
import UnitMeasurementStore from "./unitMeasurement/UnitMeasurementStore";
import { SecurityStore } from "./security/SecurityStore";
import api from "../../services/api";
import ShareStore from "./share/ShareStore";
import OccurrenceStore from "./occurrence/occurrenceStore";
import FeedbackStore from "./feedback/feedbackStore";
import ManageStationStore from "./manageStation/manageStationStore";
import AnalyticsReportingStore from "./analyticsReporting/analyticsReportingStore";
import EventAnalysisStore from "./analyticsReporting/eventAnalysisStore";
import ForecastChartInsightStore from "./forecastChart/forecastChartInsight/forecastChartInsightStore";
import ForecastChartProbabilisticStore from "./forecastChart/forecastChartProbabilistic/forecastChartProbabilisticStore";
import EnvironmentalVariableStore from "./environmentalVariable/environmentalVariableStore";
import RequestCancellationStore from "./requestCancellationStore/RequestCancellationStore";
import RiverForecastChartStore from "./riverForecastChart/RiverForecastChartStore";
import OperationServicesStore from "./operationServices/OperationServicesStore";
import postHog from "posthog-js";
import CurrencyStore from "./currency/CurrencyStore";

export default class DataStore {
  operationRuleStore: OperationRuleStore;
  operationRuleLocationStore: OperationRuleLocationStore;
  operationRuleLocationInsightStore: OperationRuleLocationInsightStore;
  insightAlertStore: InsightAlertStore;
  oceanWeatherIntelligenceStore: OceanWeatherIntelligenceStore;
  atmOceanStore: AtmOceanStore;
  forecastChartPageStore: ForecastChartPageStore;
  forecastChartMeteogramStore: ForecastChartMeteogramStore;
  authStore: AuthStore;
  userConfigStore: UserConfigStore;
  portOfCallStore: PortOfCallStore;
  analyticsStore: AnalyticsStore;
  analyticsChartStore: AnalyticsChartStore;
  regionStore: RegionStore;
  notificationsStore: NotificationsStore;
  unitMeasurementStore: UnitMeasurementStore;
  securityStore: SecurityStore;
  shareStore: ShareStore;
  occurrenceStore: OccurrenceStore;
  feedbackStore: FeedbackStore;
  manageStationStore: ManageStationStore;
  analyticsReportingStore: AnalyticsReportingStore;
  eventAnalysisStore: EventAnalysisStore;
  forecastChartInsightStore: ForecastChartInsightStore;
  forecastChartProbabilisticStore: ForecastChartProbabilisticStore;
  environmentalVariableStore: EnvironmentalVariableStore;
  requestCancellationStore: RequestCancellationStore;
  riverForecastChartStore: RiverForecastChartStore;
  operationServicesStore: OperationServicesStore;
  currencyStore: CurrencyStore;

  constructor() {
    this.requestCancellationStore = new RequestCancellationStore();
    this.operationRuleStore = new OperationRuleStore();
    this.operationRuleLocationStore = new OperationRuleLocationStore();
    this.operationRuleLocationInsightStore =
      new OperationRuleLocationInsightStore();
    this.insightAlertStore = new InsightAlertStore();
    this.oceanWeatherIntelligenceStore = new OceanWeatherIntelligenceStore();
    this.atmOceanStore = new AtmOceanStore();
    this.forecastChartPageStore = new ForecastChartPageStore(
      this.requestCancellationStore
    );
    this.forecastChartMeteogramStore = new ForecastChartMeteogramStore(
      this.requestCancellationStore
    );
    this.authStore = new AuthStore();
    this.userConfigStore = new UserConfigStore();
    this.portOfCallStore = new PortOfCallStore();
    this.analyticsStore = new AnalyticsStore();
    this.analyticsChartStore = new AnalyticsChartStore(
      this.requestCancellationStore
    );
    this.regionStore = new RegionStore();
    this.notificationsStore = new NotificationsStore();
    this.unitMeasurementStore = new UnitMeasurementStore();
    this.securityStore = new SecurityStore([api]);
    this.shareStore = new ShareStore();
    this.occurrenceStore = new OccurrenceStore();
    this.feedbackStore = new FeedbackStore();
    this.manageStationStore = new ManageStationStore();
    this.analyticsReportingStore = new AnalyticsReportingStore();
    this.eventAnalysisStore = new EventAnalysisStore();
    this.forecastChartInsightStore = new ForecastChartInsightStore();
    this.forecastChartProbabilisticStore = new ForecastChartProbabilisticStore(
      this.requestCancellationStore
    );
    this.environmentalVariableStore = new EnvironmentalVariableStore();
    this.riverForecastChartStore = new RiverForecastChartStore(
      this.requestCancellationStore
    );
    this.operationServicesStore = new OperationServicesStore();
    this.currencyStore = new CurrencyStore();
  }

  resetDataStores() {
    this.operationRuleStore.reset();
    this.operationRuleLocationStore.reset();
    this.operationRuleLocationInsightStore.reset();
    this.insightAlertStore.reset();
    this.oceanWeatherIntelligenceStore.reset();
    this.atmOceanStore.reset();
    this.forecastChartPageStore.reset();
    this.forecastChartMeteogramStore.reset();
    this.userConfigStore.reset();
    this.portOfCallStore.reset();
    this.analyticsStore.reset();
    this.analyticsChartStore.reset();
    this.regionStore.reset();
    this.notificationsStore.reset();
    this.unitMeasurementStore.reset();
    this.shareStore.reset();
    this.occurrenceStore.reset();
    this.feedbackStore.reset();
    this.manageStationStore.reset();
    this.analyticsReportingStore.reset();
    this.eventAnalysisStore.reset();
    this.forecastChartInsightStore.reset();
    this.forecastChartProbabilisticStore.reset();
    this.riverForecastChartStore.reset();
    this.operationServicesStore.reset();
    this.currencyStore.reset();
    postHog.reset();
  }
}
