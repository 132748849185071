import { useDisclosure, useToast } from "@chakra-ui/react";
import { DateTime } from "luxon";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useStores } from "stores/setup/use-store";
import { RecurrenceType, ServiceFormValues } from "types/IOperationServices";
import { getTodayWeekDay } from "util/getTodayWeekDay/getTodayWeekDay";

const useFormOperationService = () => {
  const {
    dataStores: { operationServicesStore },
  } = useStores();
  const { i18n, t } = useTranslation("translation", {
    keyPrefix: "operationServices",
  });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isTutorialOpen,
    onOpen: onTutorialOpen,
    onClose: onTutorialClose,
  } = useDisclosure();
  const toast = useToast();

  const [isEditing, setIsEditing] = useState(false);
  const [initialValues, setInitialValues] = useState<ServiceFormValues>({
    name: "",
    description: "",
    recurrenceType: RecurrenceType.ONE_TIME,
    cost: undefined,
    scheduleRules: [
      {
        startDate: undefined,
        startTime: "18:00",
        endDate: undefined,
        endTime: "22:00",
        byWeekDay: [getTodayWeekDay()],
      },
    ],
    serviceStartDate: DateTime.local().toJSDate(),
    hoursInAdvance: "8",
    interval: "1",
    currency: i18n.language === "en" ? "USD" : "BRL",
  });
  const recurrenceOptions = useMemo(
    () => [
      {
        value: RecurrenceType.ONE_TIME,
        label: t("doestHappenAgain"),
      },
      {
        value: RecurrenceType.MONDAY_TO_FRIDAY,
        label: t("mondayFriday"),
      },
      {
        value: RecurrenceType.WEEKLY,
        label: t("weekly"),
      },
      {
        value: RecurrenceType.CUSTOM,
        label: t("custom"),
      },
    ],
    [t]
  );

  const handleOpenAddModal = () => {
    setIsEditing(false);

    if (operationServicesStore.isServiceListEmpty && !isTutorialOpen) {
      onTutorialOpen();
    } else {
      onTutorialClose();
      onOpen();
    }

    setInitialValues({
      name: "",
      description: "",
      recurrenceType: RecurrenceType.ONE_TIME,
      cost: undefined,
      scheduleRules: [
        {
          startDate: undefined,
          startTime: "18:00",
          endDate: undefined,
          endTime: "22:00",
          byWeekDay: [getTodayWeekDay()],
        },
      ],
      serviceStartDate: DateTime.local().toJSDate(),
      hoursInAdvance: "8",
      interval: "1",
      currency: i18n.language === "en" ? "USD" : "BRL",
    });
  };

  const onSubmitForm = (values: ServiceFormValues) => {
    const service =
      operationServicesStore.transformServiceFormToOperationService(values);

    if (isEditing) {
      operationServicesStore.updateService(service).then((success) => {
        if (success) {
          toast({
            title: t("serviceUpdatedSucess"),
            status: "success",
            position: "top",
            isClosable: true,
          });
        }
      });
    } else {
      operationServicesStore.addService(service).then((success) => {
        if (success) {
          toast({
            title: t("serviceCreatedSucess"),
            status: "success",
            position: "top",
            isClosable: true,
          });
        }
      });
    }

    onClose();
  };

  const handleOpenEditModal = async (id: number) => {
    onOpen();

    const service = await operationServicesStore.getServiceById(id);
    setIsEditing(true);
    if (service) {
      const formatService =
        operationServicesStore.transformOperationSeviceToServiceForm(service);
      setInitialValues(formatService);
    }
  };

  return {
    isOpen,
    onOpen,
    onClose,
    isEditing,
    initialValues,
    setInitialValues,
    handleOpenAddModal,
    handleOpenEditModal,
    onSubmitForm,
    isTutorialOpen,
    onTutorialOpen,
    onTutorialClose,
    recurrenceOptions,
  };
};

export default useFormOperationService;
