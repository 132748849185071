import { makeAutoObservable } from "mobx";
import { getEnv } from "mobx-easy";
import { DBAction } from "../../../enum/DBAction";
import { StoreState } from "../../../enum/StoreState";
import { ProductAnalyticsEvents } from "../../../enum/ProductAnalyticsEvents";
import { RootEnv } from "../../setup/create-store";
import { IOperationRuleLocationItem } from "../operationRuleLocationInsights/operationRuleLocationInsightModel";
import { IOperationRuleLocation } from "../operationRuleLocations/OperationRuleLocationModel";
import { IOperationRule } from "../operationRules/OperationRuleModel";
import {
  IOperationRuleAlert,
  IOperationRuleAlertForTable,
  IOperationRuleAlertUser,
} from "./insightAlertModel";

import i18n from "../../../i18n";

export default class InsightAlertStore {
  insightAlertModalVisible: boolean = false;

  operationRule: IOperationRule[] = [];

  operationRuleDetailSelected: number = 0;

  operationRuleLocation: IOperationRuleLocation[] = [];

  operationRuleLocationDetailSelected: number = 0;

  operationRuleLocationInsight: IOperationRuleLocationItem[] = [];

  operationRuleModal: IOperationRule[] = [];

  operationRuleLocationModal: IOperationRuleLocation[] = [];

  operationRuleLocationInsightModal: IOperationRuleLocationItem[] = [];

  operationRuleAlertUser: IOperationRuleAlertUser[] = [];

  operationRuleAlertUserSelected: IOperationRuleAlertUser[] = [];

  operationRuleAlertUserSelectedBeforeEdit: IOperationRuleAlertUser[] = [];

  operationRuleAlert: IOperationRuleAlert;

  state: string = StoreState.DONE;

  messageError: string = "";

  operationRuleAlertForTableDatail: IOperationRuleAlertForTable[] = [];

  operationRuleAlertForTableForEdit: IOperationRuleAlertForTable;

  operationRuleAlertAction: DBAction = DBAction.CREATE;

  constructor() {
    makeAutoObservable(this);
  }

  setInsightAlertModalVisible(value: boolean) {
    this.insightAlertModalVisible = value;
  }

  setOperationRule(value: IOperationRule[]) {
    this.operationRule = value;
  }

  setOperationRuleLocation(value: IOperationRuleLocation[]) {
    this.operationRuleLocation = value;
  }

  setOperationRuleLocationInsight(value: IOperationRuleLocationItem[]) {
    this.operationRuleLocationInsight = value;
  }

  setOperationRuleModal(value: IOperationRule[]) {
    this.operationRuleModal = value;
  }

  setOperationRuleLocationModal(value: IOperationRuleLocation[]) {
    this.operationRuleLocationModal = value;
  }

  setOperationRuleLocationInsightModal(value: IOperationRuleLocationItem[]) {
    this.operationRuleLocationInsightModal = value;
  }

  setOperationRuleAlertUser(value: IOperationRuleAlertUser[]) {
    this.operationRuleAlertUser = value;
  }

  setOperationRuleAlertUserSelected(value: IOperationRuleAlertUser[]) {
    this.operationRuleAlertUserSelected = value;
  }

  setOperationRuleAlertUserSelectedBeforeEdit(
    value: IOperationRuleAlertUser[]
  ) {
    this.operationRuleAlertUserSelectedBeforeEdit = value;
  }

  setOperationRuleLocationDetailSelected(value: number) {
    this.operationRuleLocationDetailSelected = value;
  }

  setOperationRuleDetailSelected(value: number) {
    this.operationRuleDetailSelected = value;
  }

  setState(value: StoreState) {
    this.state = value;
  }

  setMessageError(value: string) {
    this.messageError = value;
  }

  setOperationRuleAlertForTableDatail(value: IOperationRuleAlertForTable[]) {
    this.operationRuleAlertForTableDatail = value;
  }

  setOperationRuleAlertForTableForEdit(value: IOperationRuleAlertForTable) {
    this.operationRuleAlertForTableForEdit = value;
  }

  setOperationRuleAlert(value: IOperationRuleAlert) {
    this.operationRuleAlert = value;
  }

  setOperationRuleAlertAction(value: DBAction) {
    this.operationRuleAlertAction = value;
  }

  reset() {
    this.setState(StoreState.DONE);
    this.setOperationRuleAlertUser([]);
    this.setOperationRuleAlertForTableDatail([]);
  }

  async getOperationRuleAlertUserAPI(operationRuleAlertId?: number) {
    const { operationRuleAlertUserService } = getEnv<RootEnv>();
    try {
      this.setOperationRuleAlertUser([]);
      this.setState(StoreState.PEDDING);
      const operationRuleAlertUsers: IOperationRuleAlertUser[] =
        await operationRuleAlertUserService.getOperationRuleAlertUser(
          operationRuleAlertId
        );
      if (operationRuleAlertUsers.length > 0) {
        this.setOperationRuleAlertUser(operationRuleAlertUsers);
      }
      if (StoreState.PEDDING) {
        this.setState(StoreState.DONE);
      }
    } catch (error) {
      this.setState(StoreState.ERROR);
      this.setMessageError(
        i18n.t("oceanWeatherIntelligence.insightAlert.errorGetUserAlert")
      );
    }
  }

  async getOperationRuleAPI() {
    const { operationRuleService } = getEnv<RootEnv>();

    try {
      this.setOperationRule([]);
      const operationRule: IOperationRule[] =
        await operationRuleService.getOperationRule();
      if (operationRule.length > 0) {
        this.setOperationRule(operationRule);
      }
      if (this.state === StoreState.PEDDING) {
        this.setState(StoreState.DONE);
      }
    } catch (error) {
      this.setState(StoreState.ERROR);
      this.setMessageError(
        i18n.t("oceanWeatherIntelligence.insightAlert.errorGetDashboardAlert")
      );
    }
  }

  async getOperationRuleModalAPI() {
    const { operationRuleService } = getEnv<RootEnv>();

    try {
      this.setOperationRuleModal([]);
      const operationRuleModal: IOperationRule[] =
        await operationRuleService.getOperationRule();
      if (operationRuleModal.length > 0) {
        this.setOperationRuleModal(operationRuleModal);
      }
      if (this.state === StoreState.PEDDING) {
        this.setState(StoreState.DONE);
      }
    } catch (error) {
      this.setState(StoreState.ERROR);
      this.setMessageError(
        i18n.t("oceanWeatherIntelligence.insightAlert.errorGetDashboardAlert")
      );
    }
  }

  async getOperationRuleLocationAPI(operationRuleId: number) {
    const { operationRuleLocationService } = getEnv<RootEnv>();
    try {
      this.setOperationRuleLocation([]);
      this.setState(StoreState.PEDDING);
      const operationRuleLocation: IOperationRuleLocation[] =
        await operationRuleLocationService.getOperationRuleLocation(
          operationRuleId
        );
      if (operationRuleLocation.length > 0) {
        this.setOperationRuleLocation(operationRuleLocation);
      }
      if (this.state === StoreState.PEDDING) {
        this.setState(StoreState.DONE);
      }
    } catch (error) {
      this.setState(StoreState.ERROR);
      this.setMessageError(
        i18n.t("oceanWeatherIntelligence.insightAlert.errorGetLocationAlert")
      );
    }
  }

  async getOperationRuleLocationModalAPI(operationRuleId: number) {
    const { operationRuleLocationService } = getEnv<RootEnv>();
    try {
      this.setOperationRuleLocationModal([]);
      this.setState(StoreState.PEDDING);
      const operationRuleLocationModal: IOperationRuleLocation[] =
        await operationRuleLocationService.getOperationRuleLocation(
          operationRuleId
        );
      if (operationRuleLocationModal.length > 0) {
        this.setOperationRuleLocationModal(operationRuleLocationModal);
      }
      if (this.state === StoreState.PEDDING) {
        this.setState(StoreState.DONE);
      }
    } catch (error) {
      this.setState(StoreState.ERROR);
      this.setMessageError(
        i18n.t("oceanWeatherIntelligence.insightAlert.errorGetLocationAlert")
      );
    }
  }

  async getOperationRuleLocationInsightAPI(operationRuleLocationId: number) {
    const { operationRuleLocationInsightService } = getEnv<RootEnv>();

    try {
      this.setOperationRuleLocationInsight([]);
      this.setState(StoreState.PEDDING);
      const operationRuleLocationInsight: IOperationRuleLocationItem[] =
        await operationRuleLocationInsightService.getOperationRuleLocationInsight(
          operationRuleLocationId
        );
      if (operationRuleLocationInsight.length > 0) {
        this.setOperationRuleLocationInsight(operationRuleLocationInsight);
      }
      if (this.state === StoreState.PEDDING) {
        this.setState(StoreState.DONE);
      }
    } catch (error) {
      this.setState(StoreState.ERROR);
      this.setMessageError(
        i18n.t("oceanWeatherIntelligence.insightAlert.errorGetInsightAlert")
      );
    }
  }

  async getOperationRuleLocationInsightModalAPI(
    operationRuleLocationId: number
  ) {
    const { operationRuleLocationInsightService } = getEnv<RootEnv>();

    try {
      this.setOperationRuleLocationInsightModal([]);
      this.setState(StoreState.PEDDING);
      const operationRuleLocationInsightModal: IOperationRuleLocationItem[] =
        await operationRuleLocationInsightService.getOperationRuleLocationInsight(
          operationRuleLocationId
        );
      if (operationRuleLocationInsightModal.length > 0) {
        this.setOperationRuleLocationInsightModal(
          operationRuleLocationInsightModal
        );
      }
      if (this.state === StoreState.PEDDING) {
        this.setState(StoreState.DONE);
      }
    } catch (error) {
      this.setState(StoreState.ERROR);
      this.setMessageError(
        i18n.t("oceanWeatherIntelligence.insightAlert.errorGetInsightAlert")
      );
    }
  }

  async getOperationRuleAlertForTableAPI(
    operationRuleId?: number,
    operationRuleLocationId?: number
  ) {
    const { insightAlertService } = getEnv<RootEnv>();
    try {
      this.setOperationRuleAlertForTableDatail([]);
      this.setState(StoreState.PEDDING);
      const insightAlert: IOperationRuleAlertForTable[] =
        await insightAlertService.getOperationRuleAlertForTable(
          operationRuleId,
          operationRuleLocationId
        );
      if (insightAlert.length > 0) {
        this.setOperationRuleAlertForTableDatail(insightAlert);
      }
      if (this.state === StoreState.PEDDING) {
        this.setState(StoreState.DONE);
      }
    } catch (error) {
      this.setState(StoreState.ERROR);
      this.setMessageError(
        i18n.t("oceanWeatherIntelligence.insightAlert.errorGetAlertsData")
      );
    }
  }

  async getOperationRuleAlertAPI(operationRuleAlertId: number) {
    const { insightAlertService } = getEnv<RootEnv>();
    try {
      this.setState(StoreState.PEDDING);
      const insightAlert: IOperationRuleAlert =
        await insightAlertService.getOperationRuleAlert(operationRuleAlertId);
      if (insightAlert) {
        this.setOperationRuleAlert(insightAlert);
      }
      if (this.state === StoreState.PEDDING) {
        this.setState(StoreState.DONE);
      }
    } catch (error) {
      this.setState(StoreState.ERROR);
      this.setMessageError(
        i18n.t("oceanWeatherIntelligence.insightAlert.errorGetAlertsData")
      );
    }
  }

  async deleteOperationRuleAlertAPI(operationRuleAlertId: number) {
    const { insightAlertService } = getEnv<RootEnv>();

    try {
      this.setState(StoreState.PEDDING);
      let resultState = StoreState.DONE;
      const deleteAlertResult: boolean =
        await insightAlertService.deleteOperationRuleAlert(
          operationRuleAlertId
        );
      if (!deleteAlertResult) {
        resultState = StoreState.ERROR;
        this.setMessageError(
          i18n.t("oceanWeatherIntelligence.insightAlert.errorDeleteAlerts")
        );
      }
      this.setState(resultState);
      if (resultState === StoreState.DONE) {
        this.setOperationRuleAlertForTableDatail(
          this.operationRuleAlertForTableDatail.filter(
            (item) => item.id !== operationRuleAlertId
          )
        );
      }
    } catch (error: any) {
      this.setState(StoreState.ERROR);
      if (error.response?.status === 403) {
        this.setMessageError(i18n.t("youCanOnlyChangeItems"));
      } else {
        this.setMessageError(
          i18n.t("oceanWeatherIntelligence.insightAlert.errorDeleteAlerts")
        );
      }
    }
  }

  async addOperationRuleAlertAPI(operationRuleAlert: IOperationRuleAlert) {
    const { insightAlertService } = getEnv<RootEnv>();

    try {
      this.setState(StoreState.PEDDING);

      const operationRuleAlertInserted: IOperationRuleAlert =
        await insightAlertService.addOperationRuleAlert(operationRuleAlert);
      if (operationRuleAlertInserted) {
        this.getOperationRuleAlertForTableAPI();
        this.setState(StoreState.DONE);
      } else {
        this.setState(StoreState.ERROR);
        this.setMessageError(
          i18n.t("oceanWeatherIntelligence.insightAlert.errorAddAlert")
        );
      }
    } catch (error: any) {
      this.setState(StoreState.ERROR);
      if (error.response?.status === 403) {
        this.setMessageError(i18n.t("youCanOnlyChangeItems"));
      } else {
        this.setMessageError(
          i18n.t("oceanWeatherIntelligence.insightAlert.errorAddAlert")
        );
      }
    }
  }

  async updateOperationRuleAlertAPI() {
    const { insightAlertService } = getEnv<RootEnv>();
    try {
      this.setState(StoreState.PEDDING);
      const operationRuleAlertUpdated =
        await insightAlertService.updateOperationRuleAlert(
          this.operationRuleAlert
        );
      if (operationRuleAlertUpdated) {
        this.getOperationRuleAlertForTableAPI();
        this.setState(StoreState.DONE);
      } else {
        this.setState(StoreState.ERROR);
        this.setMessageError(
          i18n.t("oceanWeatherIntelligence.insightAlert.errorUpdateAlert")
        );
      }
    } catch (error: any) {
      this.setState(StoreState.ERROR);
      if (error.response?.status === 403) {
        this.setMessageError(i18n.t("youCanOnlyChangeItems"));
      } else {
        this.setMessageError(
          i18n.t("oceanWeatherIntelligence.insightAlert.errorUpdateAlert")
        );
      }
    }
  }

  async addLogUserExperienceAPI(events: ProductAnalyticsEvents) {
    const { logServices } = getEnv<RootEnv>();
    logServices.addLogUserExperience(events);
  }
}
