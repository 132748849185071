import { makeAutoObservable } from "mobx";
import { StoreState } from "../../../enum/StoreState";
import { RootEnv } from "../../setup/create-store";
import { getEnv, getRoot } from "mobx-easy";
import { DateTime } from "luxon";
import {
  CalendarFrequency,
  IOperationService,
  IServiceEventsParams,
  IServiceList,
  RecurrenceType,
  ServiceFormValues,
  WeekDay,
} from "types/IOperationServices";
import i18n from "../../../i18n";
import RootStore from "stores/root-store";
import { FormikErrors } from "formik";

export default class OperationServicesStore {
  state: string = StoreState.DONE;
  serviceList: IServiceList[] = [];
  events: { start: string; end: string }[] = [];
  selectedServiceId: number | null = null;
  showShiftOnGantt: boolean = true;
  isServiceListEmpty: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  setState(value: StoreState) {
    this.state = value;
  }

  setServiceList(value: IServiceList[]) {
    this.serviceList = value;
  }

  setEvents(value: { start: string; end: string }[]) {
    this.events = value;
  }

  setSelectedServiceId(value: number | null) {
    this.selectedServiceId = value;
  }

  setShowShiftOnGantt(value: boolean) {
    this.showShiftOnGantt = value;
  }

  setIsServiceListEmpty(value: boolean) {
    this.isServiceListEmpty = value;
  }

  reset() {
    this.setState(StoreState.DONE);
    this.setServiceList([]);
    this.setEvents([]);
    this.setSelectedServiceId(null);
    this.setShowShiftOnGantt(true);
    this.setIsServiceListEmpty(false);
  }

  async getServices(limit = 100) {
    const { operationServicesService } = getEnv<RootEnv>();
    this.setState(StoreState.PEDDING);

    try {
      const response = await operationServicesService.getServices(limit);

      this.setServiceList(response);

      if (!response.length) {
        this.setIsServiceListEmpty(true);
      }
      this.setState(StoreState.DONE);
    } catch (error) {
      this.setState(StoreState.ERROR);
    }
  }

  async getServiceById(id: number): Promise<IOperationService | undefined> {
    const { operationServicesService } = getEnv<RootEnv>();
    this.setState(StoreState.PEDDING);

    try {
      const response = await operationServicesService.getServiceById(id);
      this.setState(StoreState.DONE);
      return response;
    } catch (error) {
      this.setState(StoreState.ERROR);
    }
  }

  async addService(service: IOperationService) {
    const { operationServicesService } = getEnv<RootEnv>();
    this.setState(StoreState.PEDDING);

    try {
      const response = await operationServicesService.createServices(service);

      if (response) {
        const list = this.serviceList;
        list.push({
          id: response.id,
          name: response.name,
          description: response.description,
          recurrenceType: response.recurrenceType,
        });
        if (response?.id) {
          this.setSelectedServiceId(response?.id);
        }
        this.setServiceList(list);
        this.setState(StoreState.DONE);
        this.setIsServiceListEmpty(false);
        return list.length > 0;
      } else {
        this.setState(StoreState.ERROR);
      }
    } catch (error) {
      this.setState(StoreState.ERROR);
    }
  }

  async deleteService(id: number) {
    const { operationServicesService } = getEnv<RootEnv>();

    this.setState(StoreState.PEDDING);

    try {
      const response = await operationServicesService.deleteService(id);
      if (response.success) {
        this.setServiceList(this.serviceList.filter((s: any) => s.id !== id));

        if (!this.serviceList?.length) {
          this.setIsServiceListEmpty(true);
          this.setSelectedServiceId(null);
        } else if (this.serviceList[0]?.id) {
          this.setSelectedServiceId(this.serviceList[0].id);
        }
        this.setState(StoreState.DONE);
      } else {
        this.setState(StoreState.ERROR);
      }
    } catch (error) {
      this.setState(StoreState.ERROR);
    }
  }

  async updateService(service: IOperationService) {
    const { operationServicesService } = getEnv<RootEnv>();
    this.setState(StoreState.PEDDING);

    try {
      const response = await operationServicesService.updateService(service);
      if (response.success) {
        const editService = JSON.parse(JSON.stringify(this.serviceList));

        const index = editService.findIndex((s: any) => s.id === service.id);
        editService[index] = {
          id: service.id,
          name: service.name,
          description: service.description,
          recurrenceType: service.recurrenceType,
        };

        this.setServiceList(editService);

        this.setState(StoreState.DONE);
        return response.success;
      } else {
        this.setState(StoreState.ERROR);
      }
    } catch (error) {
      this.setState(StoreState.ERROR);
    }
  }

  async getOperationServiceEvents({
    id,
    startDate,
    endDate,
  }: IServiceEventsParams) {
    const { operationServicesService } = getEnv<RootEnv>();
    this.setState(StoreState.PEDDING);

    try {
      const response = await operationServicesService.getOperationServiceEvents(
        {
          id,
          startDate,
          endDate,
        }
      );

      this.setEvents(response);
      this.setState(StoreState.DONE);
    } catch (error) {
      this.setState(StoreState.ERROR);
    }
  }

  mapWeekDay(day: WeekDay): WeekDay {
    return WeekDay[day.toUpperCase() as keyof typeof WeekDay];
  }

  convertToUTCFormat(timeString: string): string {
    const {
      dataStores: { regionStore },
    } = getRoot<RootStore>();

    const offsetMinutes = regionStore.mainRegionTimezoneOffset ?? 0;
    const offsetHours = offsetMinutes / 60;

    const localTime = DateTime.fromFormat(timeString, "HH:mm", {
      zone: "UTC",
    }).plus({ hours: offsetHours });

    return localTime.toUTC().toFormat("HH:mm:ss'+00'");
  }

  getFormattedTimezoneOffset(): string {
    const {
      dataStores: { regionStore },
    } = getRoot<RootStore>();

    const offsetMinutes = regionStore.mainRegionTimezoneOffset ?? 0;
    const offsetHours = offsetMinutes / 60;
    return `UTC${
      offsetHours >= 0 ? `-${offsetHours}` : `+${Math.abs(offsetHours)}`
    }`;
  }

  convertUTCToUserTimezone(utcISO: string) {
    const {
      dataStores: { regionStore },
    } = getRoot<RootStore>();

    const offsetMinutes = regionStore.mainRegionTimezoneOffset ?? 0;
    const offsetHours = offsetMinutes / 60;

    const localTime = DateTime.fromISO(utcISO, { zone: "UTC" }).minus({
      hours: offsetHours,
    });

    const formattedTime = localTime.toFormat("HH:mm");

    return formattedTime;
  }

  getAdjustedISODate(date: Date | undefined, timeString: string | undefined) {
    const {
      dataStores: { regionStore },
    } = getRoot<RootStore>();

    if (!date || !timeString) return undefined;

    const offsetMinutes = regionStore.mainRegionTimezoneOffset ?? 0;
    const userTimezone = `UTC${offsetMinutes >= 0 ? "-" : "+"}${
      Math.abs(offsetMinutes) / 60
    }`;

    const startTimeLocal = DateTime.fromFormat(timeString, "HH:mm", {
      zone: userTimezone,
    });

    const dateObj = DateTime.fromJSDate(date).setZone(userTimezone);

    const adjustedStartDateISO = dateObj
      .set({
        hour: startTimeLocal.hour,
        minute: startTimeLocal.minute,
        second: 0,
        millisecond: 0,
      })
      .toUTC()
      .toISO();

    return adjustedStartDateISO;
  }

  validateForm(values: ServiceFormValues) {
    let errors: FormikErrors<ServiceFormValues> = {};
    let ruleErrors: any[] = [];

    const { name, recurrenceType, scheduleRules } = values;

    const uniqueRules = new Set();

    scheduleRules.forEach((rule, index) => {
      let ruleError: any = {};

      if (recurrenceType === RecurrenceType.ONE_TIME) {
        if (!rule.startDate) {
          ruleError.startDate = i18n.t("operationServices.startDateRequired");
        }
        if (!rule.endDate) {
          ruleError.endDate = i18n.t("operationServices.endDateRequired");
        }
      }

      if (
        recurrenceType === RecurrenceType.WEEKLY ||
        recurrenceType === RecurrenceType.CUSTOM
      ) {
        if (!rule.byWeekDay?.length) {
          ruleError.byWeekDay = i18n.t(
            "operationServices.seleactAtLeastOneDay"
          );
        }
      }

      if (!rule.startTime) {
        ruleError.startTime = i18n.t("operationServices.startTimeRequired");
      }
      if (!rule.endTime) {
        ruleError.endTime = i18n.t("operationServices.endTimeRequired");
      }

      const normalizedStartDate = rule.startDate
        ? new Date(rule.startDate).toISOString().split("T")[0]
        : "";
      const normalizedEndDate = rule.endDate
        ? new Date(rule.endDate).toISOString().split("T")[0]
        : "";
      const sortedWeekDays = rule.byWeekDay
        ? [...rule.byWeekDay].sort().join(",")
        : "";

      let ruleKey = `${normalizedStartDate}|${normalizedEndDate}|${rule.startTime}|${rule.endTime}|`;

      if (
        recurrenceType === RecurrenceType.WEEKLY ||
        recurrenceType === RecurrenceType.CUSTOM
      ) {
        ruleKey += `${sortedWeekDays}`;
      }

      if (uniqueRules.has(ruleKey)) {
        ruleError.duplicate = i18n.t(
          "operationServices.multipleShiftsDetected"
        );
      } else {
        uniqueRules.add(ruleKey);
      }

      if (Object.keys(ruleError).length > 0) {
        ruleErrors[index] = ruleError;
      }
    });

    if (ruleErrors.length > 0) {
      errors.scheduleRules = ruleErrors;
    }

    if (!name) {
      errors.name = i18n.t("operationServices.nameIsRequired") as string;
    }

    return errors;
  }

  transformServiceFormToOperationService(
    formValues: ServiceFormValues
  ): IOperationService {
    const isNoRepeat = formValues.recurrenceType === RecurrenceType.ONE_TIME;

    const parseToNumber = (value: string): number => {
      let numericValue = value.replace(/[^0-9,\.]/g, "");
      numericValue =
        i18n.language === "pt-BR"
          ? numericValue.replace(/\./g, "").replace(",", ".")
          : numericValue;
      return parseFloat(numericValue) || 0;
    };

    return {
      id: formValues?.id,
      name: formValues.name,
      description: formValues.description,
      cost: formValues.cost
        ? parseToNumber(formValues.cost?.toString())
        : undefined,
      hoursInAdvance: formValues.hoursInAdvance
        ? parseInt(formValues.hoursInAdvance, 10)
        : undefined,
      recurrenceType: formValues.recurrenceType,
      startDateISO: formValues.serviceStartDate?.toISOString(),
      currency: formValues.currency,
      scheduleRules: formValues.scheduleRules?.map((rule) => ({
        frequency: CalendarFrequency.WEEKLY,
        id: rule?.id,
        operationServiceId: formValues?.id,
        byWeekDay: rule.byWeekDay
          ? rule.byWeekDay.map(this.mapWeekDay)
          : undefined,
        interval: formValues.interval ? parseInt(formValues.interval, 10) : 1,
        startDateISO:
          rule?.startDate && isNoRepeat
            ? this.getAdjustedISODate(rule?.startDate, rule?.startTime)
            : undefined,
        endDateISO:
          rule?.endDate && isNoRepeat
            ? this.getAdjustedISODate(rule?.endDate, rule?.endTime)
            : undefined,
        startTime: this.convertToUTCFormat(rule?.startTime),
        endTime: this.convertToUTCFormat(rule?.endTime),
      })),
    };
  }

  transformOperationSeviceToServiceForm(
    event: IOperationService
  ): ServiceFormValues {
    const formatter = new Intl.NumberFormat(i18n.language, {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    const formatToLocale = (value: string) => {
      return formatter.format(parseFloat(value));
    };

    return {
      id: event?.id,
      name: event.name,
      description: event.description,
      cost: event.cost && formatToLocale(event.cost?.toString()),
      hoursInAdvance: event.hoursInAdvance
        ? event.hoursInAdvance.toString()
        : undefined,
      recurrenceType: event.recurrenceType,
      serviceStartDate: event.startDateISO
        ? new Date(event.startDateISO)
        : undefined,
      interval:
        event?.scheduleRules?.length && event?.scheduleRules?.[0]?.interval
          ? event?.scheduleRules[0].interval.toString()
          : undefined,
      currency: event.currency,
      scheduleRules: event?.scheduleRules.map((rule) => ({
        id: rule?.id,
        startDate: rule.startDateISO ? new Date(rule.startDateISO) : undefined,
        startTime: this.convertUTCToUserTimezone(rule.startTime),
        endDate: rule.endDateISO ? new Date(rule.endDateISO) : undefined,
        endTime: this.convertUTCToUserTimezone(rule.endTime),
        byWeekDay: rule.byWeekDay
          ? rule.byWeekDay.map(this.mapWeekDay)
          : undefined,
      })),
    };
  }
}
