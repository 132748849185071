import { EnvironmentalVariables } from "../../../../enum/EnvironmentalVariables";
import { VisualizationType } from "../../../../enum/VisualizationType";
import i18n from "../../../../i18n";
import { ISerieConfigMap } from "./types/ISerieConfigMap";

export const getTemperatureConfigMap = (
  type: VisualizationType
): Record<string, ISerieConfigMap> => {
  const forecastColors: Partial<Record<EnvironmentalVariables, string>> = {
    [EnvironmentalVariables.TEMP_AIR]: "#f56f02",
    [EnvironmentalVariables.DEW_POINT]: "#2ca9de",
    [EnvironmentalVariables.REL_HUMIDITY]: "rgba(11, 127, 171, 0.15 )",
  };

  const measurementColors: Partial<Record<EnvironmentalVariables, string>> = {
    [EnvironmentalVariables.TEMP_AIR]: "#f56f02",
    [EnvironmentalVariables.DEW_POINT]: "#2ca9de",
    [EnvironmentalVariables.REL_HUMIDITY]: "rgba(11, 127, 171, 0.15 )",
  };

  const getColor = (
    variable: EnvironmentalVariables,
    type: VisualizationType
  ) => {
    let colorMap: Record<string, string>;
    switch (type) {
      case VisualizationType.FORECAST:
        colorMap = forecastColors;
        break;
      case VisualizationType.SENSOR:
        colorMap = measurementColors;
        break;
      default:
        colorMap = {};
        break;
    }
    return colorMap[variable.toLowerCase()] || "#347ED5";
  };
  return {
    [EnvironmentalVariables.TEMP_AIR]: {
      serieConfig: {
        color: getColor(EnvironmentalVariables.TEMP_AIR, type),
        updateYAxis: true,
        yAxis: 0,
      },
      yAxisConfig: {
        title: {
          text: i18n.t("environmentalVariableEnum.temp_air"),
          style: {
            color: "#000000",
          },
        },
        min: 0,
        max: 40,
        labels: { format: "{value}", style: { color: "#000000" } },
        gridLineWidth: 1,
      },
    },
    [EnvironmentalVariables.DEW_POINT]: {
      serieConfig: {
        color: getColor(EnvironmentalVariables.DEW_POINT, type),
        updateYAxis: true,
        yAxis: 0,
      },
      yAxisConfig: {
        title: {
          text: `${i18n.t(
            "forecastChart.forecastTable.environmentalVariableEnum.temp_air"
          )} / ${i18n.t("environmentalVariableEnum.dew_point")}`,
          style: {
            color: "#000000",
          },
        },
        min: 0,
        max: 40,
        labels: { format: "{value}", style: { color: "#000000" } },
        gridLineWidth: 1,
      },
    },
    [EnvironmentalVariables.REL_HUMIDITY]: {
      serieConfig: {
        type: "column",
        color: getColor(EnvironmentalVariables.REL_HUMIDITY, type),
        groupPadding: 0,
        pointPadding: 0,
        borderWidth: 0,
        shadow: false,
        dataLabels: {
          enabled: false,
        },
        yAxis: 1,
        updateYAxis: true,
      },
      yAxisConfig: {
        title: {
          text: i18n.t("environmentalVariableEnum.rel_humidity"),
          style: {
            color: "#000000",
          },
        },
        min: 0,
        max: 100,
        labels: {
          labels: { format: "{value}", style: { color: "#000000" } },
        },
        gridLineWidth: 0,
        tickLength: 0,
        opposite: true,
      },
    },
  };
};
