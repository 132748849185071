import { useStores } from "stores/setup/use-store";
import { useTranslation } from "react-i18next";
import OperationServices from "./OperationServices";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useState } from "react";
import { useDisclosure, useToast } from "@chakra-ui/react";
import { IOperationServiceTableColumns } from "types/IOperationServices";
import { StoreState } from "enum/StoreState";
import { ColumnFilterItem } from "antd/lib/table/interface";
import { useLocation } from "react-router-dom";
import useFormOperationService from "./hooks/useFormOperationService";

const OperationServicesContainer = () => {
  const {
    dataStores: { operationServicesStore, currencyStore },
  } = useStores();
  const {
    isOpen,
    onOpen,
    isTutorialOpen,
    onTutorialOpen,
    onTutorialClose,
    initialValues,
    handleOpenAddModal,
    onSubmitForm,
    onClose,
    isEditing,
    handleOpenEditModal,
    recurrenceOptions,
  } = useFormOperationService();

  const { t } = useTranslation("translation", {
    keyPrefix: "operationServices",
  });

  const [serviceRemoveId, setServiceDeleteId] = useState(0);

  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useDisclosure();

  const toast = useToast();
  const location = useLocation();

  useEffect(() => {
    operationServicesStore.getServices();
    currencyStore.getCurrency();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.get("openModal") === "true") {
      onTutorialOpen();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, onOpen]);

  const handleOpenDeleteModal = (serviceId: number) => {
    setServiceDeleteId(serviceId);
    onDeleteOpen();
  };

  const handleOnConfirmDelete = () => {
    if (serviceRemoveId) {
      operationServicesStore.deleteService(serviceRemoveId);
      setServiceDeleteId(0);
      onDeleteClose();
      toast({
        title: t("serviceRemovedSuccess"),
        status: "success",
        position: "top",
        isClosable: true,
      });
    } else {
      toast({
        title: t("removalFailedContactUs"),
        status: "error",
        position: "top",
        isClosable: true,
      });
    }
  };

  const getColumnFilter = useCallback(
    (item: keyof IOperationServiceTableColumns) => {
      const filterAux: ColumnFilterItem[] = [];
      for (const row of operationServicesStore.serviceList) {
        const columnFilterItem: ColumnFilterItem = {
          text: String(row[item]),
          value: row[item] as string,
        };
        if (
          !filterAux.find((filter) => filter.value === columnFilterItem.value)
        ) {
          filterAux.push(columnFilterItem);
        }
      }

      return filterAux;
    },
    [operationServicesStore.serviceList]
  );

  return OperationServices({
    handleOnConfirmDelete,
    handleOpenDeleteModal,
    isOpen,
    onClose,
    isDeleteOpen,
    onDeleteClose,
    initialValues,
    isTutorialOpen,
    onTutorialClose,
    isEditing,
    handleOpenAddModal,
    handleOpenEditModal,
    recurrenceOptions,
    serviceList: operationServicesStore.serviceList,
    onSubmitForm,
    validateForm: operationServicesStore.validateForm,
    isLoading: operationServicesStore.state === StoreState.PEDDING,
    getColumnFilter,
    formattedTimezone: operationServicesStore.getFormattedTimezoneOffset(),
    currencies: currencyStore.currencies,
    isServiceListEmpty: operationServicesStore.isServiceListEmpty,
  });
};

export default observer(OperationServicesContainer);
