import { extendTheme } from "@chakra-ui/react";

const Theme = {
  initialColorMode: "dark",
  useSystemColorMode: false,
  styles: {
    global: () => ({
      body: {
        bg: "#f0f1f2",
        fontSize: "14px",
      },
    }),
  },
  fonts: {
    heading: `'Akko Pro', sans-serif`,
    body: `'Akko Pro', sans-serif`,
  },
  shadows: "none",
  fontWeights: {
    normal: 400,
    medium: 600,
    bold: 700,
    xBold: 900,
  },
  colors: {
    transparent: "transparent",
    black: "#000",
    white: {
      100: "#FFFFFF",
      300: "#f0f1f2",
    },
    gray: {
      50: "#f7fafc",
      100: "#E5E5E5",
      200: "#ebedf0",
      300: "#d4d4d4",
      400: "#b8b8b8",
      500: "#7e7b7b",
      700: "#555353",
      800: "#ffffff14",
      900: "#171923",
    },
    newBlue: {
      50: "#c7e1f3",
      100: "#add8f5",
      300: "#1DA0DB",
      500: "#286795",
      600: "#267dbb",
      700: "#267dbb",
      800: "#c7e1f3",
      900: "#c7e1f3",
    },
    blue: {
      50: "#c7e1f3",
      100: "#1DA0DB",
      200: "#286795",
      400: "#1DA0DB3D",
      600: "#1e3874",
      900: "#1E1853",
    },
    lightBlue: {
      100: "#1da0db14",
      200: "#1da0db3d",
    },
    yellow: {
      500: "#FFD338",
    },
  },
  layerStyles: {
    ocean: {
      backgroundColor: "#286795",
      color: "white",
      _hover: {
        backgroundColor: "rgba(40, 103, 149, 0.8)",
      },
    },
    lightOcean: {
      backgroundColor: "rgba(49, 154, 221, 0.1)",
      color: "#286795",
      _hover: {
        backgroundColor: "rgba(49, 154, 221, 0.2)",
      },
    },
    greenOcean: {
      backgroundColor: "rgba(24, 112, 71, 0.08)",
      color: "#187047",
      _hover: {
        backgroundColor: "rgba(24, 112, 71, 0.2)",
      },
    },
    lightRedOcean: {
      backgroundColor: "rgba(159, 41, 51, 0.1)",
      color: "rgba(159, 41, 51, 0.8)",
      _hover: {
        backgroundColor: "rgba(159, 41, 51, 0.2)",
      },
    },
    redOcean: {
      backgroundColor: "#9F2933",
      color: "white",
      _hover: {
        backgroundColor: "rgba(159, 41, 51, 0.8)",
      },
    },
    yellowOcean: {
      backgroundColor: "#CB9428",
      color: "white",
      _hover: {
        backgroundColor: "rgba(203, 148, 40, 0.8)",
      },
    },
    grayOcean: {
      backgroundColor: "#fff",
      color: "#286795",
      _hover: {
        backgroundColor: "rgba(235, 235, 235, 0.8)",
      },
      _active: {
        backgroundColor: "#286795",
        color: "#fff",
      },
    },
    darkOcean: {
      backgroundColor: "rgba(0, 0, 0, 0.37)",
      color: "white",
      backdropFilter: "blur(2px)",
      _hover: {
        backgroundColor: "rgba(0, 0, 0, 0.47)",
      },
      _disabled: {
        cursor: "not-allowed",
        boxShadow: "none",
        opacity: 0.4,
        backgroundColor: "rgba(0, 0, 0, 0.75)",
        backdropFilter: "blur(2px)",
      },
    },
  },
} as const;

export type ChakraTheme = typeof Theme;

const myTheme = extendTheme(Theme);

export default myTheme;
