export enum ConfigKey {
  // The node environment (development | test | production)
  NODE_ENV = "NODE_ENV",

  VERSION = "VERSION",
  // Debug mode
  DEBUG = "DEBUG",

  REACT_APP_BACKEND_API_URL = "REACT_APP_BACKEND_API_URL",

  REACT_APP_FIREBASE_CREDENTIAL = "REACT_APP_FIREBASE_CREDENTIAL",

  FIREBASE_FIRESTORE_EMULATOR_HOST = "FIREBASE_FIRESTORE_EMULATOR_HOST",

  REACT_APP_CONTACT_URL = "REACT_APP_CONTACT_URL",

  REACT_APP_SENTRY_DSN = "REACT_APP_SENTRY_DSN",

  REACT_APP_PUBLIC_POSTHOG_KEY = "REACT_APP_PUBLIC_POSTHOG_KEY",

  REACT_APP_PUBLIC_POSTHOG_HOST = "REACT_APP_PUBLIC_POSTHOG_HOST",
}
