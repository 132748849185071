import Highcharts from "highcharts";
import { DateTime } from "luxon";
import { action, makeObservable, observable } from "mobx";
import { getEnv } from "mobx-easy";
import {
  EnvironmentalType,
  MeteogramVariables,
} from "../../../enum/EnvironmentalType";
import { StoreState } from "../../../enum/StoreState";
import { ProductAnalyticsEvents } from "../../../enum/ProductAnalyticsEvents";
import { RootEnv } from "../../setup/create-store";
import { IStation } from "../oceanWeatherIntelligences/oceanWeatherIntelligenceModel";
import { IAtmOceanLineChart, IAtmOceanMeteogram } from "./atmOceanModel";

export default class AtmOceanStore {
  chartEnvionmentaldata: IAtmOceanLineChart = {
    limits: { min: 0, max: 5 },
    serie: { name: "", units: "", data: [] },
  };
  chartMeteogramData: IAtmOceanMeteogram = {
    plotOptions: {
      series: { pointInterval: 0, pointStart: 0, timezoneOffset: 180 },
    },
    series: [
      {
        name: "",
        environmental_variable: "",
        units: "",
        units_symbol: "",
        min: 0,
        max: 0,
        data: [],
      },
    ],
  };

  chartMeteogramDataSeries: any[] = [];
  chartMeteogramYAxis: any[] = [
    {
      // Primary yAxis
      labels: {
        format: "{value}",
        style: {
          color: "#00000",
        },
      },
      title: {
        text: "Environmental Variable 1",
        style: {
          color: "#00000",
        },
      },
    },
    {
      // Secondary yAxis
      title: {
        text: "Environmental Variable 2",
        style: {
          color: "#00000",
        },
      },
      labels: {
        format: "{value} mm",
        style: {
          color: "#00000",
        },
      },
      opposite: true,
    },
  ];

  environmentalDataCSV: string = "";

  state: string = StoreState.DONE;

  messageError: string = "";

  messageSuccess: string = "";

  tabStateActivty: EnvironmentalType = EnvironmentalType.WATER_FLOW;

  stations: IStation[] = [];

  selectedStation: number = 0;

  mapCenterLatitude: number = 0;

  mapCenterLongitude: number = 0;

  selectForecastDateAvailable: DateTime | undefined;

  clickMapLatLng: any;

  regionId: number = 0;

  modalForecastChartV2Visible: boolean = true;

  clickMapPointHeatMap: any[][] = [];

  noDataAvailableMessage: string = "";

  constructor() {
    makeObservable(this, {
      chartEnvionmentaldata: observable,
      environmentalDataCSV: observable,
      state: observable,
      messageError: observable,
      chartMeteogramData: observable,
      chartMeteogramDataSeries: observable,
      chartMeteogramYAxis: observable,
      tabStateActivty: observable,
      stations: observable,
      mapCenterLatitude: observable,
      mapCenterLongitude: observable,
      selectForecastDateAvailable: observable,
      clickMapLatLng: observable,
      regionId: observable,
      messageSuccess: observable,
      modalForecastChartV2Visible: observable,
      clickMapPointHeatMap: observable,
      noDataAvailableMessage: observable,
      getChartEnvionmentaldataApi: action,
      getEnvironmentalDataCSVApi: action,
      setStations: action,
      setMapCenter: action,
      addLogUserExperienceAPI: action,
      setSelectForecastDateAvailable: action,
      clearSelectForecastDateAvailable: action,
      setRegionId: action,
      getStationsForecastChartAPI: action,
      getStationById: action,
      setMessageSuccess: action,
      setModalForecastChartV2Visible: action,
      setClickMapPointHeatMap: action,
      stationSavedByUser: action,
      removeStationByUserAPI: action,
      setNoDataAvailableMessage: action,
    });
  }

  setState(value: StoreState) {
    this.state = value;
  }

  setMessageError(value: string) {
    this.messageError = value;
  }

  setMessageSuccess(value: string) {
    this.messageSuccess = value;
  }

  setChartEnvionmentaldata(value: IAtmOceanLineChart) {
    this.chartEnvionmentaldata = value;
  }

  setEnvironmentalDataCSV(value: string) {
    this.environmentalDataCSV = value;
  }

  setChartMeteogramData(value: IAtmOceanMeteogram) {
    this.chartMeteogramData = value;
  }

  setChartMeteogramDataSeries(value: any[]) {
    this.chartMeteogramDataSeries = value;
  }

  setChartMeteogramYAxis(value: any[]) {
    this.chartMeteogramYAxis = value;
  }

  setTabStateActivity(environmentalType: EnvironmentalType) {
    this.tabStateActivty = environmentalType;
  }

  setStations(values: IStation[]) {
    this.stations = values;
  }

  setSelectedStation(values: number) {
    this.selectedStation = values;
  }

  setClickMapLatLng(values: any) {
    this.clickMapLatLng = values;
  }

  setClickMapPointHeatMap(value: any[][]) {
    this.clickMapPointHeatMap = value;
  }

  getStationById(id: number) {
    return this.stations.find((value) => value.station_id === id);
  }

  stationSavedByUser(id: number) {
    const station = this.stations.find((value) => value.station_id === id);
    if (station) {
      return station.station_save_by_user || false;
    }
    return false;
  }

  setMapCenter(): void {
    const foundStation = this.stations.find(
      (value) => value.station_id === this.selectedStation
    );
    if (foundStation) {
      this.mapCenterLatitude = foundStation.station_lat;
      this.mapCenterLongitude = foundStation.station_lon;
    }
  }

  setSelectForecastDateAvailable(values: DateTime) {
    this.selectForecastDateAvailable = values;
  }

  clearSelectForecastDateAvailable() {
    this.selectForecastDateAvailable = undefined;
  }

  setRegionId(regionId: number) {
    this.regionId = regionId;
  }

  setModalForecastChartV2Visible(value: boolean) {
    this.modalForecastChartV2Visible = value;
  }

  setNoDataAvailableMessage(value: string) {
    this.noDataAvailableMessage = value;
    setTimeout(() => {
      this.noDataAvailableMessage = "";
    }, 6000);
  }

  resetChartMeteogramData() {
    this.setChartMeteogramYAxis([
      {
        // Primary yAxis
        labels: {
          format: "{value}",
          style: {
            color: "#00000",
          },
        },
        title: {
          text: "Environmental Variable 1",
          style: {
            color: "#00000",
          },
        },
      },
      {
        // Secondary yAxis
        title: {
          text: "Environmental Variable 2",
          style: {
            color: "#00000",
          },
        },
        labels: {
          format: "{value} mm",
          style: {
            color: "#00000",
          },
        },
        opposite: true,
      },
    ]);
    this.setChartMeteogramDataSeries([]);
    this.setChartMeteogramData({
      plotOptions: {
        series: { pointInterval: 0, pointStart: 0, timezoneOffset: 180 },
      },
      series: [
        {
          name: "",
          environmental_variable: "",
          units: "",
          units_symbol: "",
          min: 0,
          max: 0,
          data: [],
        },
      ],
    });
  }

  reset() {
    this.resetChartMeteogramData();
    this.setStations([]);
    this.setRegionId(0);
    this.setSelectedStation(0);
    this.setSelectForecastDateAvailable(DateTime.local());
    this.setTabStateActivity(EnvironmentalType.WATER_FLOW);
    this.clearSelectForecastDateAvailable();
    this.setState(StoreState.DONE);
    this.setClickMapLatLng(undefined);
    this.setNoDataAvailableMessage("");
  }

  async getChartEnvionmentaldataApi(
    station_id: number,
    data_type: string,
    environmental_type: string,
    environmental_variable: string
  ) {
    const { atmOceanService } = getEnv<RootEnv>();
    this.setState(StoreState.PEDDING);
    try {
      this.setChartEnvionmentaldata({
        limits: { min: 0, max: 5 },
        serie: { name: "", units: "", data: [] },
      });
      if (station_id > 0) {
        const chartData: IAtmOceanLineChart =
          await atmOceanService.getHighChartSeriesEnvironmentalData(
            station_id,
            data_type,
            environmental_type,
            environmental_variable,
            "123",
            0
          );
        this.setChartEnvionmentaldata(chartData);
      }

      this.setState(StoreState.DONE);
    } catch (error) {
      this.setState(StoreState.ERROR);
      this.setMessageError(
        "Error to try get data for chart environmental. Please contact us."
      );
    }
  }

  async getEnvironmentalDataCSVApi(
    station_id: number,
    data_type: string,
    environmental_type: EnvironmentalType,
    create_at?: string
  ) {
    const { atmOceanService } = getEnv<RootEnv>();
    try {
      const csv = await atmOceanService.getEnvironmentalDataCSV(
        station_id,
        data_type,
        environmental_type,
        create_at
      );
      this.setEnvironmentalDataCSV(csv);
    } catch (error) {
      this.setState(StoreState.ERROR);
      this.setMessageError(
        "Error to try download csv for environmental data. Please contact us."
      );
    }
  }

  async getChartMeteogramDataApi(
    station_id: number,
    environmental_type: EnvironmentalType,
    create_at?: string
  ) {
    const { atmOceanService } = getEnv<RootEnv>();
    this.setState(StoreState.PEDDING);
    this.resetChartMeteogramData();
    try {
      if (station_id > 0) {
        const chartData: IAtmOceanMeteogram =
          await atmOceanService.getEnvironmentalDataMeteogram({
            station_id: station_id,
            environmental_type: environmental_type,
            create_at: create_at,
            macro_region_origin: "",
          });
        this.setChartMeteogramData(chartData);
        this.processMeteogramSerie(environmental_type, chartData.series);
      }

      this.setState(StoreState.DONE);
    } catch (error) {
      this.setState(StoreState.ERROR);
      this.setMessageError(
        "Error to try get data for chart meteograma. Please contact us."
      );
    }
  }

  async processMeteogramSerie(
    environmentalType: EnvironmentalType,
    series: any[]
  ) {
    let serieProcessed = [];
    let yAxisProcessed = [];
    switch (environmentalType) {
      case EnvironmentalType.WATER_FLOW:
        for (const value of series) {
          if (value.environmental_variable === MeteogramVariables.CURRENT_VEL) {
            serieProcessed.push({
              name: value.name,
              type: "spline",
              yAxis: 1,
              keys: ["y", "rotation"],
              data: value.data,
              color: "#ff3333",
              tooltip: {
                valueDecimals: 2,
                valueSuffix: value.units_symbol,
              },
            });
            yAxisProcessed[1] = {
              labels: {
                format: `{value} ${value.units_symbol}`,
                style: {
                  color: "#000000",
                },
              },
              max: value.max * 1.2 > 5 ? value.max * 1.2 : 5,
              title: {
                text: value.name,
                style: {
                  color: "#000000",
                },
              },
              gridLineWidth: 1,
              opposite: true,
            };
          } else if (
            value.environmental_variable === MeteogramVariables.WATER_LEVEL
          ) {
            serieProcessed.push({
              name: value.name,
              type: "spline",
              data: value.data,
              color: "#337ab7",
              tooltip: {
                valueDecimals: 2,
                valueSuffix: value.units_symbol,
              },
            });
            yAxisProcessed[0] = {
              labels: {
                format: `{value} ${value.units_symbol}`,
                style: {
                  color: "#000000",
                },
              },
              max: value.max * 1.2 > 2.5 ? value.max * 1.2 : 2.5,
              title: {
                text: value.name,
                style: {
                  color: "#000000",
                },
              },
              gridLineWidth: 1,
            };
          } else if (
            value.environmental_variable === MeteogramVariables.CURRENT_DIR
          ) {
            serieProcessed.push({
              name: value.name,
              type: "windbarb",
              enableMouseTracking: true,
              grouping: true,
              data: value.data,
              color: "#48afe8",
              tooltip: {
                formatDirection: true,
                reverseDirection: true,
                valueDecimals: 2,
                valueSuffix: value.units_symbol,
              },
            });
          }
        }
        break;
      case EnvironmentalType.WEATHER:
        for (const value of series) {
          if (value.environmental_variable === MeteogramVariables.WIND_VEL) {
            serieProcessed.push({
              name: value.name,
              type: "spline",
              keys: ["y", "rotation"],
              data: value.data,
              color: "#337ab7",
              tooltip: {
                valueDecimals: 2,
                valueSuffix: value.units_symbol,
              },
            });
            yAxisProcessed[0] = {
              labels: {
                format: `{value} ${value.units_symbol}`,
                style: {
                  color: "#000000",
                },
              },
              max: 40,
              title: {
                text: value.name,
                style: {
                  color: "#000000",
                },
              },
              gridLineWidth: 1,
            };
          } else if (value.environmental_variable === MeteogramVariables.GUST) {
            serieProcessed.push({
              name: value.name,
              type: "spline",
              yAxis: 1,
              keys: ["y", "rotation"],
              data: value.data,
              color: "#ff3333",
              tooltip: {
                valueDecimals: 2,
                valueSuffix: value.units_symbol,
              },
            });
            yAxisProcessed[1] = {
              labels: {
                format: `{value} ${value.units_symbol}`,
                style: {
                  color: "#000000",
                },
              },
              max: value.max * 1.2 > 40 ? value.max * 1.2 : 40,
              title: {
                text: value.name,
                style: {
                  color: "#000000",
                },
              },
              gridLineWidth: 1,
              opposite: true,
            };
          } else if (
            value.environmental_variable === MeteogramVariables.PRECIP
          ) {
            serieProcessed.push({
              name: value.name,
              type: "column",
              data: value.data,
              color: "rgba(0,128,0, 0.45)",
              keys: ["y", "rotation"],
              yAxis: 1,
              groupPadding: 0,
              pointPadding: 0,
              borderWidth: 0,
              shadow: false,
              dataLabels: {
                enabled: false,
              },
              style: {
                fontSize: "12px",
              },
              tooltip: {
                valueDecimals: 2,
                valueSuffix: value.units_symbol,
              },
            });
            yAxisProcessed[2] = {
              title: {
                text: null,
              },
              labels: {
                enabled: false,
              },
              gridLineWidth: 0,
              tickLength: 0,
              opposite: true,
            };
          } else if (
            value.environmental_variable === MeteogramVariables.WIND_DIR
          ) {
            serieProcessed.push({
              name: value.name,
              type: "windbarb",
              enableMouseTracking: true,
              data: value.data,
              color: "#48afe8",
              tooltip: {
                formatDirection: true,
                reverseDirection: false,
                valueDecimals: 2,
                valueSuffix: value.units_symbol,
              },
            });
          }
        }
        yAxisProcessed[0].max = yAxisProcessed[1].max;
        break;
      case EnvironmentalType.WAVE:
      case EnvironmentalType.WAVE_SEA:
      case EnvironmentalType.WAVE_SWELL:
        for (const value of series) {
          if (
            (value.environmental_variable as string).includes(
              MeteogramVariables.WAVE_HEIGHT
            )
          ) {
            serieProcessed.push({
              name: value.name,
              type: "spline",
              keys: ["y", "rotation"],
              data: value.data,
              color: "#68cee8",
              tooltip: {
                valueDecimals: 2,
                valueSuffix: value.units_symbol,
              },
            });
            yAxisProcessed[0] = {
              labels: {
                format: `{value} ${value.units_symbol}`,
                style: {
                  color: "#000000",
                },
              },
              max: value.max * 1.2 > 3 ? value.max * 1.2 : 3,
              title: {
                text: value.name,
                style: {
                  color: "#000000",
                },
              },
              gridLineWidth: 1,
            };
          } else if (
            (value.environmental_variable as string).includes(
              MeteogramVariables.PEAK_PERIOD
            )
          ) {
            serieProcessed.push({
              name: value.name,
              type: "column",
              data: value.data,
              color: "#00488c",
              yAxis: 1,
              tooltip: {
                valueDecimals: 2,
                valueSuffix: value.units_symbol,
              },
            });
            yAxisProcessed[1] = {
              labels: {
                format: `{value} ${value.units_symbol}`,
                style: {
                  color: "#000000",
                },
              },
              max: value.max * 1.2 > 40 ? value.max * 1.2 : 40,
              title: {
                text: value.name,
                style: {
                  color: "#000000",
                },
              },
              gridLineWidth: 1,
              opposite: true,
            };
          } else if (
            (value.environmental_variable as string).includes(
              MeteogramVariables.WAVE_DIR
            )
          ) {
            serieProcessed.push({
              name: value.name,
              type: "windbarb",
              enableMouseTracking: true,
              data: value.data,
              color: "#48afe8",
              tooltip: {
                formatDirection: true,
                reverseDirection: false,
                valueDecimals: 2,
                valueSuffix: value.units_symbol,
              },
            });
          }
        }
        break;
    }
    this.setChartMeteogramDataSeries(serieProcessed);
    this.setChartMeteogramYAxis(yAxisProcessed);
  }

  async getStationsMeteogramAPI(environmental_type: EnvironmentalType) {
    const { atmOceanService } = getEnv<RootEnv>();
    this.setState(StoreState.PEDDING);
    this.setStations([]);
    this.resetChartMeteogramData();
    let getStationsSucess = false;
    try {
      this.setTabStateActivity(environmental_type);
      const stationsResult: IStation[] =
        await atmOceanService.getStationsMeteogram(environmental_type);

      if (stationsResult.length > 0) {
        const found = stationsResult.find(
          (value) => value.station_id === this.selectedStation
        );
        if (stationsResult[0].region_id !== undefined) {
          this.setRegionId(stationsResult[0].region_id);
        }
        if (!found) {
          this.setSelectedStation(stationsResult[0].station_id);
          this.setClickMapLatLng({
            lat: parseFloat(stationsResult[0].station_lat.toString()),
            lng: parseFloat(stationsResult[0].station_lon.toString()),
          });
        }
      } else {
        this.setNoDataAvailableMessage(
          `Oops! There aren't stations configured with ${(
            this.tabStateActivty as string
          ).replace(
            /_/g,
            " "
          )} data. Contact us if you'd like to display the forecast charts for ${(
            this.tabStateActivty as string
          ).replace(/_/g, " ")}.`
        );
        this.setSelectedStation(0);
      }
      this.setState(StoreState.DONE);
      this.setStations(stationsResult);
      this.setMapCenter();
      getStationsSucess = true;
    } catch (error) {
      this.setSelectedStation(0);
      this.setState(StoreState.ERROR);
      this.setMessageError(
        "Error to try get data station on meteogram chart type. Please contact us."
      );
    }

    if (getStationsSucess) {
      if (this.selectForecastDateAvailable) {
        this.getChartMeteogramDataApi(
          this.selectedStation,
          environmental_type as EnvironmentalType,
          this.selectForecastDateAvailable.toISO({ suppressMilliseconds: true })
        );
      } else {
        this.getChartMeteogramDataApi(
          this.selectedStation,
          environmental_type as EnvironmentalType
        );
      }
    }
  }

  async getStationsForecastChartAPI(environmental_type: EnvironmentalType) {
    const { atmOceanService } = getEnv<RootEnv>();

    this.setStations([]);
    this.resetChartMeteogramData();
    let getStationsSucess = false;
    try {
      this.setTabStateActivity(environmental_type);
      const stationsResult: IStation[] =
        await atmOceanService.getStationsForecastChart(environmental_type);

      if (stationsResult.length > 0) {
        const found = stationsResult.find(
          (value) => value.station_id === this.selectedStation
        );
        if (stationsResult[0].region_id !== undefined) {
          this.setRegionId(stationsResult[0].region_id);
        }
        if (!found) {
          this.setSelectedStation(stationsResult[0].station_id);
          this.setClickMapLatLng({
            lat: parseFloat(stationsResult[0].station_lat.toString()),
            lng: parseFloat(stationsResult[0].station_lon.toString()),
          });
        }
      } else {
        this.setSelectedStation(0);
      }

      this.setStations(stationsResult);
      this.setMapCenter();
      getStationsSucess = true;
    } catch (error) {
      this.setState(StoreState.ERROR);
      this.setMessageError(
        "Error to try get data station on meteogram chart type. Please contact us."
      );
    }
  }

  async saveStationByUserAPI(
    description: string,
    latitude: number,
    longitude: number
  ) {
    const { atmOceanService } = getEnv<RootEnv>();
    this.setState(StoreState.PEDDING);
    const msgError = "Error to save virtual station. Please contact us.";
    try {
      const hasStationDescription = await atmOceanService.hasStationDescription(
        description,
        ""
      );
      if (hasStationDescription) {
        this.setMessageError(
          `There is already a station with this name: ${description}`
        );
        this.setState(StoreState.ERROR);
      } else {
        const stationInserted = await atmOceanService.saveStationByUser(
          description,
          latitude,
          longitude,
          ""
        );
        if (stationInserted) {
          this.stations.push(stationInserted);
          this.setSelectedStation(stationInserted.station_id);
          this.setState(StoreState.SUCCESS);
          this.setMessageSuccess("Station successfully saved!");
          setTimeout(() => {
            this.setState(StoreState.DONE);
          }, 3000);
        } else {
          this.setMessageError(msgError);
          this.setState(StoreState.ERROR);
        }
      }
    } catch (error) {
      this.setMessageError(msgError);
      this.setState(StoreState.ERROR);
    }
  }

  async addLogUserExperienceAPI(
    events: ProductAnalyticsEvents,
    details?: string
  ) {
    const { logServices } = getEnv<RootEnv>();
    logServices.addLogUserExperience(events, details);
  }

  async getGeoLocationClickMapAPI() {
    const { atmOceanService } = getEnv<RootEnv>();

    try {
      this.setClickMapPointHeatMap([]);
      this.setState(StoreState.PEDDING);
      const result = await atmOceanService.getGeoLocationClickMap();

      if (result.length > 0) {
        this.setClickMapPointHeatMap(result);
      }
    } catch (error) {
      console.log(Error);
    } finally {
      this.setState(StoreState.DONE);
    }
  }

  async removeStationByUserAPI(stationId: number) {
    const { atmOceanService } = getEnv<RootEnv>();
    const msgError = "Error to remove station. Please contact us.";
    try {
      const stationSelected = this.getStationById(stationId);
      let stationRemoved: boolean = false;
      if (stationSelected) {
        stationRemoved = await atmOceanService.removeStationByUser(
          stationSelected.station_id,
          stationSelected.region_id!
        );

        if (stationRemoved) {
          const stationId = stationSelected.station_id;
          const stationToRemoveIndex = this.stations.indexOf(stationSelected);
          this.stations.splice(stationToRemoveIndex, 1);
          if (stationId === this.selectedStation) {
            this.setSelectedStation(0);
          }
          this.setState(StoreState.SUCCESS);
          this.setMessageSuccess("Station successfully removed!");
          setTimeout(() => {
            this.setState(StoreState.DONE);
          }, 3000);
        } else {
          this.setMessageError(msgError);
          this.setState(StoreState.ERROR);
        }
      } else {
        this.setMessageError(msgError);
        this.setState(StoreState.ERROR);
      }
    } catch (error) {
      this.setMessageError(msgError);
      this.setState(StoreState.ERROR);
    }
  }
}
