import { Flex } from "@chakra-ui/react";
import {
  Button,
  Form,
  Input,
  message,
  Popconfirm,
  Popover,
  Tooltip,
} from "antd";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useStores } from "../../../stores/setup/use-store";

interface ForecastChartFormStationProps {
  showFormAddStation: boolean;
  showButtonRemoveStation: boolean;
}

const ForecastChartFormStation: React.FC<ForecastChartFormStationProps> = (
  props
) => {
  const {
    dataStores: { forecastChartPageStore, regionStore, authStore },
  } = useStores();

  const [formSaveStation] = Form.useForm();
  const [popupSaveStation, setPopupSaveStation] = useState(false);

  const { t } = useTranslation("translation", {
    keyPrefix: "forecastChart.forecastChartFormStation",
  });

  const handleOnClickSaveStation = () => {
    const { stationDescription } = formSaveStation.getFieldsValue();

    if (!stationDescription) {
      message.error(t("informStationDescription"));
      return;
    }
    if (!forecastChartPageStore.clickMapLatLng) {
      message.error(t("failedGetVirtualStationGeolocation"));
      return;
    }

    const latLng = forecastChartPageStore.clickMapLatLng;
    const logDetails = {
      latitude: latLng.lat,
      longitude: latLng.lng,
    };

    forecastChartPageStore.updateMacroRegionOrigin(
      latLng,
      regionStore.macroRegions
    );
    if (!forecastChartPageStore.macroRegionOrigin) {
      message.info(t("youAreOutOfRegionArea"));
      return;
    }

    forecastChartPageStore.saveStationByUserAPI(
      stationDescription,
      latLng.lat,
      latLng.lng,
      forecastChartPageStore.macroRegionOrigin
    );

    setTimeout(() => {
      formSaveStation.resetFields();
      setPopupSaveStation(false);
    }, 1000);
  };

  const handleOnConfirmDeleteStation = () => {
    forecastChartPageStore.removeStationByUserAPI(
      forecastChartPageStore.selectedStation,
      forecastChartPageStore.regionId
    );
  };

  const cursor = authStore.readOnlyUser ? "not-allowed" : "pointer";

  return (
    <>
      {props.showFormAddStation ? (
        <Flex alignItems={"center"}>
          <Popover
            content={
              <Form layout="vertical" form={formSaveStation}>
                <Form.Item
                  name="stationDescription"
                  label={t("stationDescription")}
                >
                  <Input />
                </Form.Item>
                <Button type="primary" onClick={handleOnClickSaveStation}>
                  {t("save")}
                </Button>
              </Form>
            }
            title={t("doYouWantSaveStation")}
            open={popupSaveStation && !authStore.readOnlyUser}
            onOpenChange={() => {
              setPopupSaveStation(!popupSaveStation);
              formSaveStation.resetFields();
            }}
            trigger="click"
          >
            {authStore.readOnlyUser ? (
              <Tooltip title={t("youOnlyHaveReadPermission")}>
                <i
                  className="far fa-star fa-1x"
                  style={{ cursor, color: "#286795" }}
                >
                  {" "}
                  {t("saveStation")}
                </i>
              </Tooltip>
            ) : (
              <i
                className="far fa-star fa-1x"
                style={{ cursor, color: "#286795" }}
              >
                {" "}
                {t("saveStation")}
              </i>
            )}
          </Popover>
        </Flex>
      ) : (
        <Flex alignItems={"center"} gap={1}>
          {props.showButtonRemoveStation &&
          forecastChartPageStore.selectedStation > 0 ? (
            <Popconfirm
              placement="top"
              title={t("areYouSureWantDeleteStation")}
              disabled={authStore.readOnlyUser}
              onConfirm={(e) => handleOnConfirmDeleteStation()}
              okText={t("yes")}
              cancelText={t("no")}
            >
              {authStore.readOnlyUser ? (
                <Tooltip title={t("youOnlyHaveReadPermission")}>
                  <i
                    className="fas fa-trash"
                    style={{ cursor, color: "#ff3142" }}
                  ></i>
                </Tooltip>
              ) : (
                <i
                  className="fas fa-trash"
                  style={{ cursor, color: "#ff3142" }}
                ></i>
              )}
            </Popconfirm>
          ) : (
            ""
          )}
        </Flex>
      )}
    </>
  );
};
export default observer(ForecastChartFormStation);
