import { Box, Button, IconButton } from "@chakra-ui/react";
import React, { useRef } from "react";
import { SCREEN_HORIZONTAL_SPACING } from "../../constants";

import { useTranslation } from "react-i18next";
import { PageTitle } from "components/PageTitle/PageTitle";
import { Space, Table } from "antd";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import { OperationServicesModal } from "./Components/OperationServicesModal";
import { AlertDelete } from "components/AlertDelete/AlertDelete";
import {
  IOperationServiceTableColumns,
  RecurrenceType,
  ServiceFormValues,
} from "types/IOperationServices";
import { ColumnsType } from "antd/lib/table";
import { SearchOutlined } from "@ant-design/icons";
import { ColumnFilterItem } from "antd/lib/table/interface";
import { FormikErrors } from "formik";
import serviceEmpty from "../../assets/service_empty.svg";
import EmptyState from "components/EmptyState/EmptyState";
import { ServiceGuideModal } from "./Components/ServiceGuideModal";
import { ICurrency } from "types/ICurrency";
export interface OperationServicesProps {
  handleOnConfirmDelete: () => void;
  handleOpenDeleteModal: (serviceId: number) => void;
  isOpen: boolean;
  onClose: () => void;
  isDeleteOpen: boolean;
  onDeleteClose: () => void;
  isTutorialOpen: boolean;
  onTutorialClose: () => void;
  initialValues: ServiceFormValues;
  isEditing: boolean;
  handleOpenAddModal: () => void;
  handleOpenEditModal: (id: number) => void;
  recurrenceOptions: {
    value: RecurrenceType;
    label: string;
  }[];
  serviceList: any;
  onSubmitForm: (values: ServiceFormValues) => void;
  isLoading: boolean;
  getColumnFilter: (
    item: keyof IOperationServiceTableColumns
  ) => ColumnFilterItem[];
  validateForm: (values: ServiceFormValues) => FormikErrors<ServiceFormValues>;
  formattedTimezone: string;
  currencies: ICurrency[];
  isServiceListEmpty: boolean;
}

const OperationServices: React.FC<OperationServicesProps> = ({
  handleOnConfirmDelete,
  handleOpenDeleteModal,
  isOpen,
  onClose,
  isDeleteOpen,
  onDeleteClose,
  isTutorialOpen,
  onTutorialClose,
  initialValues,
  isEditing,
  handleOpenAddModal,
  handleOpenEditModal,
  recurrenceOptions,
  serviceList,
  onSubmitForm,
  isLoading,
  getColumnFilter,
  validateForm,
  formattedTimezone,
  currencies,
  isServiceListEmpty,
}: OperationServicesProps) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "operationServices",
  });

  const initialRef = useRef(null);

  const renderActions = (record: IOperationServiceTableColumns) => (
    <Space>
      <IconButton
        icon={<EditIcon />}
        layerStyle="yellowOcean"
        _hover={{ layerStyle: "yellowOcean" }}
        size="sm"
        mr={2}
        aria-label="Editar"
        onClick={() => handleOpenEditModal(record?.id)}
      />
      <IconButton
        icon={<DeleteIcon />}
        layerStyle="redOcean"
        _hover={{ layerStyle: "redOcean" }}
        size="sm"
        aria-label="Excluir"
        onClick={() => handleOpenDeleteModal(record.id)}
      />
    </Space>
  );

  const columns: ColumnsType<IOperationServiceTableColumns> = [
    {
      title: t("name"),
      key: "name",
      dataIndex: "name",
      width: "30%",
      filters: getColumnFilter("name"),
      onFilter: (value: any, record: IOperationServiceTableColumns) =>
        record.name.startsWith(value),
      filterSearch: true,
      filterIcon: <SearchOutlined style={{ fontSize: 20 }} />,
    },
    {
      title: t("description"),
      key: "description",
      dataIndex: "description",
      width: "35%",
    },
    {
      title: t("recurrence"),
      key: "recurrenceType",
      dataIndex: "recurrenceType",
      width: "30%",
      render: (value: RecurrenceType) => {
        const option = recurrenceOptions.find((opt) => opt.value === value);
        return option ? option.label : "-";
      },
    },
    {
      title: "",
      dataIndex: "action",
      width: "10%",
      render: (_: any, record: IOperationServiceTableColumns) =>
        renderActions(record),
    },
  ];

  return (
    <Box mt={8} mx={SCREEN_HORIZONTAL_SPACING}>
      <PageTitle text={t("services")} />

      <AlertDelete
        isOpen={isDeleteOpen}
        onClose={onDeleteClose}
        titleLabel={t("deleteService")}
        bodyText={t("areYouSureWantDeleteThisService")}
        cancelButtonLabel={t("cancel")}
        deleteButtonLabel={t("delete")}
        handleDelete={handleOnConfirmDelete}
      />

      <ServiceGuideModal
        handleOpenAddModal={handleOpenAddModal}
        isOpen={isTutorialOpen}
        onClose={onTutorialClose}
      />

      <OperationServicesModal
        initialValues={initialValues}
        isOpen={isOpen}
        onClose={onClose}
        initialRef={initialRef}
        recurrenceOptions={recurrenceOptions}
        onSubmitForm={onSubmitForm}
        validateForm={validateForm}
        isLoading={isLoading}
        isEditing={isEditing}
        formattedTimezone={formattedTimezone}
        currencies={currencies}
      />

      {!isServiceListEmpty ? (
        <>
          <Button
            leftIcon={<AddIcon />}
            layerStyle="ocean"
            _hover={{ layerStyle: "ocean" }}
            onClick={handleOpenAddModal}
          >
            {t("createService")}
          </Button>

          <Table
            key={`id-${serviceList.length}`}
            columns={columns}
            dataSource={serviceList}
            rowKey={(record) => record.id}
            style={{ marginTop: 10 }}
            loading={isLoading}
            locale={{
              emptyText: isLoading && (
                <span style={{ color: "gray" }}>{t("loading")}</span>
              ),
            }}
          ></Table>
        </>
      ) : (
        <EmptyState
          description={t("visualizeYourOperations")}
          buttonAction={handleOpenAddModal}
          buttonIcon={<AddIcon />}
          buttonText={t("createService") as string}
          image={serviceEmpty}
          imageSize="20vw"
        />
      )}
    </Box>
  );
};

export default OperationServices;
